import React, { Fragment } from 'react';
import { Card, Row, Col, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import Action from './action';
import { registerStatusToString, registerStatusToColor } from '../../constant';

const Detail = props => {
    const [{ record, loading }] = useDetail(`/registeritem/${props.match.params.id}`, {});

    return (
        <Card loading={loading} title={<Title title={"Detail pendaftaran"} actions={<Action dataId={record.id || '1'} />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField label="Tanggal daftar" >{(new Date(record.date)).toLocaleString('in-ID')}</TextField>
                            <TextField label="Nama" >{record.name}</TextField>
                            <TextField label="Telepon" >{record.phone}</TextField>
                            <TextField label="Email" >{record.email}</TextField>
                        </Col>
                        <Col span={12}>
                            <TextField label="Kelas" >{record.register.class.name}</TextField>
                            <TextField label="Tahun ajaran" >{record.register.year.name}</TextField>
                            <TextField label="Status" ><Tag color={registerStatusToColor(record.status)}>{registerStatusToString(record.status)}</Tag></TextField>
                            {record.status === 1 && <TextField label="Tanggal bayar" >{(new Date(record.date)).toLocaleString('in-ID')}</TextField>}
                        </Col>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>

                        </div>
                    </Row>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;