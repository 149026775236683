import React from 'react';
import { Typography, Table, Drawer } from 'antd';
import useTable from '../../component/table';
import PropTypes from 'prop-types';

const UserSelectorDialog = props => {
    const [, tb] = useTable('/user', { filter: { type: props.type } }, { key: 'sS' })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                if (props.onUserSelected)
                    props.onUserSelected(record);
            }
        }
    }

    return (
        <Drawer width="450" title="Pilih user orang tua" visible={props.visible || false} footer={null} {...props}>
            <div style={{ marginBottom: 10 }}>
                <Typography.Text disabled>Pilih dengan double click</Typography.Text>
            </div>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="name" dataIndex="name" title="Nama"
                    {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="username" dataIndex="username" title="Username"
                    {...tb.columnProps({ key: 'username', hint: 'Cari username' })} />
            </Table>
        </Drawer >
    )
}

UserSelectorDialog.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    onUserSelected: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default UserSelectorDialog;