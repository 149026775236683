import React from 'react';
import { Carousel } from 'antd';
import Fetch from '../../Fetch';

const ImageCarausel = props => {
    const imgGen = (url) => {
        return <div key={url}><img alt="sample" src={Fetch.getUrl(`/public/uploads/bulletin/${url}`)} style={{ height: props.height, borderRadius: 10, margin: 'auto' }} /></div>
    }
    const { images, image } = props;
    const imgs = images.length === 0 ?
        imgGen(image === null || image === '' ? 'default.jpg' : image) :
        (images.map(v => imgGen(v)));
    return (
        <Carousel draggable dots>
            {imgs}
        </Carousel>
    );
}

export default ImageCarausel;