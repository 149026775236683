import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Tag, message, Table, List, Typography } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { replace } from '../../component/link';
import {
    paymentTypeToString, paymentType,
    paymentTypeToColor, monthIntToString
} from '../../constant'
import useTable from '../../component/table';
import { LabelHeader } from '../../component/header';
import { TextField } from '../../component/field';
import moment from 'moment';

const PaymentItem = (props) => {
    const item = props.item;
    return <div>
        <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
                <Tag color={paymentTypeToColor(item.type)}>{paymentTypeToString(item.type)}</Tag>
                {(item.type !== 1 && item.type !== 2) && <Typography.Text style={{ fontSize: 12 }}>{monthIntToString(item.month)} - {item.year.name}</Typography.Text>}
                {(item.type === 1 || item.type === 2) && <Typography.Text style={{ fontSize: 12 }}>{item.year.name}</Typography.Text>}
            </div>
            <div>
                <span className="span-click" onClick={props.onDelete}>hapus</span>
            </div>
        </div>
        <div>
            <Typography.Text >{item.student.number} - {item.student.fullname}</Typography.Text>
        </div>
        {item.voucher &&
            <>
                <Tag color='green'>Voucher: Rp{item.voucher.amount.toLocaleString('in-ID')}</Tag>
                <div>
                    <Typography.Text strong delete>Rp {item.total.toLocaleString('in-ID')}</Typography.Text>
                    <Typography.Text strong style={{ marginLeft: 10 }}>Rp {(item.total - item.voucher.amount).toLocaleString('in-ID')}</Typography.Text>
                </div>
            </>
        }
        {!item.voucher &&
            <div>
                <Typography.Text strong>Rp {item.total.toLocaleString('in-ID')}</Typography.Text>
            </div>
        }
    </div>;
}

const Add = props => {
    const { setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, selected: [], total: 0 });
    const [, tb] = useTable('/payment', { filter: { status_ne: 3 }, })
    const [vouchers, setVouchers] = React.useState([])
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    let ret = {};
                    /*if (edit) {
                        ret = await Fetch.put(`/pickup/${props.match.params.id}`, { ...values, active: values.active_internal ? 1 : 0 });
                        message.info('Pesanan pembayaran berhasil diedit');
                    } else */
                    {
                        await Fetch.post('/paymentorder', {
                            type: 1, payments: state.selected.map(v => {
                                return { id: v.id, voucher: v.voucher ? v.voucher : null }
                            })
                        });
                        message.info('Pesanan pembayaran berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    replace(props, `/paymentorder/${ret.data.id}`)
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    React.useEffect(() => {
        const fetchVoucher = async () => {
            const voucers = await Fetch.get('/voucher?filter[active]=1')
            setVouchers(voucers.data)
        }
        fetchVoucher()
    }, [])

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const { data } = await Fetch.get(`/pickup/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            driver_id: data.driver.id,
            active_internal: true//data.active === 1 ? true : false
        });
    }

    const onRowList = (record) => {
        return {
            onDoubleClick: () => {
                const f = state.selected.find(v => record.id === v.id)
                if (!f) {
                    const selectedVoucer = anyVoucher(record)
                    const voucherValue = selectedVoucer ? selectedVoucer.amount : 0;
                    const arr = [...state.selected, { ...record, voucher: selectedVoucer }]
                    const tot = state.total + record.total - voucherValue;
                    setState({ ...state, selected: arr, total: tot })
                }
            }
        }
    }

    const deleleClicked = (record) => {
        return () => {
            const arr = state.selected.filter(v => v.id !== record.id);
            const voucherValue = record.voucher ? record.voucher.amount : 0
            setState({ ...state, selected: arr, total: state.total - record.total + voucherValue })
        }
    }

    const anyVoucher = (po) => {
        for (let i = 0; i < vouchers.length; i++) {
            const v = vouchers[i]
            const startTime = moment(v.valid_from)
            const endTime = moment(v.valid_until)
            const now = moment();
            if (startTime.isAfter(now)) continue;
            if (endTime.isBefore(now)) continue;
            if (v.year.value !== po.year.value) continue;
            if (v.payment_type === po.type) {
                if (v.students && v.students.length > 0) {
                    for (let s = 0; s < v.students.length; s++) {
                        const studentID = v.students[s]
                        if (studentID === po.student.id) {
                            return v
                        }
                    }
                } else {
                    return v
                }
            }
        }
        return null;
    }

    return (
        <Card title={<Title title={edit ? "Edit pembayaran" : "Tambah pembayaran"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_pembayaran" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={64}>
                    <Col span={14}>
                        <LabelHeader>Daftar pembayaran</LabelHeader>
                        <Table {...tb.tableProps} onRow={onRowList}>
                            <Table.Column key="student.number" dataIndex="student.number" title="NIK" {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                            <Table.Column key="student.name" dataIndex="student.name" title="Nama murid" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                            <Table.Column key="detail" dataIndex="detail" title="Detail" />
                            <Table.Column key="type" dataIndex="type" title="tipe" width={100} render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>}
                                {...tb.columnProps({ key: 'type', type: 'radio', options: paymentType, })} />
                            <Table.Column key="year.name" dataIndex="year.name" title="Tahun" />
                            <Table.Column key="month" dataIndex="month" title="Bulan" render={t => monthIntToString(t)} />
                            <Table.Column key="total" dataIndex="total" title="Total" align="right" render={(t) => t.toLocaleString('in-ID')} />
                        </Table>
                    </Col>
                    <Col span={10}>
                        <LabelHeader>Pembayaran terpilih</LabelHeader>
                        <List dataSource={state.selected} itemLayout="vertical" renderItem={item => <List.Item key={item.id}>
                            <PaymentItem item={item} onDelete={deleleClicked(item)} />
                        </List.Item>} />
                        <Divider style={{ margin: '1px 0' }} />
                        <TextField label="Total">
                            <Typography.Text strong style={{ color: '#808080', fontSize: 20 }}>
                                {state.total.toLocaleString('in-ID')}
                            </Typography.Text>
                        </TextField>
                        <Divider style={{ margin: '1px 0' }} />
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Bayar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_pembayaran' })(Add);