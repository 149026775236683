import React from 'react';
import { Form, Input, Button, Popconfirm, message, Select } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch'
import MySelect from '../../component/select';

const YearForm = props => {
    const [loading, setLoading] = React.useState({ save: false, delete: false });
    const { getFieldDecorator } = props.form;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setLoading({ ...loading, save: true })
                    if (props.edit) {
                        await Fetch.put(`/class/${props.record.id}`, values);
                        message.info('Kelas berhasil diedit');
                    }
                    else {
                        await Fetch.post('/class', values);
                        message.info('Kelas berhasil ditambahkan');
                    }
                    props.onSuccess()
                    setLoading({ ...loading, save: false })
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    const onConfirm = async () => {
        setLoading({ ...loading, delete: true });
        await Fetch.del(`/class/${props.record.id}`);
        message.info('Kelas berhasil dihapus');
        props.onSuccess();
        setLoading({ ...loading, delete: false });
    }

    return (
        <div>
            <Form layout="horizontal" onSubmit={formSummited}>
                <Form.Item label="Grade">
                    {getFieldDecorator('grade_id', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.grade.id : ''
                    })(
                        <MySelect url="/grade" dataIndex="name" placeholder="Pilih grade" />
                    )}
                </Form.Item>
                <Form.Item label="Nama kelas">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.name : ''
                    })(
                        <Input placeholder="Masukkan nama kelas" />
                    )}
                </Form.Item>
                <Form.Item label="Tipe kelas">
                    {getFieldDecorator('type', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.type : ''
                    })(
                        <Select placeholder="Pilih tipe" >
                            <Select.Option value={0} key={0}>Normal</Select.Option>
                            <Select.Option value={1} key={1}>Pendaftaran</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            loading={loading.save}
                            type="primary"
                            htmlType="submit"
                        >
                            Simpan
                        </Button>
                        {props.edit &&
                            <Popconfirm placement="bottomLeft" title={"Yakin menghapus kelas?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                                <Button loading={loading.delete} type="danger">Hapus</Button>
                            </Popconfirm>
                        }
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

YearForm.propTypes = {
    edit: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
}

export default Form.create()(YearForm);