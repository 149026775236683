import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/dailyact/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/dailyact/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const SemesterScore = props => {
    const [, tb] = useTable('/savvyactivitymaster', { order: { key: 'grade.value', order: 'ascend' }, filter: { active: 1 } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/raporsemester/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/raporsemester/${record.id}/${record.kelas.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Master Kegiatan Harian"} actions={<Actions  {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="year.name" dataIndex="kelas.year.name" title="Tahun ajaran"
                    {...tb.columnProps({ key: 'year_value', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'value' })} />
                <Table.Column key="kelas.class.name" dataIndex="kelas.class.name" title="Kelas"
                    {...tb.columnProps({ key: 'grade_name', hint: 'Cari nama kelas' })} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default SemesterScore;