import React from 'react';
import { Modal, Typography, Table } from 'antd';
import useTable from '../../component/table';
import PropTypes from 'prop-types';

const StudentSelectDialog = props => {
    const [, tb] = useTable('/student', { filter: props.filter || {} }, { key: 'sS' })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                if (props.onStudentSelected)
                    props.onStudentSelected(record);
            }
        }
    }

    return (
        <Modal title="Pilih siswa" visible={props.visible || false} footer={null} {...props} width={600}>
            <div style={{ marginBottom: 10 }}>
                <Typography.Text disabled>Pilih dengan double click</Typography.Text>
            </div>
            <Table {...tb.tableProps} onRow={onRow} scroll={{ y: 200 }}>
                <Table.Column key="number" dataIndex="number" title="NIK" width={100}
                    {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                <Table.Column key="fullname" dataIndex="fullname" title="Nama"
                    {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="gender" dataIndex="gender" title="L/P" width={80}
                    {...tb.columnProps({
                        key: 'gender', type: 'radio', options: [
                            { text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }
                        ]
                    })} />
            </Table>
        </Modal >
    )
}

StudentSelectDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onStudentSelected: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default StudentSelectDialog;