import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { push } from '../../component/link';

const Actions = props => {
    return (
        <Button.Group>
            <Button icon="edit" href={`/kelas/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/kelas/${props.dataId}/edit`);
            }}>Edit</Button>
            {props.record && props.record.active > 0 && props.record.class.grade.graduate !== 1 && <Button icon="to-top" href={`/kelas/${props.dataId}/naik`} onClick={(e) => {
                e.preventDefault();
                push(props, `/kelas/${props.dataId}/naik`);
            }}>Naik kelas</Button>}
            {props.record && props.record.active > 0 && props.record.class.grade.graduate === 1 && <Button icon="to-top" href={`/kelas/${props.dataId}/naik`} onClick={(e) => {
                e.preventDefault();
                push(props, `/kelas/${props.dataId}/naik`);
            }}>Lulus</Button>}
        </Button.Group>
    );
}

Actions.propTypes = {
    dataId: PropTypes.string.isRequired,
    reload: PropTypes.func,
}

export default withRouter(Actions);