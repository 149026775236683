import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Input, Typography } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { monthIntToString, paymentTypeToString } from '../../constant'
import { TextField } from '../../component/field';

const Edit = props => {
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const [state, setState] = React.useState({ loading: true, saveLoading: false, record: undefined, total: 0 });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };
    const fieldSpan = { labelSpan: 6, valueSpan: 18 };
    const { record } = state;

    React.useEffect(() => {
        Fetch.get(`/payment/${props.match.params.id}`).then(v => {
            const { data } = v
            setFieldsValue({ detail: data.detail, subtotal: data.subtotal, discount: data.discount })
            setState({ ...state, record: data, loading: false, total: data.subtotal - data.discount })
        })
    }, [])

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    await Fetch.put(`/payment/${props.match.params.id}`, { ...values, total: values.subtotal - values.discount });
                    message.info('Pembayaran berhasil diedit');
                    setState({ ...state, saveLoading: false })
                    pop(props, '/payment');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const calculateTotal = (field) => {
        return (v) => {
            const val = getFieldsValue();
            if (field === 'subtotal') {
                setState({ ...state, total: v - val.discount })
            } else {
                setState({ ...state, total: val.subtotal - v })
            }
        }
    }

    return (
        <Card title={<Title title="Edit pembayaran" {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="edit_payment" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField label="Nama" {...fieldSpan}>{record ? record.student.name : ''}</TextField>
                        <TextField label="Tipe" {...fieldSpan}>{record ? paymentTypeToString(record.type) : ''}</TextField>
                        <TextField label="Tahun" {...fieldSpan}>{record ? record.year.name : ''}</TextField>
                        <TextField label="Bulan" {...fieldSpan}>{record ? monthIntToString(record.month) : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Detail">
                            {getFieldDecorator('detail', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan detail" />
                            )}
                        </Form.Item>
                        <Form.Item label="Sub total">
                            {getFieldDecorator('subtotal', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan subtotal" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('subtotal')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Diskon">
                            {getFieldDecorator('discount', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan diskon" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('discount')} />
                            )}
                        </Form.Item>
                        <TextField label="Total" {...fieldSpan}><Typography.Text strong>{state.total.toLocaleString('in-ID')}</Typography.Text></TextField>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={state.saveLoading}
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'edit_payment' })(Edit);