import React from 'react';
import { Modal } from 'antd';
import { Button } from 'antd/lib/radio';

const StudentRemoveModel = props => {
    return (
        <Modal visible={props.visible} title="Konfirmasi hapus siswa" {...props} >
            {props.record && <div>
                <div>
                    {`Menghapus ${props.record.fullname} dari kelas, dengan opsi :`}
                    <br />
                    <Button>Hapus</Button>
                    <Button>Hapus</Button>
                </div>
            </div>}
        </Modal>
    )
}

export default StudentRemoveModel;