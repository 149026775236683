import React from 'react';
import { Card, Button, message, Select, Alert, Divider, Row, Col, Input, Tag, Form } from 'antd';
import Title from '../../component/title';
import { push } from '../../component/link';
import Fetch from '../../Fetch';

const Detail = props => {
    const [data, setData] = React.useState({ year: 'loading...', kelas: 'loading...', week: undefined, loading: true, saving: false })
    const [values, setValues] = React.useState({})
    const id = props.match.params.id
    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => i)
    }

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvyactivitymaster/${id}`);
            setData({ ...data, data: result, year: result.kelas.year.name, kelas: result.kelas.class.name, loading: false })
        }
        fetchData();
    }, [id])

    React.useEffect(() => {
        const fetchWeek = async (v) => {
            const result = await Fetch.get(`/savvyactivity/${id}/week/${v - 1}`);
            const acts = [];
            const plots = data.data.plots.filter(v1 => v1.week === v - 1)
            plots.forEach(e => {
                const idx = acts.findIndex(x => x.spectrum_id === e.spectrum_id)
                let spectrum = { spectrum_id: e.spectrum_id, basics: [] }
                if (idx >= 0) {
                    spectrum = acts[idx]
                } else {
                    acts.push(spectrum)
                }
                const basicIdx = spectrum.basics.findIndex(x => x.basic_id === e.basic_id)
                if (basicIdx >= 0) {
                    spectrum.basics[basicIdx].indicators.push(e)
                } else {
                    spectrum.basics.push({ basic_id: e.basic_id, indicators: [e] })
                }
            })
            const newValues = {};
            result.data.forEach(e => newValues[e.indicator_id] = e.value)
            setData({ ...data, loading: false, plots: acts, basePlots: plots })
            setValues(newValues)
        }
        if (data.week > 0)
            fetchWeek(data.week)
    }, [data.week])

    const onChange = (v) => {
        setData({ ...data, week: v, loading: true })
    }

    const onTextChanged = (id) => {
        return e => {
            setValues({ ...values, [id]: e.target.value })
        }
    }

    const getValue = (v) => {
        if (values[v]) return values[v]
        return undefined
    }

    const onClickDaily = () => {
        push(props, `/dailyact/${id}/daily`);
    }

    const onDownloadClicked = () => {
        const url = `/savvyactivitymaster/${id}/download?token=${Fetch.token}`
        window.open(Fetch.getUrl(url))
    }

    const onClick = async () => {
        const postData = { savvy_activity_master_id: id, activities: [] }
        for (let k in values) {
            const plot = getPlotByIndicatorId(k)
            postData.activities.push({
                value: values[k],
                indicator_id: k,
                basic_id: plot.basic_id,
                spectrum_id: plot.spectrum_id,
                savvy_id: plot.savvy_id,
                week: plot.week,
                theme: getThemeByWeek(plot.week).value
            })
        }
        if (postData.activities.length !== data.basePlots.length) {
            message.error('Error: pastikan semua kegiatan diisi')
            return
        }
        setData({ ...data, saving: true })
        try {
            await Fetch.post(`/savvyactivity`, postData);
            message.success('berhasil menyimpan')
        } catch (err) {
            message.error('Error: ' + err);
        }
        setData({ ...data, saving: false })
    }

    const Action = () => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 4 }}>
                <Button type='primary' loading={data.saving} onClick={onDownloadClicked}>Download Excel</Button>
            </div>
            <div style={{ marginRight: 4 }}>
                <Button type='primary' loading={data.saving} onClick={onClickDaily}>Harian</Button>
            </div>
            <Select placeholder="Pilih minggu" style={{ width: 200 }} onChange={onChange} value={data.week} disabled={data.saving}>
                {range(0, 34).map(v => {
                    return <Select.Option key={v} value={v + 1}>{`Minggu ke-${v + 1}`}</Select.Option>
                })}
            </Select>
            <Button.Group>
            </Button.Group>
        </div>
    }

    const getPlotByIndicatorId = (v) => {
        const idx = data.data.plots.findIndex(e => e.indicator_id === v)
        return data.data.plots[idx]
    }

    const getBasic = (v) => {
        return data.data.basics.filter(v1 => v1.id === v)[0]
    }

    const getSpectrum = (v) => {
        return data.data.spectrums.filter(v1 => v1.id === v)[0]
    }

    const getIndicator = (v) => {
        return data.data.indicators.filter(v1 => v1.id === v)[0]
    }

    const getThemeByWeek = (week) => {
        const themes = data.data.savvy.themes;
        for (let i = 0; i < themes.length; i++) {
            if (i === themes.length - 1) {
                return themes[i]
            }
            if (themes[i].start <= week && themes[i + 1].start > week) {
                return themes[i]
            }
        }
        return null
    }

    const Theme = () => {
        return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div>
                <Tag color='green'>{getThemeByWeek(data.week - 1).value}</Tag>
            </div>
            <div>
                <Button type='primary' loading={data.saving} onClick={onClick}>Simpan</Button>
            </div>
        </div>
    }

    return (
        <Card loading={data.loading} title={<Title title={`Kegiatan harian - ${data.kelas} - ${data.year}`} actions={<Action />} />}>
            {!data.week && <Alert message="Silakan pilih minggu terlebih dahulu" />}
            {data.week > 0 && !data.loading &&
                data.plots.map(v => {
                    return <Card title={getSpectrum(v.spectrum_id).value} style={{ marginBottom: 8 }} size="small" key={v.spectrum_id}
                        extra={<Theme />}>
                        {
                            v.basics.map(basic => {
                                return <div key={basic.basic_id}>
                                    <Row>
                                        <Col span={8}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flexGrow: 1 }}>{getBasic(basic.basic_id).value}</div>
                                                <div style={{ width: 1, background: '#e8e8e8', margin: 8 }} />
                                            </div>
                                        </Col>
                                        <Col span={16}>
                                            {
                                                basic.indicators.map(indicator => {
                                                    const value = getValue(indicator.indicator_id)
                                                    return <div style={{ marginBottom: 8 }} key={indicator.indicator_id}>
                                                        <div style={{ display: 'flex', marginBottom: 4 }}>
                                                            <div style={{ flexGrow: 1 }}>
                                                                {getIndicator(indicator.indicator_id).value}
                                                            </div>
                                                        </div>
                                                        <Form.Item validateStatus={value ? 'validating' : 'error'} help={value ? undefined : 'wajib diisi'}>
                                                            <Input.TextArea allowClear placeholder='Masukkan nama kegiatan' autoSize={{ minRows: 2, maxRows: 6 }}
                                                                value={value} onChange={onTextChanged(indicator.indicator_id)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                    <Divider />
                                </div>
                            })
                        }
                    </Card>
                })
            }
        </Card >
    )
}

export default Detail;