import React from 'react';
import Satu from '../svg/emot1_off.svg'
import Dua from '../svg/emot2_off.svg'
import Tiga from '../svg/emot3_off.svg'
import Empat from '../svg/emot4_off.svg'
import SatuOn from '../svg/emot1_on.svg'
import DuaOn from '../svg/emot2_on.svg'
import TigaOn from '../svg/emot3_on.svg'
import EmpatOn from '../svg/emot4_on.svg'

const Score = props => {
    const icons = [
        { on: SatuOn, off: Satu, id: 1 },
        { on: DuaOn, off: Dua, id: 2 },
        { on: TigaOn, off: Tiga, id: 3 },
        { on: EmpatOn, off: Empat, id: 4 },
    ]

    const onClick = (v) => {
        return () => {
            if (props.onChange) props.onChange(v.id)
        }
    }

    return <div style={{ display: 'flex' }}>
        {icons.map(v => {
            return (
                <div key={v.id} style={{ width: 40, height: 40, marginRight: 8, cursor: 'pointer' }} onClick={onClick(v)}>
                    <img src={props.value === v.id ? v.on : v.off} alt="Score" />
                </div>
            )
        })}
    </div>
}

export default Score;