import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <MyLink to={'/shuttle/add'}><Button type="primary">Tambah</Button></MyLink>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/pickup', {})

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/pickup/${id}`);
                message.info("Berhasil menghapus shuttle");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus shuttle: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/shuttle/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/shuttle/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/shuttle/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/shuttle/${record.id}/generate`}><Icon type="thunderbolt" style={{ marginRight: 8 }} />Pembayaran</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomLeft" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar penjemputan"} actions={<Actions />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="name" dataIndex="name" title="Nama" />
                <Table.Column key="driver" dataIndex="driver.name" title="Nama driver" />
                <Table.Column key="active" dataIndex="active" title="Status" render={t => <Tag color={t === 1 ? 'green' : 'red'}>{t === 1 ? 'Aktif' : 'Tidak aktif'}</Tag>} />
                <Table.Column key="pickup_items" dataIndex="pickup_items" title="Peserta" align="right" render={(t) => t.length.toLocaleString('in-ID')} />
                <Table.Column key="total" dataIndex="total" title="Total biaya" align="right" render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;