import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';

const Clone = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [data, setData] = React.useState({ data: [], column: [], year: 'loading...', grade: 'loading...' })
    const id = props.match.params.id

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvy/${id}`);
            setData({ year: result.savvy.year.name, grade: result.savvy.grade.name })
        }
        fetchData();
    }, [id])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const formData = new FormData();
                    formData.append("year", values.year)
                    formData.append("grade_id", values.grade_id)
                    const jsonData = { id, year_value: values.year };
                    console.log(jsonData)
                    const result = await Fetch.post('/savvy/clone', { id, year_value: values.year });
                    message.success('Berhasil clone')
                    props.history.replace('/savvy')
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    return (
        <Card title={<Title title={`Clone Indikator - ${data.grade} - ${data.year}`} />}>
            <Form layout="horizontal" name="create_kelas" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tahun Ajaran">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/year" dataIndex="name" valueIndex="value" placeholder="Pilih tahun ajaran" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Clone</Button>
                </Form.Item>
            </Form>
        </Card >
    )
}

export default Form.create({ name: 'clone_savvy' })(Clone);