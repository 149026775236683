import jwt_decode from "jwt-decode";

class Fetch {

    token = '';
    refreshToken = '';
    lifetime = 0;
    refreshing = false;
    onRefreshedCallback = [];

    constructor() {
        this.apiUrl = process.env.REACT_APP_API_URL;
        this.token = localStorage.getItem('access_token');
        this.refreshToken = localStorage.getItem('refresh_token');
        const v = parseInt(localStorage.getItem('lifetime'))
        if (v > 0) {
            this.lifetime = v;
        }
    }

    setLoggedIn(accessToken) {
        const now = Math.round(new Date().getTime() / 1000);
        this.token = accessToken.access_token;
        this.refreshToken = accessToken.refresh_token;
        this.lifetime = now + accessToken.expires_in;
        //console.log('lifetime:' + accessToken.expires_in)
        //console.log('new lifetime: ' + this.lifetime)
        localStorage.setItem('access_token', this.token)
        localStorage.setItem('refresh_token', this.refreshToken)
        localStorage.setItem('lifetime', `${this.lifetime}`)

        const jwtDecoded = jwt_decode(this.token)
        localStorage.setItem('user_type', jwtDecoded.UserType);
    }

    async doRefreshToken() {
        const result = await fetch(this.getUrl('/auth/refresh'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: this.refreshToken,
            })
        });
        const json = await result.json();
        this.setLoggedIn(json)
    }

    async getHeaderExec() {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
        };
    }

    async getToken() {
        const now = Math.round(new Date().getTime() / 1000) + (5 * 60);
        if (this.lifetime === 0 || this.lifetime < now) {
            console.log("token is expired, refreshing token")
            return new Promise((resolve, rejected) => {
                if (!this.refreshing) {
                    this.refreshing = true;
                    this.doRefreshToken().then(() => {
                        this.refreshing = false;
                        resolve(this.token);
                        for (let i = 0; i < this.onRefreshedCallback.length; i++) {
                            this.onRefreshedCallback[i]();
                        }
                        this.onRefreshedCallback = [];
                    });
                } else {
                    this.onRefreshedCallback.push(() => {
                        resolve(this.token)
                    })
                }
            })
        } else {
            return this.token
        }
    }

    async getHeaders() {
        const token = await this.getToken();
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
    }

    getUrl(url) {
        return `${this.apiUrl}${url}`;
    }

    async execute(result) {
        const json = await result.json();
        if (result.status >= 200 && result.status < 300) {
            return json;
        } else {
            throw json['error'];
        }
    }

    async get(url) {
        const result = await fetch(`${this.apiUrl}${url}`, {
            method: 'GET',
            headers: await this.getHeaders(),
        });
        return this.execute(result);
    }

    async post(url, body) {
        const result = await fetch(`${this.apiUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: await this.getHeaders(),
        });
        return this.execute(result);
    }

    async postForm(url, body) {
        const result = await fetch(`${this.apiUrl}${url}`, {
            method: 'POST',
            body: body,
            headers: {
                'Authorization': 'Bearer ' + await this.getToken(),
            },
        });
        return this.execute(result)
    }

    async put(url, body) {
        const result = await fetch(`${this.apiUrl}${url}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: await this.getHeaders(),
        });
        return this.execute(result);
    }

    async del(url) {
        const result = await fetch(`${this.apiUrl}${url}`, {
            method: 'DELETE',
            headers: await this.getHeaders(),
        });
        return this.execute(result);
    }
}

export default new Fetch();