import React from 'react';
import { Modal, Typography, Table } from 'antd';
import useTable from '../../component/table';
import { USER_PARENT } from '../../constant';

const UserSelector = props => {
    const [, tb] = useTable('/user', { filter: { type: USER_PARENT } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                if (props.onUserSelected)
                    props.onUserSelected(record);
            }
        }
    }

    return (
        <Modal title="Pilih user orang tua" visible={props.visible || false} footer={null} {...props}>
            <div style={{ marginBottom: 10 }}>
                <Typography.Text disabled>Pilih dengan double click</Typography.Text>
            </div>
            <Table {...tb.tableProps} onRow={onRow} >
                <Table.Column key="name" dataIndex="name" title="Nama"
                    {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="username" dataIndex="username" title="Username"
                    {...tb.columnProps({ key: 'username', hint: 'Cari username' })} />
            </Table>
        </Modal >
    )
}

export default UserSelector;