import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Input, Checkbox, Alert } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';

const Add = props => {
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ cardLoading: edit, saveLoading: false, showUserDialog: false, file: null });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = async (e) => {
        e.preventDefault();
        try {
            const result = await Fetch.put(`/savvy/indicator/${props.match.params.id}`, { value: state.data.value, plots: state.plots });
            props.history.replace(`/savvy/import/${result.filename}`)
            message.success('berhasil mengubah plot')
            pop(props, `/savvy/${props.match.params.id}`)
        } catch (err) {
            message.error('Error: ' + err);
        }
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, cardLoading: true });
        const data = await Fetch.get(`/savvy/indicator/${props.match.params.id}`);
        setState({ ...state, cardLoading: false, data: data, plots: data.plots.map(v => v.week) });
    }
    var foo = Array(35).fill().map((v, i) => i);

    const checkBoxChange = (week) => {
        return (v) => {
            if (v.target.checked) {
                if (!checkboxChecked(week)) {
                    state.plots.push(week)
                    setState({ ...state, plots: state.plots })
                }
            } else {
                setState({ ...state, plots: state.plots.filter(v => v != week) })
            }
        }
    }

    const checkboxChecked = (week) => {
        return state.plots.indexOf(week) >= 0
    }

    return (
        <Card title={<Title title="Import Indikator" {...props} />} loading={state.cardLoading}>
            {!state.cardLoading &&
                <Form layout="horizontal" name="create_kelas" onSubmit={formSummited} {...formItemLayout}>
                    <Row gutter={32}>
                        <Col span={18}>
                            <Row style={{ marginBottom: 10 }}>
                                <Col span={6} />
                                <Col span={18}>
                                    <Alert type='error' message='Perhatian!' description='Aksi disini hanya bisa untuk menambah minggu baru dan tidak bisa untuk menghapus minggu yang sudah terpilih. Pastikan semuanya benar sebelum disimpan!' />
                                </Col>
                            </Row>
                            <Form.Item label="Indikator">
                                <Input.TextArea allowClear placeholder='Masukkan indikator' autoSize={{ minRows: 3, maxRows: 6 }}
                                    value={state.data.value} />
                            </Form.Item>
                            <Form.Item label="Plot mingguan ">
                                <Row>
                                    {foo.map(v => {
                                        return <Col span={6} key={v}>
                                            <Checkbox checked={checkboxChecked(v)} onChange={checkBoxChange(v)} key={v}>{`Minggu ${v + 1}`}</Checkbox>
                                        </Col>
                                    })}
                                </Row>
                            </Form.Item>
                            <Row>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" >Simpan</Button>
                    </Form.Item>
                </Form>
            }
        </Card>
    );
}

export default Form.create({ name: 'edit_plot' })(Add);