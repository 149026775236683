import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/extra/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/extra/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const extra = props => {
    const [, tb] = useTable('/extra', { filter: { active: 1 } })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/extra/${id}`);
                message.info("Berhasil menghapus ekskul");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus ekskul: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/extra/${record.id}`);
            }
        }
    }

    const onActivate = (record) => {
        return async () => {
            const ms = message.loading("Loading...", 0);
            await Fetch.post(`/extra/enable`, { active: record.active ? 0 : 1, extra_id: record.id });
            ms();
            tb.fetchData();
        }
    }

    const closeExtra = (id) => {
        return async () => {
            try {
                await Fetch.post(`/extra/close`, { id: id, close: true });
                message.info("Berhasil menutup ekskul");
                tb.fetchData();
            } catch (err) {
                message.error("Error menutup ekskul: " + err);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/extra/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/extra/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/extra/${record.id}/clone`}><Icon type="copy" style={{ marginRight: 8 }} />Clone</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menutup ${record.name}?`} onConfirm={closeExtra(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="poweroff" style={{ marginRight: 8 }} />Tutup</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar extra"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran"
                    {...tb.columnProps({ key: 'year_value', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'value' })} />
                <Table.Column key="semester" dataIndex="semester" title="Semester" render={(t) => {
                    switch (t) {
                        case 1: return 'Ganjil';
                        case 2: return 'Genap';
                        default: return 'Unknown';
                    }
                }} {...tb.columnProps({ key: 'semester', type: 'radio', options: [{ text: 'Ganjil', value: 1 }, { text: 'Genap', value: 2 }] })} />
                <Table.Column key="class" dataIndex="class" title="Kelas" render={(t) => t.map(v => v.name + ',')} />
                <Table.Column key="name" dataIndex="name" title="Nama ekskul" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="active" dataIndex="active" title="Aktif" render={(t, r) => {
                    return <Popconfirm placement="bottomRight" title={`Yakin ${r.active ? 'non aktifkan' : 'aktifkan'} "${r.name}"?`} onConfirm={onActivate(r)} okText="Iya" cancelText="Tidak">
                        <Tag color={t ? 'green' : 'red'}>{t ? 'Aktif' : 'Non aktif'}</Tag>
                    </Popconfirm>
                }}
                    {...tb.columnProps({ key: 'active', type: 'radio', options: [{ text: 'Aktif', value: 1 }, { text: 'Non aktif', value: 0 }] })} />
                <Table.Column key="capacity" dataIndex="capacity" title="Kapasitas" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="count" dataIndex="count" title="Peserta" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default extra;