import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, InputNumber, Typography } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { paymentTypeToString } from '../../constant'
import { TextField } from '../../component/field';

const Edit = props => {
    const { getFieldDecorator, getFieldsValue } = props.form;
    const [state, setState] = React.useState({ loading: true, saveLoading: false, record: undefined, total: 0, rest: 0 });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };
    const fieldSpan = { labelSpan: 6, valueSpan: 18 };
    const { record } = state;

    React.useEffect(() => {
        Fetch.get(`/payment/${props.match.params.id}`).then(v => {
            const { data } = v
            setState({ ...state, record: data, loading: false, total: data.total, rest: data.total })
        })
    }, [])

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                if (state.rest !== 0) {
                    message.error('Jumlah pembagian salah, sisa harus 0')
                    return
                }
                try {
                    setState({ ...state, saveLoading: true })
                    const keys = ['split1', 'split2', 'split3', 'split4', 'split5', 'split6']
                    let arr = [];
                    for (let i = 0; i < keys.length; i++) {
                        if (values[keys[i]] > 0) {
                            arr.push(values[keys[i]])
                        }
                    }
                    console.log(arr)
                    await Fetch.put(`/payment/split/${props.match.params.id}`, { data: arr });
                    message.info('Pembayaran berhasil displit');
                    setState({ ...state, saveLoading: false })
                    pop(props, '/payment');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const calculateTotal = (field) => {
        return (v) => {
            const val = getFieldsValue();
            const arr = ['split1', 'split2', 'split3', 'split4', 'split5', 'split6']
            let tot = 0;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === field) tot += v
                else tot += val[arr[i]] || 0
            }
            setState({ ...state, rest: state.total - tot })
        }
    }

    return (
        <Card title={<Title title="Split pembayaran tahunan" {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="edit_payment" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <TextField label="Nama" {...fieldSpan}>{record ? record.student.name : ''}</TextField>
                        <TextField label="Tipe" {...fieldSpan}>{record ? paymentTypeToString(record.type) : ''}</TextField>
                        <TextField label="Tahun" {...fieldSpan}>{record ? record.year.name : ''}</TextField>
                        <TextField label="Detail" {...fieldSpan}>{record ? record.detail : ''}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField label="Total" {...fieldSpan}><Typography.Text strong>{state.total.toLocaleString('in-ID')}</Typography.Text></TextField>
                        <Form.Item label="Split 1">
                            {getFieldDecorator('split1')(
                                <InputNumber placeholder="Masukkan split 1" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split1')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Split 2">
                            {getFieldDecorator('split2')(
                                <InputNumber placeholder="Masukkan split 2" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split2')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Split 3">
                            {getFieldDecorator('split3')(
                                <InputNumber placeholder="Masukkan split 3" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split3')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Split 4">
                            {getFieldDecorator('split4')(
                                <InputNumber placeholder="Masukkan split 4" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split4')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Split 5">
                            {getFieldDecorator('split5')(
                                <InputNumber placeholder="Masukkan split 5" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split5')} />
                            )}
                        </Form.Item>
                        <Form.Item label="Split 6">
                            {getFieldDecorator('split6')(
                                <InputNumber placeholder="Masukkan split 6" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={calculateTotal('split6')} />
                            )}
                        </Form.Item>
                        <TextField label="Sisa" {...fieldSpan}><Typography.Text strong>{state.rest.toLocaleString('in-ID')}</Typography.Text></TextField>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={state.saveLoading}
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'edit_payment' })(Edit);