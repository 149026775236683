import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import DefaultRoute from './DefaultRoute';
import PageLogin from './page/login';
import PageProfile from './page/profile/profile';
import PageDashboard from './page/dashboard/dashboard';
import PageBulletinList from './page/bulletin/list';
import PageBulletinAdd from './page/bulletin/add';
import PageBulletinDetail from './page/bulletin/detail';
import PageReligion from './page/religion/religion';
import PageYear from './page/year/year';
import PageGrade from './page/grade/grade';
import PageClass from './page/class/class';
import PageUser from './page/user/user';
import PageUserAdd from './page/user/add';
import PageUserDetail from './page/user/detail';
import PageStudent from './page/student/student';
import PageStudentAdd from './page/student/add';
import PageStudentDetail from './page/student/detail';
import PageKelas from './page/kelas/kelas';
import PageKelasAdd from './page/kelas/add';
import PageKelasDetail from './page/kelas/detail';
import PageKelasNaik from './page/kelas/naik_kelas';
import PageExtra from './page/extra/extra';
import PageExtraAdd from './page/extra/add';
import PageExtraDetail from './page/extra/detail';
import PageRegister from './page/register/register';
import PageRegisterAdd from './page/register/add';
import PageRegisterDetail from './page/register/detail';
import PageRegisterImport from './page/register/import';
import PageRegisterItemDetail from './page/register/registeritemdetail';
import PagePayment from './page/payment/payment';
import PagePaymentAdd from './page/payment/add';
import PagePaymentDetail from './page/payment/detail';
import PagePaymentEdit from './page/payment/edit';
import PagePaymentSplit from './page/payment/split';
import PagePaymentOrder from './page/paymentorder/paymentorder';
import PagePaymentOrderDetail from './page/paymentorder/detail';
import PagePaymentOrderAdd from './page/paymentorder/add';
import PagePaymentConfig from './page/paymentconfig/paymentconfig';
import PagePaymentConfigAdd from './page/paymentconfig/add';
import PageShuttle from './page/shuttle/shuttle';
import PageShuttleAdd from './page/shuttle/add';
import PageShuttleDetail from './page/shuttle/detail';
import PageShuttleItemAdd from './page/shuttle/itemadd';
import PageShuttlePayment from './page/shuttle/payment';
import PageBookList from './page/book/book';
import PageBookAdd from './page/book/add';
import PageBookDetail from './page/book/detail';
import PageBookKeepList from './page/bookkeep/list';
import { StoreProvider } from './store/store';
import { Empty } from 'antd';
import PageWelcome from './page/welcome/welcome';
import PageSavvy from './page/savvy/savvy';
import PageSavvyClone from './page/savvy/savvyclone';
import PageSavvyImport from './page/savvy/add';
import PageSavvyDoImport from './page/savvy/doimport';
import PageSavvyDetail from './page/savvy/detail';
import PageSavvyIndicatorEdit from './page/savvy/editplot';
import PageSavvyActDaily from './page/dailyact/dailyact';
import PageSavvyActDailyAdd from './page/dailyact/add';
import PageSavvyActDailyDetail from './page/dailyact/detail';
import PageSavvyActDailyDaily from './page/dailyact/daily';
import PageSavvyDailyScore from './page/dailyscore/dailyscore';
import PageSavvyDailyScoreDetail from './page/dailyscore/detail';
import PageSemesterScore from './page/semesterscore/semesterscore';
import PageSemesterScoreDetail from './page/semesterscore/semesterdetail';
import PageVoucher from './page/voucher/voucher';
import PageVoucherAdd from './page/voucher/add';
import PageSetting from './page/setting/setting';

const CheckLogin = () => {
  const token = localStorage.getItem('access_token');
  if (token === null || token === "") {
    return <Redirect to="/login" />
  }
  return <Redirect to="/welcome" />
}

const NotFound = () => {
  return <div style={{ marginTop: 100 }}>
    <Empty description={"Halaman tidak ditemukan"} />
  </div>
}

class App extends Component {
  render() {
    return (
      <StoreProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={CheckLogin} />
            <Route exact path="/login" component={PageLogin} />
            <DefaultRoute path="/welcome" component={PageWelcome} />
            <DefaultRoute path="/dashboard" component={PageDashboard} />
            <DefaultRoute path="/profile" component={PageProfile} />
            <DefaultRoute exact path="/bulletin" component={PageBulletinList} />
            <DefaultRoute exact path="/bulletin/add" component={PageBulletinAdd} />
            <DefaultRoute exact path="/bulletin/:id" component={PageBulletinDetail} />
            <DefaultRoute exact path="/bulletin/:id/edit" component={PageBulletinAdd} />
            <DefaultRoute exact path="/religion" component={PageReligion} />
            <DefaultRoute exact path="/year" component={PageYear} />
            <DefaultRoute exact path="/grade" component={PageGrade} />
            <DefaultRoute exact path="/class" component={PageClass} />
            <DefaultRoute exact path="/user" component={PageUser} />
            <DefaultRoute exact path="/user/add" component={PageUserAdd} />
            <DefaultRoute exact path="/user/:id" component={PageUserDetail} />
            <DefaultRoute exact path="/user/:id/edit" component={PageUserAdd} />
            <DefaultRoute exact path="/student" component={PageStudent} />
            <DefaultRoute exact path="/student/add" component={PageStudentAdd} />
            <DefaultRoute exact path="/student/:id" component={PageStudentDetail} />
            <DefaultRoute exact path="/student/:id/edit" component={PageStudentAdd} />
            <DefaultRoute exact path="/kelas" component={PageKelas} />
            <DefaultRoute exact path="/kelas/add" component={PageKelasAdd} />
            <DefaultRoute exact path="/kelas/:id/edit" component={PageKelasAdd} />
            <DefaultRoute exact path="/kelas/:id/clone" component={PageKelasAdd} />
            <DefaultRoute exact path="/kelas/:id/naik" component={PageKelasNaik} />
            <DefaultRoute exact path="/kelas/:id" component={PageKelasDetail} />
            <DefaultRoute exact path="/extra" component={PageExtra} />
            <DefaultRoute exact path="/extra/add" component={PageExtraAdd} />
            <DefaultRoute exact path="/extra/:id" component={PageExtraDetail} />
            <DefaultRoute exact path="/extra/:id/edit" component={PageExtraAdd} />
            <DefaultRoute exact path="/extra/:id/clone" component={PageExtraAdd} />
            <DefaultRoute exact path="/payment" component={PagePayment} />
            <DefaultRoute exact path="/payment/add" component={PagePaymentAdd} />
            <DefaultRoute exact path="/payment/:id" component={PagePaymentDetail} />
            <DefaultRoute exact path="/payment/:id/edit" component={PagePaymentEdit} />
            <DefaultRoute exact path="/payment/:id/split" component={PagePaymentSplit} />
            <DefaultRoute exact path="/paymentorder" component={PagePaymentOrder} />
            <DefaultRoute exact path="/paymentorder/add" component={PagePaymentOrderAdd} />
            <DefaultRoute exact path="/paymentorder/:id" component={PagePaymentOrderDetail} />
            <DefaultRoute exact path="/paymentconfig" component={PagePaymentConfig} />
            <DefaultRoute exact path="/paymentconfig/add" component={PagePaymentConfigAdd} />
            <DefaultRoute exact path="/paymentconfig/:id/edit" component={PagePaymentConfigAdd} />
            <DefaultRoute exact path="/register" component={PageRegister} />
            <DefaultRoute exact path="/register/add" component={PageRegisterAdd} />
            <DefaultRoute exact path="/register/import" component={PageRegisterImport} />
            <DefaultRoute exact path="/register/:id" component={PageRegisterDetail} />
            <DefaultRoute exact path="/register/:id/edit" component={PageRegisterAdd} />
            <DefaultRoute exact path="/registeritem/:id" component={PageRegisterItemDetail} />
            <DefaultRoute exact path="/student/:id/register" component={PageStudentDetail} />
            <DefaultRoute exact path="/shuttle" component={PageShuttle} />
            <DefaultRoute exact path="/shuttle/add" component={PageShuttleAdd} />
            <DefaultRoute exact path="/shuttle/:id/edit" component={PageShuttleAdd} />
            <DefaultRoute exact path="/shuttle/:id/generate" component={PageShuttlePayment} />
            <DefaultRoute exact path="/shuttle/:id" component={PageShuttleDetail} />
            <DefaultRoute exact path="/shuttle/item/:id/add" component={PageShuttleItemAdd} />
            <DefaultRoute exact path="/shuttle/item/:shuttleid/:id/edit" component={PageShuttleItemAdd} />
            <DefaultRoute exact path="/book" component={PageBookList} />
            <DefaultRoute exact path="/book/add" component={PageBookAdd} />
            <DefaultRoute exact path="/book/:id/edit" component={PageBookAdd} />
            <DefaultRoute exact path="/book/:id" component={PageBookDetail} />
            <DefaultRoute exact path="/bookkeep" component={PageBookKeepList} />
            <DefaultRoute exact path="/savvy" component={PageSavvy} />
            <DefaultRoute exact path="/savvy/import" component={PageSavvyImport} />
            <DefaultRoute exact path="/savvy/import/:id" component={PageSavvyDoImport} />
            <DefaultRoute exact path="/savvy/indicator/:id/edit" component={PageSavvyIndicatorEdit} />
            <DefaultRoute exact path="/savvy/:id" component={PageSavvyDetail} />
            <DefaultRoute exact path="/savvy/:id/clone" component={PageSavvyClone} />
            <DefaultRoute exact path="/dailyact" component={PageSavvyActDaily} />
            <DefaultRoute exact path="/dailyact/add" component={PageSavvyActDailyAdd} />
            <DefaultRoute exact path="/dailyact/:id" component={PageSavvyActDailyDetail} />
            <DefaultRoute exact path="/dailyact/:id/daily" component={PageSavvyActDailyDaily} />
            <DefaultRoute exact path="/dailyscore" component={PageSavvyDailyScore} />
            <DefaultRoute exact path="/dailyscore/:id/:kelasid" component={PageSavvyDailyScoreDetail} />
            <DefaultRoute exact path="/raporsemester" component={PageSemesterScore} />
            <DefaultRoute exact path="/raporsemester/:id/:kelasid" component={PageSemesterScoreDetail} />
            <DefaultRoute exact path="/voucher" component={PageVoucher} />
            <DefaultRoute exact path="/voucher/add" component={PageVoucherAdd} />
            <DefaultRoute exact path="/voucher/:id/edit" component={PageVoucherAdd} />
            <DefaultRoute exact path="/setting" component={PageSetting} />
            <DefaultRoute path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </StoreProvider>
    );
  }
}

export default App;

