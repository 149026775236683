import React, { Fragment } from 'react';
import { Row, Col, Table, Tag, Card } from 'antd';
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util';
import useTable from '../../component/table';
import { TextField } from '../../component/field';

const DetailAbsensi = props => {
    const { loading, student } = props;
    const [, tb] = useTable('/absensi', { order: { key: 'from', order: 'desc' } }, { key: 'abs_' }, true)
    const [state, setState] = React.useState({ data: [], loading: true, })
    if (loading) return null;

    React.useEffect(() => {
        Fetch.get('/config').then(v => {
            Fetch.get(`/absensi?filter[student_id]=${student.id}&count=0`).then(abs => {
                const d = abs.data;
                let kelas = { 'sick': 0, 'other': 0, 'alpha': 0 };
                let exs = { 'sick': 0, 'other': 0, 'alpha': 0 };
                for (let i = 0; i < d.length; i++) {
                    const from = new Date(d[i].from)
                    const to = new Date(d[i].from)
                    const diffTime = Math.abs(from.getTime() - to.getTime())
                    const diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
                    if (d[i].type === 'kelas') {
                        kelas[d[i].reason] += diffDay;
                    } else if (d[i].type === 'extra') {
                        exs[d[i].reason] += diffDay;
                    }
                }
                tb.setFilter({ 'student_id': student.id })
                setState({ ...state, loading: false, kelas: kelas, extra: exs, year: v.data.current_year })
            })
        })
    }, [student]);

    return !state.loading && <Fragment>
        <Row gutter={16}>
            <Col span={16}>
                <Card title={`Absensi ${state.year.name}`}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card title="Kelas">
                                <TextField label="Sakit" >{state.kelas.sick}</TextField>
                                <TextField label="Ijin" >{state.kelas.other}</TextField>
                                <TextField label="Alpha" >{state.kelas.alpha}</TextField>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Ekskul">
                                <TextField label="Sakit" >{state.extra.sick}</TextField>
                                <TextField label="Ijin" >{state.extra.other}</TextField>
                                <TextField label="Alpha" >{state.extra.alpha}</TextField>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <br />
        <Row gutter={16}>
            <Col span={24}>
                <Table {...tb.tableProps}>
                    <Table.Column key="from" dataIndex="from" title="Dari" render={t => formatDate(t, 'dd-MM-yyyy')} />
                    <Table.Column key="to" dataIndex="to" title="Sampai" render={t => formatDate(t, 'dd-MM-yyyy')} />
                    <Table.Column key="reason" dataIndex="reason" title="Alasan" render={t => <Tag color={t === 'alpha' ? 'red' : t === 'sick' ? 'green' : 'blue'}>
                        {t === 'alpha' ? 'Alpha' : t === 'sick' ? 'Sakit' : 'Ijin'}
                    </Tag>} />
                    <Table.Column key="type" dataIndex="type" title="Tipe" render={t => <Tag color={t === 'parent' ? 'blue' : t === 'kelas' ? 'green' : 'orange'}>
                        {t === 'parent' ? 'Ortu' : t === 'kelas' ? 'Kelas' : 'Ekskul'}
                    </Tag>} />
                </Table>
            </Col>
        </Row>
    </Fragment>

}

export default DetailAbsensi;