import React from 'react';
import { Card, Row, Col, Divider, Button, message, Table } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util'

const Add = props => {
    const [state, setState] = React.useState({ loading: true, selectedRowKeys: [] })

    const formSummited = async (e) => {
        try {
            if (state.selectedRowKeys.length === 0) {
                message.error('Siswa yang dipilih kosong')
                return
            }
            await Fetch.post('/registeritem/import', { ids: state.selectedRowKeys })
            message.info('Siswa berhasil di import')
            fetchData();
        } catch (err) {
            message.error('Error: ' + err);
        }
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true })
        const { data } = await Fetch.get(`/registeritem?filter[status]=1&filter[imported]=0&limit=10000&sort[pay_date]=asc`);
        setState({ ...state, loading: false, data: data })
    }

    const rowSelection = {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys)
            console.log(selectedRows)
            setState({ ...state, selectedRows, selectedRowKeys })
        },
    };

    return (
        <Card title={<Title title="Import siswa pendaftaran" {...props} />} loading={state.loading}>
            <Table dataSource={state.data} size={"small"} bordered={true} scroll={{ x: true }} rowSelection={rowSelection} rowKey="id" pagination={false}>
                <Table.Column key="pay_date" dataIndex="pay_date" title="Tanggal bayar" render={(t) => formatDate(t, false)} />
                <Table.Column key="name" dataIndex="name" title="Nama" />
                <Table.Column key="email" dataIndex="email" title="Email" />
                <Table.Column key="register.class.name" dataIndex="register.class.name" title="Kelas" />
                <Table.Column key="register.year.name" dataIndex="register.year.name" title="Tahun" />
                <Table.Column key="student.name" dataIndex="student.name" title="Nama panggilan" />
                <Table.Column key="student.fullname" dataIndex="student.fullname" title="Nama lengkap" />
            </Table>
            <Row gutter={32}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                </Col>
            </Row>
            <Divider />
            <Button type="primary" onClick={formSummited} >Import siswa</Button>
        </Card >
    );
}

export default Add;