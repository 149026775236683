import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { MyLink } from '../component/link';
import { GlobalStore } from '../store/store'
import packageJson from '../../package.json'

const MySider = (props) => {
    const [{ global }] = React.useContext(GlobalStore);

    const menus = [
        //{ label: 'Dashboard', key: '/dashboard', path: '/dashboard', icon: 'bar-chart' },
        { label: 'Pengumuman', key: '/bulletin', path: '/bulletin', icon: 'bell' },
        { label: 'User', key: '/user', path: '/user', icon: 'user' },
        { label: 'Siswa', key: '/student', path: '/student', icon: 'team' },
        { label: 'Kelas', key: '/kelas', path: '/kelas', icon: 'layout' },
        { label: 'Ekstrakurikuler', key: '/extra', path: '/extra', icon: 'robot' },
        { label: 'Jemputan', key: '/shuttle', path: '/shuttle', icon: 'car' },
        {
            label: 'Penilaian Siswa', key: '/p0', path: '/p0', icon: 'snippets', children: [
                { label: 'Kegiatan harian', key: '/dailyact', path: '/dailyact', icon: 'box-plot' },
                { label: 'Penilaian harian', key: '/dailyscore', path: '/dailyscore', icon: 'radar-chart' },
                { label: 'Rapor semester', key: '/raporsemester', path: '/raporsemester', icon: 'radar-chart' },
            ]
        },
        {
            label: 'Pembayaran', key: '/p1', path: '/p1', icon: 'dollar', children: [
                { label: 'Pembayaran', key: '/payment', path: '/payment', icon: 'dollar' },
                { label: 'Pesanan pembayaran', key: '/paymentorder', path: '/paymentorder', icon: 'file-search' },
                { label: 'Setting pembayaran', key: '/paymentconfig', path: '/paymentconfig', icon: 'setting' },
                { label: 'Voucher', key: '/voucher', path: '/voucher', icon: 'percentage' },
            ]
        },
        { label: 'Pendaftaran Murid', key: '/register', path: '/register', icon: 'audit' },
        {
            label: 'Perpustakaan', key: '/p2', path: '/p2', icon: 'book', children: [
                { label: 'Buku', key: '/book', path: '/book', icon: 'book' },
                { label: 'Pinjam Buku', key: '/bookkeep', path: '/bookkeep', icon: 'file-search' },
            ]
        },
        {
            label: 'Master', key: '/none', path: '/none', icon: 'form', children: [
                { label: 'Agama', key: '/religion', path: '/religion', icon: 'snippets' },
                { label: 'Tahun Ajaran', key: '/year', path: '/year', icon: 'snippets' },
                { label: 'Grade', key: '/grade', path: '/grade', icon: 'snippets' },
                { label: 'Kelas', key: '/class', path: '/class', icon: 'snippets' },
                //{ label: 'Subject', key: '/subject', path: '/subject', icon: 'snippets' },
                { label: 'Indikator', key: '/savvy', path: '/savvy', icon: 'box-plot' },
                { label: 'Setting', key: '/setting', path: '/setting', icon: 'setting' },
            ]
        },
    ]

    const menuTeachers = [
        {
            label: 'Penilaian Siswa', key: '/p0', path: '/p0', icon: 'snippets', children: [
                { label: 'Kegiatan harian', key: '/dailyact', path: '/dailyact', icon: 'box-plot' },
                { label: 'Penilaian harian', key: '/dailyscore', path: '/dailyscore', icon: 'radar-chart' },
                { label: 'Rapor semester', key: '/raporsemester', path: '/raporsemester', icon: 'radar-chart' },
            ]
        },
    ]

    const buildMenuItem = (arr) => {
        return arr.map(menu => {
            if (Array.isArray(menu.children)) {
                return <Menu.SubMenu key={menu.key}
                    title={<span><Icon type={menu.icon} /><span className="nav-text">{menu.label}</span></span>}>
                    {buildMenuItem(menu.children)}
                </Menu.SubMenu>
            }
            return (<Menu.Item key={menu.key}>
                <MyLink to={menu.path}>
                    <Icon type={menu.icon} />
                    <span className="nav-text">{menu.label}</span>
                </MyLink>
            </Menu.Item>
            )
        })
    }

    const iof = props.location.pathname.indexOf('/', 1);
    const path = iof > 0 ? props.location.pathname.substring(0, iof) : props.location.pathname

    const whichMenu = () => {
        const userType = localStorage.getItem('user_type')
        return userType === 3 || userType === '3' ? menuTeachers : menus;
    }

    return (
        <div>
            <Layout.Sider style={{
                overflowY: 'auto', height: '100%', backgroundColor: 'white',
            }} width={250} collapsible collapsed={global.collapse} trigger={null}
            >
                <div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={[path]}>
                        {buildMenuItem(whichMenu())}
                    </Menu>
                </div>
                <div style={{ height: 20 }}></div>
            </Layout.Sider>
            <div style={{
                width: global.collapse ? 80 : 250, textAlign: 'center', fontSize: 10, backgroundColor: 'green',
                color: 'white', padding: '5px 0px 5px 0px', position: 'absolute',
                bottom: 0, left: 0
            }}>{packageJson.version}</div>
        </div>
    );
};

export default MySider;