import React from 'react';
import { Card, Row, Col, Table, Typography, Button, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import MyForm from './form';

const Grade = props => {
    const [, tb] = useTable('/grade', {})
    const [currentReligion, setCurrentReligion] = React.useState(null);
    const [add, setAdd] = React.useState(false);

    const onRow = (record) => {
        return {
            onClick: () => {
                setCurrentReligion(record);
                setAdd(false);
            }
        }
    }

    const addClicked = () => {
        setCurrentReligion(null);
        setAdd(true);
    }

    return (
        <Card title={<Title title={"Master grade"} actions={<Button type="primary" onClick={addClicked}>Tambah</Button>} />}>
            <Row style={{ height: '100%' }} gutter={32}>
                <Col span={12}>
                    <Card title={"Daftar grade"}>
                        <Table {...tb.tableProps} onRow={onRow}>
                            <Table.Column dataIndex="value" title="Nilai" />
                            <Table.Column dataIndex="name" title="Nama" />
                            <Table.Column dataIndex="graduate" title="Lulus" render={v => <Tag color={v === 1 ? 'green' : 'blue'}>{v === 1 ? 'Iya' : 'Tidak'} </Tag>} />
                        </Table>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={add ? "Tambah grade" : "Edit grade"}>
                        {currentReligion === null && !add && <Typography.Text disabled>Silakan pilih grade pada tabel untuk edit</Typography.Text>}
                        {(currentReligion !== null || add) && <MyForm record={currentReligion} edit={!add} onSuccess={() => {
                            setAdd(false);
                            setCurrentReligion(null);
                            tb.fetchData();
                        }} />}
                    </Card>
                </Col>
            </Row>
        </Card >
    )
}

export default Grade;