import React from 'react';
import { Form, Input, Button, Popconfirm, message } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch'

const ReligionForm = props => {
    const [loading, setLoading] = React.useState({ save: false, delete: false });
    const { getFieldDecorator } = props.form;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setLoading({ ...loading, save: true })
                    if (props.edit) {
                        await Fetch.put(`/religion/${props.record.id}`, values);
                        message.info('Agama berhasil diedit');
                    }
                    else {
                        await Fetch.post('/religion', values);
                        message.info('Agama berhasil ditambahkan');
                    }
                    props.onSuccess()
                    setLoading({ ...loading, save: false })
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    const onConfirm = async () => {
        setLoading({ ...loading, delete: true });
        await Fetch.del(`/religion/${props.record.id}`);
        message.info('Agama berhasil dihapus');
        props.onSuccess();
        setLoading({ ...loading, delete: false });
    }

    return (
        <div>
            <Form layout="horizontal" onSubmit={formSummited}>
                <Form.Item label="Nama agama">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.name : ''
                    })(
                        <Input placeholder="Masukkan nama agama" />
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            loading={loading.save}
                            type="primary"
                            htmlType="submit"
                        >
                            Simpan
                        </Button>
                        {props.edit &&
                            <Popconfirm placement="bottomLeft" title={"Yakin menghapus agama?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                                <Button loading={loading.delete} type="danger">Hapus</Button>
                            </Popconfirm>
                        }
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

ReligionForm.propTypes = {
    edit: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
}

export default Form.create()(ReligionForm);