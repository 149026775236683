import React from 'react';
import { Card, Button, message, Select, Divider, Row, Col, DatePicker, List, Typography, Popconfirm } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const Daily = props => {
    const [data, setData] = React.useState({ year: 'loading...', kelas: 'loading...', week: undefined, masterLoading: true, loading: true, saving: false, reload: false, total: 0 })
    const [daily, setDaily] = React.useState({ data: [], loading: true, date: undefined })
    const id = props.match.params.id
    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => i)
    }

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvyactivitymaster/${id}`);
            setData({ ...data, data: result, year: result.kelas.year.name, kelas: result.kelas.class.name, masterLoading: false, loading: false })
        }
        fetchData();
    }, [id])

    React.useEffect(() => {
        const fetchWeek = async (v) => {
            const result = await Fetch.get(`/savvyactivity/${id}/week/${v - 1}`);
            const arr = result.data
            for (let i = 0; i < arr.length; i++) {
                const date = arr[i].date;
                if (date) arr[i].date = moment(date)
            }
            setData({ ...data, loading: false, activities: arr, reload: false, total: arr.filter(x => !x.date).length })
        }
        if (data.week > 0 || (data.reload && data.week > 0))
            fetchWeek(data.week)
    }, [data.week, data.reload])

    React.useEffect(() => {
        const fetchDate = async (v) => {
            const result = await Fetch.get(`/savvyactivity/${id}/date/${v}`);
            const arr = result.data
            for (let i = 0; i < arr.length; i++) {
                const date = arr[i].date;
                if (date) arr[i].date = moment(date)
            }
            setDaily({ ...daily, loading: false, data: arr })
        }
        if (daily.date)
            fetchDate(daily.date.format('DD-MM-YYYY'))
    }, [daily.date])

    const onChange = (v) => {
        setData({ ...data, week: v, loading: true })
    }

    const onClick = async () => {
        if (!daily.date) return;
        setData({ ...data, saving: true })
        try {
            const arr = []
            for (let i = 0; i < daily.data.length; i++) {
                arr.push({ id: daily.data[i].id, order: i })
            }
            await Fetch.post(`/savvyactivity/${id}/date/${daily.date.format('DD-MM-YYYY')}`, { data: arr });
            message.success('berhasil menyimpan data')
        } catch (e) {
            message.error(e)
        }
        setData({ ...data, saving: false })
    }

    const Action = () => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 4 }}>
                <Button type='primary' loading={data.saving} onClick={onClick} disabled={!daily.date}>Simpan</Button>
            </div>

        </div>
    }

    const getSpectrum = (v) => {
        return data.data.spectrums.filter(v1 => v1.id === v)[0]
    }

    const onWeekDoubleClicked = (id) => {
        return () => {
            if (!daily.date) {
                message.error('Error: silakan pilih tanggal kegiatan')
                return
            }
            const item = data.activities.find(v2 => v2.id === id)
            if (item.date) return;
            item.date = daily.date
            daily.data.push(item)
            setDaily({ ...daily })
            setData({ ...data, total: data.activities.filter(x => !x.date).length })
        }
    }

    const onDateSelected = (v) => {
        setDaily({ ...daily, date: v })
        setData({ ...data, reload: true })
    }

    const goUp = (item) => {
        return () => {
            const dData = daily.data;
            const idx = dData.findIndex(v => v.id === item.id)
            if (idx === 0 || idx < 0) return;
            dData.splice(idx, 1)
            dData.splice(idx - 1, 0, item)
            setDaily({ ...daily, data: dData })
        }
    }

    const goDown = (item) => {
        return () => {
            const dData = daily.data;
            const idx = dData.findIndex(v => v.id === item.id)
            if (idx >= dData.length - 1) return;
            dData.splice(idx, 1)
            dData.splice(idx + 1, 0, item)
            setDaily({ ...daily, data: dData })
        }
    }

    const deleteItem = (item) => {
        return () => {
            const newDaily = daily.data.filter(v => v.id !== item.id)
            setDaily({ ...daily, data: newDaily })
            const it = data.activities.find(v2 => v2.id === item.id)
            if (it) {
                it.date = undefined;
                setData({ ...data, total: data.activities.filter(x => !x.date).length })
            }
        }
    }

    const onDownloadClicked = () => {
        if (!daily.date || !daily.data || (daily.data && daily.data.length === 0)) {
            message.error('data masih kosong')
            return
        }
        const url = `/savvyactivity/${id}/date/${daily.date.format('DD-MM-YYYY')}/download?token=${Fetch.token}`
        window.open(Fetch.getUrl(url))
    }

    const isDownloadDisabled = () => {
        return (!daily.date || !daily.data || (daily.data && daily.data.length === 0))
    }

    return (
        <Card title={<Title title={`Kegiatan harian - ${data.kelas} - ${data.year}`} actions={<Action />} />} loading={data.masterLoading}>
            {!data.masterLoading &&
                <div >
                    <Row gutter={32}>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Select placeholder="Pilih minggu" style={{ width: 200 }} onChange={onChange} value={data.week} disabled={data.saving}>
                                        {range(0, 34).map(v => {
                                            return <Select.Option key={v} value={v + 1}>{`Minggu ke-${v + 1}`}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                                <Typography >Total: {data.total}</Typography>
                            </div>
                            <Divider />
                            {data.week && !data.loading &&
                                <div style={{ overflowY: 'auto', height: 500 }}>
                                    <List dataSource={data.activities} loading={data.loading} renderItem={item => {
                                        return <List.Item onDoubleClick={onWeekDoubleClicked(item.id)} style={{ cursor: 'pointer' }}>
                                            <div style={{ width: '100%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ flexGrow: 1 }}>
                                                        <Typography style={{ color: item.date ? 'gainsboro' : undefined }}>{getSpectrum(item.spectrum_id).value}</Typography>
                                                    </div>
                                                    {item.date && <div>
                                                        <Typography style={{ color: 'silver' }}>{item.date.format('ddd, DD-MM-YY')}</Typography>
                                                    </div>}
                                                </div>
                                                <Typography style={{ color: item.date ? 'gainsboro' : undefined }}>{item.value}</Typography>
                                            </div>
                                        </List.Item>
                                    }}>
                                    </List>
                                </div>
                            }
                        </Col>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <div style={{ display: 'flex' }}>
                                        <DatePicker locale={locale} placeholder='Pilih tanggal kegiatan' onChange={onDateSelected} format="ddd, DD-MM-YY" showToday={false} />
                                        <div style={{ width: 8 }} />
                                        <Button type='warning' disabled={isDownloadDisabled()} icon="download" style={{ marginRight: 8 }} onClick={onDownloadClicked}>Download</Button>
                                    </div>
                                </div>
                                <Typography >Total: {daily.data.length}</Typography>
                            </div>
                            <Divider />
                            {daily.date &&
                                <div style={{ overflowY: 'auto', height: 500 }}>
                                    <List dataSource={daily.data} itemLayout="horizontal" loading={daily.loading} renderItem={item => {
                                        return <List.Item >
                                            <div style={{ width: '100%' }}>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <Typography>{getSpectrum(item.spectrum_id).value}</Typography>
                                                    </div>
                                                    <Button.Group>
                                                        <Button type='primary' size="small" icon="up" onClick={goUp(item)} />
                                                        <Button type='primary' size="small" icon="down" onClick={goDown(item)} />
                                                        <Popconfirm placement="bottomRight" title={`Yakin menghapus?`} onConfirm={deleteItem(item)} okText="Iya" cancelText="Tidak">
                                                            <Button type="danger" size="small" icon="delete" />
                                                        </Popconfirm>
                                                    </Button.Group>
                                                </div>
                                                <Typography>{item.value}</Typography>
                                            </div>
                                        </List.Item>
                                    }}>
                                    </List>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
            }
        </Card >
    )
}

export default Daily;