import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Switch } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { USER_DRIVER } from '../../constant'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/pickup/${props.match.params.id}`, { ...values, active: values.active_internal ? 1 : 0 });
                        message.info('Shuttle berhasil diedit');
                    } else {
                        await Fetch.post('/pickup', { ...values, active: values.active_internal ? 1 : 0 });
                        message.info('Shuttle berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/pickup');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const { data } = await Fetch.get(`/pickup/${props.match.params.id}`);
        setState({ ...state, loading: false });
        setFieldsValue({
            name: data.name,
            driver_id: data.driver.id,
            active_internal: true//data.active === 1 ? true : false
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit shuttle" : "Tambah shuttle"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_shuttle" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan nama" />
                            )}
                        </Form.Item>
                        <Form.Item label="Driver">
                            {getFieldDecorator('driver_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih driver" dataIndex="name" url={`/user?filter[type]=${USER_DRIVER}`} />
                            )}
                        </Form.Item>
                        <Form.Item label="Aktif">
                            {getFieldDecorator('active_internal')(
                                <Switch>Aktif</Switch>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_shuttle' })(Add);