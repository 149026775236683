import React from 'react';
import { Card, Form, Row, Icon, Col, Divider, Button, Input, Upload, Typography, Modal, message, Select } from 'antd';
import Title from '../../component/title';
import { pop } from '../../component/link';
import Fetch from '../../Fetch';
import { bookType, bookLanguage } from '../../constant'

const PagebookAdd = props => {
    const [preview, setPreview] = React.useState({ visible: false, image: '' });
    const [fileList, setFileList] = React.useState([]);
    const { getFieldDecorator } = props.form;
    const edit = props.match.params.id !== undefined;
    const [loading, setLoading] = React.useState(edit);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const images = fileList.length === 0 ? ['default.jpg'] : fileList.map(v => v.response.filename);
                    if (edit) await Fetch.put(`/book/${props.match.params.id}`, { ...values, images, year: parseInt(values.year) });
                    else await Fetch.post('/book', { ...values, images, year: parseInt(values.year) });
                    message.info('Buku berhasil ditambahkan');
                    pop(props, '/book');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const { data } = await Fetch.get(`/book/${props.match.params.id}`);
        setLoading(false);
        const { setFieldsValue } = props.form;
        setFieldsValue({
            title: data.title,
            description: data.description,
            author: data.author,
            isbn: data.isbn,
            year: data.year,
        });
        if (data.images.length === 0) {
            setFileList([{
                uid: data.image,
                response: {
                    filename: data.image,
                },
                url: Fetch.getUrl(`/public/uploads/book/${data.image}`)
            }])
        } else {
            setFileList(data.images.map(v => {
                return {
                    uid: v,
                    response: {
                        filename: v,
                    },
                    url: Fetch.getUrl(`/public/uploads/book/${v}`)
                }
            }));
        }
    }

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleUploadChange = (val) => {
        console.log(val);
        //if (val.file.status === 'done')
        setFileList(val.fileList);
    }

    return (
        <Card title={<Title title={edit ? "Edit master buku" : "Tambah master buku"} {...props} />} loading={loading}>
            <Form layout="horizontal" name="create_book" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={16}>
                        <Form.Item label="Judul">
                            {getFieldDecorator('title', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan judul buku" autosize />
                            )}
                        </Form.Item>
                        <Form.Item label="Penulis / Pengarang">
                            {getFieldDecorator('author', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan penulis / pengarang" autosize />
                            )}
                        </Form.Item>
                        <Form.Item label="Deskipsi">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan deskripsi" autosize />
                            )}
                        </Form.Item>
                        <Form.Item label="ISBN">
                            {getFieldDecorator('isbn', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan isbn buku" autosize="true" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tahun">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input type="number" placeholder="Masukkan tahun" autosize="true" />
                            )}
                        </Form.Item>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }],
                            })(
                                <Select placeholder="Pilih tipe" >
                                    {bookType.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Bahasa">
                            {getFieldDecorator('language', {
                                rules: [{ required: true, message: 'Harus diisi' }],
                            })(
                                <Select placeholder="Pilih bahasa">
                                    {bookLanguage.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Row>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                <Typography.Text style={{ marginBottom: 20, color: 'black', marginRight: 10 }}>Gambar:</Typography.Text>
                            </Col>
                            <Col span={16}>
                                <div style={{ marginTop: 10 }}>
                                    <Upload action={Fetch.getUrl(`/book/photo`)} listType="picture-card" name={"upload"} fileList={fileList}
                                        headers={{ Authorization: `Bearer ${Fetch.token}` }} onChange={handleUploadChange}
                                        onPreview={(file) => { setPreview({ visible: true, image: file.url || file.thumbUrl }); }}
                                    >
                                        {uploadButton}
                                    </Upload>
                                    <Modal visible={preview.visible} footer={null} onCancel={() => { setPreview({ ...preview, visible: false }) }}>
                                        <img alt="sample" style={{ width: '100%' }} src={preview.image} />
                                    </Modal>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_book' })(PagebookAdd);