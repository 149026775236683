import React from 'react';
import { Card, Form, Row, Icon, Col, Divider, Button, Input, Upload, Typography, Modal, message } from 'antd';
import Title from '../../component/title';
import MySelect from '../../component/select';
import { pop } from '../../component/link';
import Fetch from '../../Fetch';

const PageBulletinAdd = props => {
    const [preview, setPreview] = React.useState({ visible: false, image: '' });
    const [fileList, setFileList] = React.useState([]);
    const { getFieldDecorator } = props.form;
    const edit = props.match.params.id !== undefined;
    const [loading, setLoading] = React.useState(edit);

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const image = fileList.length === 0 ? 'default.jpg' : fileList[0].response.filename;
                    const images = fileList.length === 0 ? [] : fileList.map(v => v.response.filename);
                    if (edit) await Fetch.put(`/bulletin/${props.match.params.id}`, { ...values, image, images });
                    else await Fetch.post('/bulletin', { ...values, image, images });
                    message.info('Pengumuman berhasil ditambahkan');
                    pop(props, '/bulletin');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const { data } = await Fetch.get(`/bulletin/${props.match.params.id}`);
        setLoading(false);
        const { setFieldsValue } = props.form;
        setFieldsValue({
            channel_ids: data.channel_ids,
            title: data.title,
            body: data.body,
            notif_title: data.notif_title,
            notif_body: data.notif_body,
        });
        if (data.images.length === 0) {
            setFileList([{
                uid: data.image,
                response: {
                    filename: data.image,
                },
                url: Fetch.getUrl(`/public/uploads/bulletin/${data.image}`)
            }])
        } else {
            setFileList(data.images.map(v => {
                return {
                    uid: v,
                    response: {
                        filename: v,
                    },
                    url: Fetch.getUrl(`/public/uploads/bulletin/${v}`)
                }
            }));
        }
    }

    const uploadButton = (
        <div>
            <Icon type="plus" />
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleUploadChange = (val) => {
        console.log(val.fileList)
        setFileList(val.fileList);
    }

    return (
        <Card title={<Title title={edit ? "Edit pengumuman" : "Tambah pengumuman"} {...props} />} loading={loading}>
            <Form layout="horizontal" name="create_bulletin" onSubmit={formSummited}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Channel">
                            {getFieldDecorator('channel_ids', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/channel?filter[active]=1&count=0" dataIndex="name" mode="multiple" placeholder="Pilih channel"></MySelect>
                            )}
                        </Form.Item>
                        <Form.Item label="Judul berita">
                            {getFieldDecorator('title', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan judul berita" autosize />
                            )}
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Form.Item label="Judul notif">
                            {getFieldDecorator('notif_title', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan judul notifikasi" autosize />
                            )}
                        </Form.Item>
                        <Form.Item label="Isi notif">
                            {getFieldDecorator('notif_body', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan isi notifikasi" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Typography.Text style={{ marginBottom: 20 }}>Gambar</Typography.Text>
                <div style={{ marginTop: 10 }}>
                    <Upload action={Fetch.getUrl(`/bulletin/photo`)} listType="picture-card" name={"upload"} fileList={fileList}
                        headers={{ Authorization: `Bearer ${Fetch.token}` }} onChange={handleUploadChange}
                        onPreview={(file) => { setPreview({ visible: true, image: file.url || file.thumbUrl }); }}
                    >
                        {uploadButton}
                    </Upload>
                    <Modal visible={preview.visible} footer={null} onCancel={() => { setPreview({ ...preview, visible: false }) }}>
                        <img alt="sample" style={{ width: '100%' }} src={preview.image} />
                    </Modal>
                </div>
                <Form.Item label="Isi berita">
                    {getFieldDecorator('body', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <Input.TextArea placeholder="Masukkan isi berita" rows={16} />
                    )}
                </Form.Item>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_bulletin' })(PageBulletinAdd);