import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import StudentSelectDialog from '../share/studentselectdialog';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.path === '/shuttle/item/:shuttleid/:id/edit';
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, studentDialogVisible: false });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    const { student, ...rest } = values;
                    if (edit) {
                        await Fetch.put(`/pickup/student/${props.match.params.shuttleid}/${props.match.params.id}`, { ...rest, student_id: props.match.params.id });
                        message.info('Shuttle berhasil diedit');
                    } else {
                        await Fetch.post(`/pickup/student/${props.match.params.id}`, { ...rest, student_id: state.student.id });
                        message.info('Shuttle berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, `/shuttle/${props.match.params.id}`);
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const { data } = await Fetch.get(`/pickup/${props.match.params.shuttleid}`);
        const item = data.pickup_items.find(v => v.student.id === props.match.params.id)
        console.log(item)
        setFieldsValue({
            student: item.student.fullname,
            price: item.price,
            address: item.address,
        });
        setState({ ...state, loading: false, student: item });
    }

    const onStudentSelected = (obj) => {
        setState({ ...state, studentDialogVisible: false, student: obj })
        setFieldsValue({ student: obj.fullname, address: obj.address })
    }

    return (
        <Card title={<Title title={edit ? "Edit shuttle" : "Tambah shuttle"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_shuttle_item" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama">
                            {getFieldDecorator('student', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input readOnly placeholder="Pilih siswa" addonAfter={edit ? null : <span className="span-click" onClick={() => setState({ ...state, studentDialogVisible: true })}>Pilih</span>} />
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('address', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Alamat" />
                            )}
                        </Form.Item>
                        <Form.Item label="Biaya">
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan biaya" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
            <StudentSelectDialog visible={state.studentDialogVisible} onCancel={() => setState({ ...state, studentDialogVisible: false })}
                onStudentSelected={onStudentSelected} title="Pilih siswa" />
        </Card>
    );
}

export default Form.create({ name: 'create_shuttle_item' })(Add);