import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/bookkeep/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/bookkeep/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [, tb] = useTable('/bookkeep', { filter: { active: 1 } })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/bookkeep/${id}`);
                message.info("Berhasil menghapus ekskul");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus ekskul: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/bookkeep/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/bookkeep/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/bookkeep/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/bookkeep/${record.id}/clone`}><Icon type="copy" style={{ marginRight: 8 }} />Clone</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar peminjaman buku"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="date" dataIndex="date" title="Tanggal" render={(t) => formatDate(t, 'dd-MM-yyyy')} />
                <Table.Column key="date_return" dataIndex="date_return" title="Kembali" render={(t) => t === null ? '' : formatDate(t, 'dd-MM-yyyy')} />
                <Table.Column key="book_item.book.title" dataIndex="book_item.book.title" title="Judul" {...tb.columnProps({ key: 'title', hint: 'Cari judul' })} />
                <Table.Column key="code" dataIndex="book_item.code" title="Kode" {...tb.columnProps({ key: 'code', hint: 'Cari kode' })} />
                <Table.Column key="student" dataIndex="student.fullname" title="Siswa" {...tb.columnProps({ key: 'student_name', hint: 'Cari nama siswa' })} />
                <Table.Column key="book_item.book.isbn" dataIndex="book_item.book.isbn" title="ISBN" {...tb.columnProps({ key: 'isbn', hint: 'Cari isbn' })} />
                <Table.Column key="status" dataIndex="status" title="Status" render={(t) => t === 'open' ? 'Proses' : 'Selesai'}
                    {...tb.columnProps({ key: 'status', type: 'radio', options: [{ text: 'Open', value: 'open' }, { text: 'Selesai', value: 'done' }] })} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default List;