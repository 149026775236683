import React, { Fragment } from 'react';
import { Card, Row, Col, Empty, Table } from 'antd';
import { TextField } from '../../component/field';
import { MyLink } from '../../component/link'
import useTable from '../../component/table';

const DetailKelas = props => {
    const { loading, student } = props;
    const [, tb] = useTable('/kelas', { loading: false }, {}, true);
    const stArr = () => student.kelas_histories ? student.kelas_histories.map(v => v + ",") : [student.current_kelas.id]

    React.useEffect(() => {
        if (student) tb.setFilter({ id: stArr() });
    }, [student])

    if (loading) return null;
    return <Fragment>
        <Card title="Kelas saat ini" bordered={false}>
            {student.current_kelas != null && <Row gutter={16}>
                <Col span={24}>
                    <TextField label="Tahun ajaran" >{student.current_kelas.year.name}</TextField>
                    <TextField label="Kelas" ><MyLink to={`/kelas/${student.current_kelas.id}`}>{student.current_kelas.class.name}</MyLink></TextField>
                </Col>
            </Row>}
        </Card>
        <Card title="Sejarah kelas" bordered={false}>
            {student.kelas_histories.length === 0 && <Empty description="Tidak ada data sejarah kelas" />}
            {student.kelas_histories.length > 0 &&
                <Table {...tb.tableProps} >
                    <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran" />
                    <Table.Column key="class.name" dataIndex="class.name" title="Kelas" render={(t, r) => <MyLink to={`/kelas/${r.id}`}>{t}</MyLink>} />
                    <Table.Column key="guru" dataIndex="teachers" title="Guru kelas" render={t => t.map(v => v.name + ', ')} />
                </Table>
            }
            <Row gutter={16} >
            </Row>
        </Card>
    </Fragment>

}

export default DetailKelas;