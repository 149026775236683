import React, { Fragment } from 'react';
import useTable from '../../component/table'
import { Table, Tag } from 'antd';
import { monthToString } from '../../component/util'
import { paymentTypeToString, paymentStatusToString, paymentStatusToColor, paymentOrderTypeToString } from '../../constant'

const DetailPayment = props => {
    const { loading, student } = props;
    if (loading) return null;
    const [, tb] = useTable('/payment', { filter: { student_id: student.id } });

    return <Fragment>
        <Table {...tb.tableProps}>
            <Table.Column key="year" dataIndex="year.name" title="Tahun ajaran" />
            <Table.Column key="month" dataIndex="month" title="Bulan" render={monthToString} />
            <Table.Column key="type" dataIndex="type" title="Tipe" render={paymentTypeToString} />
            <Table.Column key="status" dataIndex="status" title="Status"
                render={(t, r) => <>
                    <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>
                    {t == 3 ? <Tag color='blue'>{paymentOrderTypeToString(r.payment_order_type)}</Tag> : <></>}
                </>
                } />
            <Table.Column key="detail" dataIndex="detail" title="Detail" />
            <Table.Column key="subtotal" dataIndex="subtotal" title="Subtotal" align="right" render={(t) => t.toLocaleString('in-ID')} />
            <Table.Column key="discount" dataIndex="discount" title="Discount" align="right" render={(t) => t.toLocaleString('in-ID')} />
            <Table.Column key="total" dataIndex="total" title="Total" align="right" render={(t) => t.toLocaleString('in-ID')} />
        </Table>
    </Fragment>
}

export default DetailPayment;