import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Button, Divider, Table, Drawer, Input, Typography } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import { MyLink } from '../../component/link';
import { formatDate } from '../../component/util'
import Fetch from '../../Fetch';
import { bankVaToString } from '../../constant';

const Detail = props => {
    const [{ record, loading }] = useDetail(`/paymentorder/${props.match.params.id}`, {})
    const [state, setState] = React.useState({ sendMailOpen: false, email: '' })
    const lspan = { labelSpan: 8, valueSpan: 16 }

    const Action = (props) => {
        return <Button.Group>
            {props.record.status === 3 && <Button icon="printer" href={Fetch.getUrl(`/paymentorder/${record.id}/print/pdf`)} target="_blank">Print</Button>}
            {/*props.record.status === 3 && <Button icon="to-top" onClick={() => setState({ ...state, sendMailOpen: true })}>Send Email</Button>*/}
        </Button.Group>;
    }

    React.useEffect(() => {
        if (record && Object.entries(record).length !== 0) setState({ ...state, email: record.user === null ? record.register_item.email : record.user.name })
    }, [record])

    const sendMail = () => {

    }

    return (
        <Card loading={loading} title={<Title title={"Detail pembayaran"} actions={<Action record={record} />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField {...lspan} label="No pembayaran" >{record.number}</TextField>
                            <TextField {...lspan} label="Status" >{<Tag color={record.status === 3 ? 'green' : 'red'}>{record.status === 3 ? 'Lunas' : 'Belum'}</Tag>}</TextField>
                            <TextField {...lspan} label="Tipe" >{<Tag color={record.type === 1 ? 'green' : 'blue'}>{record.type === 1 ? 'Cash' : record.type === 2 ? 'Midtrans' : 'Flip'}</Tag>}</TextField>
                            <TextField {...lspan} label="Tanggal bayar" >{formatDate(record.payment_date, false)}</TextField>
                            {record.for === 1 && <TextField {...lspan} label="Tujuan" >{`Untuk pendaftaran ${record.register_item.register.class.name} ${record.register_item.register.year.name}`}</TextField>}
                        </Col>
                        <Col span={12}>
                            <TextField {...lspan} label="Email" >{record.for === 0 ? <MyLink to={`/user/${record.user.id}`}>{record.user.email}</MyLink> :
                                record.register_item.name}</TextField>
                            <TextField {...lspan} label="Oleh" >{record.for === 0 ? <MyLink to={`/user/${record.user.id}`}>{record.user.name}</MyLink> :
                                record.register_item.name}</TextField>
                            <TextField {...lspan} label="Tujuan" >{<Tag color={record.type === 1 ? 'green' : 'blue'}>{record.for === 0 ? 'Normal' : 'Pendaftaran'}</Tag>}</TextField>
                            <TextField {...lspan} label="Total" >{`Rp ${record.total.toLocaleString('in-ID')}`}</TextField>
                        </Col>
                    </Row>
                    {record.for === 0 && <Divider />}
                    {record.for === 0 && <Table rowKey="id" dataSource={record.payments} pagination={false} size={"small"} scroll={{ x: true }}>
                        <Table.Column key="number" dataIndex="student.number" title="No Induk" render={(r, s) => <MyLink to={`/student/${s.student.id}`}>{r}</MyLink>} />
                        <Table.Column key="name" dataIndex="student.name" title="Nama siswa" />
                        <Table.Column key="voucher" dataIndex="voucher" title="voucher?"
                            render={(t) => t ? `${t.title} - ${t.amount.toLocaleString('in-ID')}` : '-'} />
                        <Table.Column key="detail" dataIndex="detail" title="Detail" />
                        <Table.Column key="total" dataIndex="total" title="Total" align="right"
                            render={(t, r) => r.voucher ? (t - r.voucher.amount).toLocaleString('in-ID') :
                                t.toLocaleString('in-ID')} />
                    </Table>}
                    {record.flip_data &&
                        <React.Fragment>
                            <Divider />
                            <Typography.Text strong>Detail Pembayaran Flip</Typography.Text>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <TextField {...lspan} label="Bank" >{bankVaToString(record.flip_data.bill_payment.sender_bank)}</TextField>
                                    <TextField {...lspan} label="Nomor VA" >{record.flip_data.bill_payment.receiver_bank_account.account_number}</TextField>
                                    <TextField {...lspan} label="Nama VA" >{record.flip_data.bill_payment.receiver_bank_account.account_holder}</TextField>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                    <Drawer visible={state.sendMailOpen} onClose={() => setState({ ...state, sendMailOpen: false })} title="Kirim invoice ke email" width={400}>
                        <Input placeholder="Masukkan alamat email" value={state.email} />
                        <br /><br />
                        <Button type="primary" onClick={sendMail}>Kirim</Button>
                    </Drawer>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;