import React from 'react';
import { Dropdown, Menu, Icon, Avatar } from 'antd';
import { GlobalStore } from '../store/store'
import { push } from '../component/link'

const ProfileButton = props => {
    const logout = () => {
        localStorage.removeItem('token');
        props.history.replace('/login');
    }

    const handleProfile = () => {
        push(props, '/profile');
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={handleProfile}>
                <Icon type="pie-chart" />
                <span>My Profil</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={logout}>
                <Icon type="logout" />
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <div style={{ color: 'white', cursor: 'pointer' }}>
                <Avatar size={32} icon="user" />
                <span style={{ marginLeft: '16px' }} >My Profile</span>
                <Icon type="down" style={{ marginLeft: '16px' }} />
            </div>
        </Dropdown>
    );
}

const LayoutHeader = props => {
    const [{ global }, dispatch] = React.useContext(GlobalStore);
    const menuClick = () => {
        dispatch({ type: 'TOGGLE_SIDE_BAR' })
    }
    return (
        <div style={{ margin: '0 16px 0 16px', display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <span><Icon className="collapse-toggle" type={global.collapse ? "menu-unfold" : "menu-fold"} onClick={menuClick} />
                    <span style={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}>Bumi Bambini</span>
                </span>
            </div >
            <ProfileButton {...props} />
        </div >
    );
}

export default LayoutHeader;