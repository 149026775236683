import React from 'react';
import { Card, Table, Button, message, } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';

const DoImport = props => {
    const colNum = 35
    const columns = [{
        title: 'Perkembangan dasar',
        width: 350,
        dataIndex: 'name',
        render: (value, row, index) => {
            const obj = {
                children: row.value,
            };
            if (row.type === 'spectrum') {
                return {
                    children: row.spectrum,
                    props: {
                        colSpan: colNum + 2,
                        style: { background: 'aliceblue' }
                    },
                }
            } else if (row.type === 'basic') {
                return {
                    children: <div>{row.basic}</div>,
                    props: {
                        rowSpan: row.count,
                    }
                }
            } else if (row.type === 'indicator') {
                return {
                    props: {
                        rowSpan: 0,
                    }
                }
            }
            return obj;
        },
    }, {
        title: 'Indikator perkembangan',
        dataIndex: 'age',
        width: 450,
        render: (value, row, index) => {
            if (row.type === 'spectrum') {
                return {
                    props: { colSpan: 0 },
                };
            } else if (row.type === 'basic' || row.type === 'indicator') {
                //return <div style={{ wordBreak: 'break-all', inlineSize: '450px', wordWrap: 'break-word', textOverflow: 'ellipsis' }}>{row.indicator}</div>
                return { children: row.indicator }
            }
        },
    }, { title: '', dataIndex: 'number', render: (value, row, index) => { return { children: row.number } } }];
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({ data: [], column: [], year: 'loading...', grade: 'loading...' })
    const id = props.match.params.id

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvy/import/${id}`);
            let data = [];
            const fillPlots = (plots, obj) => {
                for (let i = 0; i < plots.length; i++) {
                    obj[`plot${plots[i]}`] = 'X'
                }
                return obj
            }
            for (let i = 0; i < result.spectrums.length; i++) {
                const spec = result.spectrums[i];
                data.push({ spectrum: spec.value, type: 'spectrum' })
                let number = 1;
                for (let j = 0; j < spec.basics.length; j++) {
                    const basic = spec.basics[j];
                    data.push(fillPlots(basic.indicators[0].plots, { basic: basic.value, type: 'basic', count: basic.indicators.length, indicator: basic.indicators[0].value, number: number++ }))
                    for (let k = 1; k < basic.indicators.length; k++) {
                        const indi = basic.indicators[k];
                        data.push(fillPlots(indi.plots, { indicator: indi.value, type: 'indicator', number: number++ }))
                    }
                }
            }
            const column = [columns[0], columns[2], columns[1]];
            for (let i = 0; i < colNum; i++) {
                column.push({
                    title: `${i + 1}`, dataIndex: `plot${i}`, width: 40, render: (value, row, index) => {
                        if (row.type === 'spectrum') {
                            return {
                                children: row.value,
                                props: { colSpan: 0 },
                            };
                        }
                        return { children: row[`plot${i}`] }
                    }
                })
            }
            setData({ column: column, data: data, year: result.year.name, grade: result.grade.name })
        }
        fetchData();
    }, [id])

    const processClicked = async () => {
        setLoading(true)
        try {
            const result = await Fetch.get(`/savvy/import/${id}/do`);
            message.info("import berhasil")
            props.history.replace(`/savvy/${result.id}`)
        } catch (e) {
            message.error(e.toString())
        }
        setLoading(false)
    }

    const Action = () => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" loading={loading} onClick={processClicked} >Prosess Import</Button>
            </Button.Group>
        </div>
    }

    return (
        <Card title={<Title title={`Daftar Indikator dan Spektrum - ${data.grade} - ${data.year}`} actions={<Action />} />}>
            <Table style={{ whiteSpace: 'pre' }} columns={data.column} dataSource={data.data} scroll={{ x: true, y: 450 }} size='small' bordered pagination={false} />
        </Card >
    )
}

export default DoImport;