import React, { Fragment } from 'react';
import { Row, Col, Empty } from 'antd';
import { TextField } from '../../component/field';
import { push } from '../../component/link'
import Fetch from '../../Fetch';

const DetailShuttle = props => {
    const { loading, student } = props;
    const [state, setState] = React.useState({ data: null, loading: true, })
    if (loading) return null;

    React.useEffect(() => {
        if (student.current_pickup) {
            Fetch.get(`/pickup/${student.current_pickup}`)
                .then(({ data }) => {
                    const pi = data.pickup_items.find(v => v.student.id === student.id)
                    setState({ ...state, data: data, loading: false, pickup: pi });
                })
        } else {
            setState({ ...state, data: [], loading: false });
        }
    }, [student]);

    return !state.loading && <Fragment>
        {student.current_pickup == null && <Empty description="Tidak ada data penjemputan" />}
        {student.current_pickup != null && <Row gutter={16}>
            <Col span={24}>
                <TextField label="Nama penjemputan" >{state.data.name}</TextField>
                <TextField label="Nama driver" ><a href={`/user/${state.data.driver.id}`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/user/${state.data.driver.id}`);
                }}>{state.data.driver.name}</a></TextField>
                <TextField label="Alamat penjemputan" >{state.pickup.address}</TextField>
                <TextField label="Biaya" >{state.pickup.price.toLocaleString('in-ID')}</TextField>
            </Col>
        </Row>}
    </Fragment>

}

export default DetailShuttle;