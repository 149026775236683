import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Popconfirm, Table } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { LabelHeader } from '../../component/header';
import UserSelectorDialog from '../share/userselectdialog';
import { USER_TEACHER, CLASS_NORMAL } from '../../constant';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const clone = props.match.path === '/kelas/:id/clone'
    const [state, setState] = React.useState({ cardLoading: edit, saveLoading: false, showUserDialog: false });
    const [teacher, setTeacher] = React.useState([])
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const t = teacher.map(v => v.id);
                    if (edit && !clone) {
                        await Fetch.put(`/kelas/${props.match.params.id}`, { ...values, teacher_id: t });
                        message.info('Kelas berhasil diedit');
                    } else {
                        await Fetch.post('/kelas', { ...values, teacher_id: t, active: 1 });
                        message.info('Kelas berhasil ditambahkan');
                    }
                    pop(props, '/kelas');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, cardLoading: true });
        const { data } = await Fetch.get(`/kelas/${props.match.params.id}`);
        setState({ ...state, cardLoading: false });
        setFieldsValue({
            class_id: data.class.id,
            year_id: clone ? undefined : data.year.value,
        });
        setTeacher(data.teachers.map(v => ({ id: v.id, name: v.name })))
    }

    const onTeacherDelete = (id) => {
        return () => {
            setTeacher(teacher.filter(v => v.id !== id))
        }
    }

    return (
        <Card title={<Title title={edit ? clone ? "Clone kelas" : "Edit kelas" : "Tambah kelas"} {...props} />} loading={state.cardLoading}>
            <Form layout="horizontal" name="create_kelas" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tahun Ajaran">
                            {getFieldDecorator('year_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/year" dataIndex="name" valueIndex="value" placeholder="Pilih tahun ajaran" />
                            )}
                        </Form.Item>
                        <Form.Item label="Kelas">
                            {getFieldDecorator('class_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url={`/class?filter[type]=${CLASS_NORMAL}`} dataIndex="name" valueIndex="id" placeholder="Pilih kelas" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <LabelHeader actions={<Button onClick={() => setState({ ...state, showUserDialog: true })}>Tambah guru</Button>}>Guru</LabelHeader>
                        <Table dataSource={teacher} rowKey="id">
                            <Table.Column key="name" dataIndex="name" title="Nama" />
                            <Table.Column key="id" dataIndex="id" title="Action" render={(t) => {
                                return <Popconfirm placement="bottomRight" title={"Yakin menghapus guru?"} onConfirm={onTeacherDelete(t)} okText="Iya" cancelText="Tidak">
                                    <span className="span-click">Delete</span>
                                </Popconfirm>
                            }} />
                        </Table>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
            <UserSelectorDialog title="Pilih guru" type={USER_TEACHER} visible={state.showUserDialog} onUserSelected={v => {
                setState({ ...state, showUserDialog: false })
                const find = teacher.find(v1 => v1.id === v.id);
                if (find === undefined) setTeacher([...teacher, v]);
                else message.error('Guru sudah ada')
            }} onClose={() => setState({ ...state, showUserDialog: false })} width={600} />
        </Card>
    );
}

export default Form.create({ name: 'create_kelas' })(Add);