import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Modal, Radio, Divider, Button, message } from 'antd';
import { TextField } from '../../component/field';
import Fetch from '../../Fetch';
import { push } from '../../component/link'

const StatusModel = props => {
    const [value, setValue] = React.useState(props.status)
    const [saving, setSaving] = React.useState(false)
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const onChanged = e => {
        setValue(e.target.value)
    }

    const onSave = async () => {
        setSaving(true)
        try {
            await Fetch.put(`/student/status/${props.studentId}`, {
                status: value
            });
            message.info('Murid berhasil diedit');
            props.refresh();
        } catch (err) {
            message.error('Error: ' + err);
        }
        setSaving(false)
    }

    return <Modal title="Ganti status siswa" footer={null} {...props} height={1024}>
        <Radio.Group value={value} buttonStyle="solid" onChange={onChanged}>
            <Radio style={radioStyle} value="active">Aktif</Radio>
            <Radio style={radioStyle} value="graduated">Lulus</Radio>
            <Radio style={radioStyle} value="nonactive">Non aktif</Radio>
        </Radio.Group>
        <Divider />
        <Button type="primary" loading={saving} onClick={onSave}>Simpan</Button>
    </Modal>
}

const DetailInfo = props => {
    const [state, setState] = React.useState({ visible: false })
    const { loading, photo, student } = props;

    const statusClicked = () => {
        setState({ ...state, visible: true })
    }

    if (loading) return null;
    return <Fragment>
        <Row gutter={16}>
            <Col span={6}>
                <img alt="foto murid" src={Fetch.getUrl(`/public/uploads/studentphotos/${photo === '' ? 'default.jpg' : photo}`)} width={'100%'} style={{ borderRadius: 10 }} />
            </Col>
            <Col span={18}>
                <TextField label="No Induk" >{student.number}</TextField>
                <TextField label="Nama lengkap" >{student.fullname}</TextField>
                <TextField label="Nama" >{student.name}</TextField>
                <TextField label="Status" >{<Tag color={student.status === 'active' ? 'green' : student.status === 'nonactive' ? 'red' : 'blue'} onClick={statusClicked}>
                    {student.status === 'active' ? 'Aktif' : student.status === 'nonactive' ? 'Nonaktif' : 'Lulus'}</Tag>}</TextField>
                <TextField label="Tempat lahir" >{student.birth_place}</TextField>
                <TextField label="Tanggal lahir" >{(new Date(student.birth_date)).toLocaleString('in-ID')}</TextField>
                <TextField label="Gender" >{student.gender === 'L' ? 'Laki-laki' : 'Perempuan'}</TextField>
                <TextField label="Agama" >{student.religion == null ? '' : student.religion.name}</TextField>
                <TextField label="Address" >{student.address}</TextField>
                <TextField label="User ortu" ><span className="span-click" onClick={() => push(props, `/user/${student.parent.id}`)}>
                    {student.parent == null ? '' : student.parent.name}</span></TextField>
            </Col>
        </Row>
        {student.father != null && <Row gutter={16}>
            <Col span={12}>
                <Card title="Orang tua Ayah" bordered={false}>
                    <TextField label="Name" >{student.father.name}</TextField>
                    <TextField label="Phone" >{student.father.phone}</TextField>
                    <TextField label="Pekerjaan" >{student.father.job}</TextField>
                    <TextField label="Alamat" >{student.father.address}</TextField>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Orang tua Ibu" bordered={false}>
                    <TextField label="Name" >{student.mother.name}</TextField>
                    <TextField label="Phone" >{student.mother.phone}</TextField>
                    <TextField label="Pekerjaan" >{student.mother.job}</TextField>
                    <TextField label="Alamat" >{student.mother.address}</TextField>
                </Card>
            </Col>
        </Row>}
        <Card title="Diskon" bordered={false}>
            <Col span={12}>
                <TextField label="Tahunan" >{student.discount.yearly === '' ? '-' : student.discount.yearly}</TextField>
            </Col>
            <Col span={12}>
                <TextField label="SPP" >{student.discount.spp === '' ? '-' : student.discount.spp}</TextField>
            </Col>
        </Card>
        <StatusModel studentId={student.id} status={student.status} refresh={props.refresh}
            visible={state.visible} onCancel={() => { setState({ ...state, visible: false }) }} />
    </Fragment>
}

export default DetailInfo;