import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="plus" type="primary" href={`/register/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/register/add`);
                }}>Tambah</Button>
                <Button icon="download" href={`/register/import`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/register/import`);
                }}>Import Siswa</Button>
            </Button.Group>
        </div>
    );
}

const RegisterPage = props => {
    const [, tb] = useTable('/register', {})

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/register/${id}`);
                message.info("Berhasil menghapus ekskul");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus ekskul: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/register/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/register/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/register/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    const onActivate = (record) => {
        return async () => {
            const ms = message.loading("Loading...", 0);
            await Fetch.put(`/extra/${record.id}`, { is_active: !record.is_active });
            ms();
            tb.fetchData();
        }
    }

    return (
        <Card title={<Title title={"Daftar register"} actions={<Actions {...props} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="class.name" dataIndex="class.name" title="Kelas" />
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran" />
                <Table.Column key="open_date" dataIndex="open_date" title="Tanggal Buka" render={(t) => (new Date(t)).toLocaleString('in-ID')} />
                <Table.Column key="is_active" dataIndex="is_active" title="Aktif" render={(t, r) =>
                    <Popconfirm placement="bottomRight" title={`Yakin ${r.is_active ? 'non aktifkan' : 'aktifkan'} ${r.class.name} ?`} onConfirm={onActivate(r)} okText="Iya" cancelText="Tidak">
                        <Tag color={t ? 'green' : 'red'}>{t ? 'Aktif' : 'Non aktif'}</Tag></Popconfirm>}
                    {...tb.columnProps({ key: 'is_active', type: 'radio', options: [{ text: 'Aktif', value: true }, { text: 'Non aktif', value: false }] })} />
                <Table.Column key="price" dataIndex="price" title="Harga" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="capacity" dataIndex="capacity" title="Kapasitas" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="count" dataIndex="count" title="Isi" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="registerer" dataIndex="registerer" title="Pendaftar" align='right' render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default RegisterPage;