import React from 'react';
import { Card, Typography, Tag, List, Input, Divider, Button, message } from 'antd';
import Fetch from '../../Fetch';
import ImgCarousel from './imagecarausel';
import Title from '../../component/title';
import Actions from './editdeleteaction';

const PageBulletinDetail = props => {
    const [loading, setLoading] = React.useState(true);
    const [record, setRecord] = React.useState({});
    const [comment, setComment] = React.useState('');
    const [commentLoading, setCommentLoading] = React.useState(false);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const res = await Fetch.get(`/bulletin/${props.match.params.id}`);
        setRecord(res.data);
        setLoading(false);
    }

    const commentChange = (val) => {
        setComment(val.target.value);
    }

    const saveClicked = async () => {
        if (comment === "") {
            message.error('Komen tidak boleh kosong')
            return;
        }
        setCommentLoading(true);
        try {
            await Fetch.post(`/bulletin/comment/${props.match.params.id}`, { comment });
            message.info('Komentar berhasil ditambahkan');
            setCommentLoading(false);
            fetchData();
        } catch (err) {
            message.error(`Error: ${err}`);
            setCommentLoading(false);
        }
    }

    return (
        <Card loading={loading} title={<Title title={"Detail Pengumuman"} actions={<Actions dataId={props.match.params.id} />} />}>
            {!loading &&
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <ImgCarousel image={record.image} images={record.images} height={400} />
                    </div>
                    <div style={{ paddingTop: 24 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography.Text strong style={{ fontSize: 16, cursor: 'pointer' }} >{record.title}</Typography.Text>
                            <div><Typography.Text disabled style={{ fontSize: 12 }}>{(new Date(record.date)).toLocaleString('in-ID')}</Typography.Text></div>
                            <div style={{ marginTop: 10, marginBottom: 10, display: 'block' }}>{record.channel.map(v => {
                                return <Tag color="green" key={v.id}>{v.name}</Tag>
                            })}</div>
                            <div><Typography.Text>{record.body.split('\n').map((v, i) => <span key={i}>{v}<br /></span>)}</Typography.Text></div>
                            <div style={{ marginTop: 20 }}><Typography.Text disabled>{record.comment_num} komentar</Typography.Text></div>
                            <div style={{ marginLeft: 20 }}>
                                <List itemLayout={'vertical'} dataSource={record.comments || []} renderItem={item => {
                                    return (
                                        <List.Item>
                                            <div>
                                                <Typography.Text strong style={{ display: 'block' }}>{item.user_short.name}</Typography.Text>
                                                <Typography.Text disabled style={{ fontSize: 12 }}>{(new Date(item.date).toLocaleString('in-ID'))}</Typography.Text>
                                                <Typography.Text style={{ display: 'block' }}>{item.comment}</Typography.Text>
                                            </div>
                                        </List.Item>
                                    );
                                }} />
                                <Divider />
                                <Input.TextArea placeholder={"Masukkan komentar Anda"} onChange={commentChange}></Input.TextArea>
                                <div><Button onClick={saveClicked} type="primary" style={{ marginTop: 10 }} loading={commentLoading}>Simpan Komentar</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Card >
    );
}

export default PageBulletinDetail;