import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Divider, Popconfirm, message, Tag, Typography } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field';
import ImgCarousel from './imagecarausel';
import useTable from '../../component/table';
import { push, MyLink } from '../../component/link';
import { Button } from 'antd/lib/radio';
import Fetch from '../../Fetch';
import AddItem from './additem';
import { formatDate } from '../../component/util'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/book/${props.match.params.id}`);
    const [, tb] = useTable('/bookitem', { loading: false }, {}, true);
    const [state, setState] = React.useState({ modalVisible: false, item: null })
    const tFieldSpan = { labelSpan: 8, valueSpan: 16 }

    React.useEffect(() => {
        tb.setFilter({ book_id: record.id });
    }, [record])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/book/${record.id}`)
            }
        }
    }

    const onBookItemRemoved = (t) => {
        return async () => {
            try {
                await Fetch.del(`/bookitem/${t}`)
                message.info('Berhasil menghapus item')
                tb.fetchData();
            } catch (err) {
                message.error('Error menghapus item: ' + err)
            }
        }
    }

    const printQr = () => {
        window.open(Fetch.getUrl(`/book/print/${record.id}?token=${Fetch.token}`))
    }

    return (
        <Card loading={loading} title={<Title title={"Detail buku"} actions={<Action active={record.active} dataId={record.id || '1'} reload={fetchData} printQr={printQr} />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <div>
                            <ImgCarousel images={record.images} height={400} />
                        </div>
                    </Col>
                    <Col span={12}>
                        <TextField {...tFieldSpan} label="Judul">{record.title}</TextField>
                        <TextField {...tFieldSpan} label="Pengarang">{record.author}</TextField>
                        <TextField {...tFieldSpan} label="Deskripsi"><span>{record.description.split('\n').map((item, i) => <p key={i}>{item}</p>)}</span></TextField>
                        <TextField {...tFieldSpan} label="ISBN">{record.isbn}</TextField>
                        <TextField {...tFieldSpan} label="Tahun">{record.year}</TextField>
                        <TextField {...tFieldSpan} label="Total / Tersedia">{record.items.length} / {record.available}</TextField>
                    </Col>
                </Row>
                <Divider />
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography.Text style={{ fontSize: 16, color: '#505050' }}>Item Buku</Typography.Text>
                        <div><Button loading={true}
                            onClick={() => setState({ ...state, modalVisible: true, item: null })}>Tambah item</Button></div>
                    </div>
                    <Divider style={{ marginBottom: 8, marginTop: 8 }} />
                </div>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="code" dataIndex="code" title="Kode" render={(t, r) => <a href={`/bookitem/${r.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            push(props, `/bookitem/${r.id}`);
                        }
                        }>{t}</a>}
                        {...tb.columnProps({ key: 'code', hint: 'Cari kode' })} />
                    <Table.Column key="student" dataIndex="student" title="Posisi" render={(t) => t == null ? <Tag color="green">Tersedia</Tag> : <MyLink to={`/student/${t.id}`}>{t.fullname}</MyLink>} />
                    <Table.Column key="date_out" dataIndex="date_out" title="Tanggal pinjam" render={r => r === null ? '' : formatDate(r)} />
                    <Table.Column dataIndex="id" title="Action" render={(t, record) => {
                        return <div><Popconfirm placement="bottomRight" title={"Yakin menghapus item?"} onConfirm={onBookItemRemoved(t, record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">Delete</span>
                        </Popconfirm>
                            <span style={{ marginLeft: 16 }} className="span-click" onClick={() => {
                                setState({ ...state, item: record, modalVisible: true })
                            }}>Edit</span>
                        </div>
                    }} />
                </Table>
                <Divider />
                <AddItem visible={state.modalVisible} onCancel={() => setState({ ...state, modalVisible: false })}
                    book={record} refresh={() => {
                        fetchData();
                        tb.fetchData();
                    }} item={state.item} />
            </Fragment>}
        </Card >
    );
}

export default Detail;