import React, { Fragment } from 'react';
import { Col, Table } from 'antd';
import useTable from '../../component/table';
import { formatDate } from '../../component/util';

const DetailBook = props => {
    const { loading, student } = props;
    const [, tb] = useTable('/bookkeep', { order: { key: 'date', order: 'desc' } }, { key: 'book_' }, true)
    if (loading) return null;

    React.useEffect(() => {
        tb.setFilter({ 'student_id': student.id })
    }, [student]);

    return <Fragment>
        <Col span={24}>
            <Table {...tb.tableProps}>
                <Table.Column key="date" dataIndex="date" title="Tanggal" render={(t) => formatDate(t, 'dd-MM-yyyy')} />
                <Table.Column key="date_return" dataIndex="date_return" title="Kembali" render={(t) => t === null ? 'Belum' : formatDate(t, 'dd-MM-yyyy')} />
                <Table.Column key="book_item.book.title" dataIndex="book_item.book.title" title="Judul" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="code" dataIndex="book_item.code" title="Kode" {...tb.columnProps({ key: 'code', hint: 'Cari kode' })} />
                <Table.Column key="book_item.book.isbn" dataIndex="book_item.book.isbn" title="ISBN" {...tb.columnProps({ key: 'isbn', hint: 'Cari isbn' })} />
                <Table.Column key="status" dataIndex="status" title="Status" render={(t) => t === 'open' ? 'Proses' : 'Selesai'}
                    {...tb.columnProps({ key: 'status', type: 'radio', options: [{ text: 'Open', value: 'open' }, { text: 'Selesai', value: 'done' }] })} />
                }} />
                </Table>
        </Col>
    </Fragment >

}

export default DetailBook;