import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Divider, Popconfirm, message, Tag, Modal, Radio, Button } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import { push, MyLink } from '../../component/link';
import StudentRemoveModel from './studentremovemodal';
import StudentSelectDialog from '../share/studentselectdialog';
import Fetch from '../../Fetch';

const StatusModal = props => {
    const [value, setValue] = React.useState(props.status)
    const [saving, setSaving] = React.useState(false)
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const onChanged = e => {
        setValue(e.target.value)
    }

    const onSave = async () => {
        setSaving(true)
        try {
            await Fetch.put(`/kelas/active/${props.kelasId}`, {
                active: value
            });
            message.info('Kelas berhasil diedit');
            props.onCancel();
            props.refresh();
        } catch (err) {
            message.error('Error: ' + err);
        }
        setSaving(false)
    }

    return <Modal title="Ganti status kelas" footer={null} {...props} height={1024}>
        <Radio.Group value={value} buttonStyle="solid" onChange={onChanged}>
            <Radio style={radioStyle} value={1}>Aktif</Radio>
            <Radio style={radioStyle} value={0}>Non Aktif</Radio>
        </Radio.Group>
        <Divider />
        <Button type="primary" loading={saving} onClick={onSave}>Simpan</Button>
    </Modal>
}

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/kelas/${props.match.params.id}`);
    const [, tb] = useTable('/student', { loading: false }, {}, true);
    const [state, setState] = React.useState({ modalVisible: false, selectStudentVisible: false, addStudentLoading: false, naikKelasVisible: false, statusVisible: false })
    const stArr = () => record.students ? record.students.length === 0 ? ["5ba971e48ef8a663a8350b50"] : record.students : ["5ba971e48ef8a663a8350b50"]

    React.useEffect(() => {
        if (record) tb.setFilter({ id_in: stArr() });
    }, [record])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/student/${record.id}`)
            }
        }
    }

    const onStudentRemoved = (t) => {
        return async () => {
            try {
                await Fetch.del(`/kelas/student/${record.id}/${t}`)
                message.info('Berhasil menghapus siswa')
                fetchData();
            } catch (err) {
                message.error('Error menghapus siswa: ' + err)
            }
        }
    }

    const onStudentSelected = async (o) => {
        setState({ ...state, selectStudentVisible: false })
        try {
            await Fetch.put(`/kelas/student/${record.id}`, { student_id: o.id });
            message.info('Berhasil menambah siswa pada kelas');
            fetchData();
        } catch (err) {
            message.error('Error menambah siswa: ' + err)
        }
    }

    const statusClicked = () => {
        setState({ ...state, statusVisible: true })
    }

    return (
        <Card loading={loading} title={<Title title={"Detail kelas"} actions={<Action record={record} dataId={record.id || '1'}
            naikKelasClick={() => { }} />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField label="Tahun Ajaran">{record.year.name}</TextField>
                        <TextField label="Kelas">{record.class.name}</TextField>
                        <TextField label="Status"><Tag color={record.active ? 'green' : 'red'} onClick={statusClicked}>
                            {record.active ? 'Aktif' : 'Non aktif'}</Tag></TextField>
                    </Col>
                    <Col span={12}>
                        <LabelHeader>Guru</LabelHeader>
                        <Table dataSource={record.teachers} rowKey="id">
                            <Table.Column key="name" dataIndex="name" title="Nama" render={(t, r) => <MyLink to={`/user/${r.id}`}>{t}</MyLink>} />
                        </Table>
                    </Col>
                </Row>
                <Divider />
                <div style={{ marginTop: 20 }}>
                    <LabelHeader actions={<div><Button
                        onClick={() => setState({ ...state, selectStudentVisible: true, addStudentLoading: true })}>Tambah siswa</Button></div>}>Siswa</LabelHeader>
                </div>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="number" dataIndex="number" title="NIK" render={(t, r) => <MyLink to={`/student/${r.id}`}>{t}</MyLink>}
                        {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })}  {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                    <Table.Column key="name" dataIndex="name" title="Nama"  {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                    <Table.Column key="fullname" dataIndex="fullname" title="Nama lengkap"  {...tb.columnProps({ key: 'fullname', hint: 'Cari nama lengkap' })} />
                    <Table.Column key="gender" dataIndex="gender" title="L/P" render={v => v === 'L' ? 'Laki-laki' : 'Perempuan'}
                        {...tb.columnProps({ key: 'gender', type: 'radio', options: [{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }] })} />
                    <Table.Column dataIndex="id" title="Action" render={(t, record) => {
                        return <Popconfirm placement="bottomRight" title={"Yakin menghapus siswa?"} onConfirm={onStudentRemoved(t, record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">Delete</span>
                        </Popconfirm>
                    }} />
                </Table>
            </Fragment>}
            <StudentRemoveModel visible={state.modalVisible} onCancel={() => setState({ ...state, modalVisible: false })} record={state.record}
                okText="Hapus dan masukan history" cancelText="Hapus tanpa masuk history" />
            {!loading && <StudentSelectDialog visible={state.selectStudentVisible} onCancel={() => setState({ ...state, selectStudentVisible: false })}
                filter={{ grade_in: record.class.grade.value, id_nin: stArr() }} onStudentSelected={onStudentSelected} title="Pilih siswa" />}
            {!loading && <StatusModal kelasId={record.id} status={record.active} refresh={fetchData}
                visible={state.statusVisible} onCancel={() => { setState({ ...state, statusVisible: false }) }} />}
        </Card >
    );
}

export default Detail;