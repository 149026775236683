import React from 'react';
import { Card, Row, Col, Typography, Button, Switch, Divider, message, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';

const Setting = props => {
    const [state, setState] = React.useState({ data: {}, loading: true })

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const { data } = await Fetch.get(`/config/5ba971e48ef8a663a8350b50`);
        setState({ ...state, loading: false, data: data });
    }

    const saveClicked = async () => {
        try {
            await Fetch.put(`/config/5ba971e48ef8a663a8350b50`, {
                year: state.data.current_year.value,
                midtrans: state.data.midtrans,
                flip: state.data.flip,
            })
            message.success('berhasil menyimpan')
        } catch (e) {
            message.error(e)
        }
    }

    /*const formatDate = (v) => {
        return moment(v).format('ddd, DD-MMM-YYYY')
    }*/

    const va = [
        //{ name: 'BCA', id: 'bca' },
        { name: 'BNI', id: 'bni' },
        { name: 'BRI', id: 'bri' },
        { name: 'Mandiri', id: 'mandiri' },
        { name: 'CIMB', id: 'cimb' },
        //{ name: 'BTPN/Jenius', id: 'tabungan_pensiunan_nasional' },
        //{ name: 'DBS', id: 'dbs' },
        { name: 'Permata', id: 'permata' },
        //{ name: 'Muamalat', id: 'muamalat' },
        { name: 'Danamon', id: 'danamon' },
        { name: 'BSI', id: 'bsm' },
    ];

    const bankEnabled = (id) => {
        if (state.data.flip.va) {
            const va = state.data.flip.va.filter(v => v === id)
            return va.length > 0
        }
        return false
    }

    return (
        <Card title={<Title title={"Global setting"} actions={<Button type="primary" onClick={saveClicked}>Simpan</Button>} />}
            loading={state.loading}>
            {!state.loading &&
                <Row style={{ height: '100%' }} gutter={32}>
                    <Col span={12}>
                        <Card title={"Tahun ajaran"}>
                            <Row style={{ height: '100%' }} gutter={32}>
                                <Col span={12}>Tahun ajaran saat ini</Col>
                                <Col span={12}>
                                    <div>
                                        <MySelect url="/year" dataIndex="name" valueIndex="value" value={state.data.current_year.value}
                                            placeholder="Pilih tahun ajaran" style={{ width: '100%' }}
                                            onChange={(v) => {
                                                state.data.current_year.value = v
                                                setState({ ...state })
                                            }} />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        {/*
                        <Card title={"Pembayaran Midtrans"}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    Aktifkan pembayaran Midtrans
                                </div>
                                <Switch defaultChecked={state.data.midtrans.enabled} onChange={(v) => {
                                    state.data.midtrans.enabled = v
                                    setState({ ...state })
                                }} />
                            </div>
                        </Card>
                            */}
                        <Card title={"Pembayaran Flip"} style={{ marginTop: 12 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                <div>
                                    Aktifkan pembayaran Flip
                                </div>
                                <Switch defaultChecked={state.data.flip.enabled} onChange={(v) => {
                                    state.data.flip.enabled = v
                                    setState({ ...state })
                                }} />
                            </div>
                            {state.data.flip.enabled &&
                                <React.Fragment>
                                    <Divider />
                                    <Row>
                                        <Col span={12}>Biaya admin</Col>
                                        <Col span={12}><InputNumber placeholder="Masukkan biaya admin" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                            parser={value => value.replace(/\$\s?|(\.*)/g, '')} onChange={(v) => {
                                                state.data.flip.additional = v;
                                                setState({ ...state })
                                            }} value={state.data.flip.additional} /></Col>
                                    </Row>
                                    <div style={{ marginTop: 20 }} />
                                    <Typography.Text strong>Virtual Account</Typography.Text><div style={{ marginTop: 20 }} />
                                    {va.map(v => {
                                        return <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }} key={v.id}>
                                            <div>
                                                {v.name}
                                            </div>
                                            <Switch defaultChecked={bankEnabled(v.id)} onChange={(newVal) => {
                                                if (state.data.flip.va === null) state.data.flip.va = []
                                                if (newVal) {
                                                    state.data.flip.va.push(v.id)
                                                } else {
                                                    state.data.flip.va = state.data.flip.va.filter(item => item !== v.id)
                                                }
                                                setState({ ...state })
                                            }} />
                                        </div>
                                    })}
                                </React.Fragment>
                            }
                        </Card>
                    </Col>
                </Row>
            }
        </Card >
    )
}

export default Setting;