import React from 'react';
import { Card, Row, Col, Button, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title'
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import { monthIntToString, paymentTypeToString, paymentStatusToString, paymentStatusToColor, paymentOrderTypeToString } from '../../constant'
import { push, pop, MyLink } from '../../component/link';
import Fetch from '../../Fetch'

const Actions = props => {

    const onConfirm = async () => {
        try {
            await Fetch.del(`/payment/${props.dataId}`);
            message.info('Pembayaran berhasil dihapus');
            if (props.reload !== undefined) {
                props.reload();
            } else {
                pop(props, '/payment');
            }
        } catch (err) {
            message.error('Error: ' + err);
        }
    }
    return (
        <Button.Group>
            <Button icon="edit" href={`/payment/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/payment/${props.dataId}/edit`);
            }}>Edit</Button>
            <Popconfirm placement="bottomLeft" title={"Yakin menghapus ekstrakulikuler?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                <Button icon="delete" type="danger">Hapus</Button>
            </Popconfirm>
        </Button.Group>
    );
}

const Detail = props => {
    const [{ record, loading }] = useDetail(`/payment/${props.match.params.id}`);
    const fieldSpan = { labelSpan: 6, valueSpan: 18 };

    return (
        <Card loading={loading} title={<Title title={"Detail pembayaran"} actions={record.status === 3 ? null : <Actions {...props} dataId={record.id} record={record} />} />} >
            {!loading &&
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField label="Nama" {...fieldSpan}>{record.student.name}</TextField>
                        <TextField label="Status" {...fieldSpan}>{
                            <>
                                <Tag color={paymentStatusToColor(record.status)}>{paymentStatusToString(record.status)}</Tag>
                                {record.status == 3 ? <Tag color='blue'>{paymentOrderTypeToString(record.payment_order_type)}</Tag> : <></>}
                            </>
                        }</TextField>
                        <TextField label="Tipe" {...fieldSpan}>{paymentTypeToString(record.type)}</TextField>
                        <TextField label="Tahun" {...fieldSpan}>{record.year.name}</TextField>
                        <TextField label="Bulan" {...fieldSpan}>{monthIntToString(record.month)}</TextField>
                    </Col>
                    <Col span={12}>
                        <TextField label="Detail" {...fieldSpan}>{record.detail}</TextField>
                        <TextField label="Subtotal" {...fieldSpan}>{record.subtotal.toLocaleString('in-ID')}</TextField>
                        <TextField label="Diskon" {...fieldSpan}>{record.discount.toLocaleString('in-ID')}</TextField>
                        <TextField label="Total" {...fieldSpan}>{record.total.toLocaleString('in-ID')}</TextField>
                        <TextField label="Catatan" {...fieldSpan}>{record.note}</TextField>
                        {record.link && <TextField label="Link DP" {...fieldSpan}><MyLink to={`/payment/${record.link}`}>DP</MyLink></TextField>}
                    </Col>
                </Row>
            }
        </Card >
    );
}

export default Detail;