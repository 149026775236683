import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { userTypeToString, userType } from '../../constant';
import ResetPassword from './resetpassword';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={`/user/add`} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/user/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const User = props => {
    const [, tb] = useTable('/user', {})
    const [modalState, setModalState] = React.useState({ visible: false })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/user/${id}`);
                message.info("Berhasil menghapus user");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus user: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/user/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/user/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/user/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item><span className="span-click2" onClick={() => setModalState({ ...modalState, visible: true, record: record })}><Icon type="lock" style={{ marginRight: 8 }} />Reset password</span></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar User"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="username" dataIndex="username" title="Username" {...tb.columnProps({ key: 'username', hint: 'Cari username' })} />
                <Table.Column key="name" dataIndex="name" title="Nama" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="type" dataIndex="type" title="Tipe" render={userTypeToString} {...tb.columnProps({ key: 'type', type: 'radio', options: userType })} />
                <Table.Column key="email" dataIndex="email" title="Email" {...tb.columnProps({ key: 'email', hint: 'Cari email' })} />
                <Table.Column key="phone" dataIndex="phone" title="No Telp" />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
            <ResetPassword visible={modalState.visible} onCancel={() => setModalState({ ...modalState, visible: false })} record={modalState.record} />
        </Card >
    )
}

export default User;