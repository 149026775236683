import React from 'react';
import { Card, Button, message, Divider, Row, Col, DatePicker, List, Typography, Input } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';
import MySelect from '../../component/select';
import Score from '../../component/score';

const Detail = props => {
    const [data, setData] = React.useState({ year: 'loading...', kelas: 'loading...', masterLoading: true, reload: false, total: 0 })
    const [state, setState] = React.useState({ data: [], loading: true, date: undefined, student: undefined, download: false, note: '' })
    const [score, setScore] = React.useState({})
    const id = props.match.params.id
    const kelasId = props.match.params.kelasid;

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvyactivitymaster/${id}`);
            setData({ ...data, data: result, year: result.kelas.year.name, kelas: result.kelas.class.name, masterLoading: false })
        }
        fetchData();
    }, [id])

    React.useEffect(() => {
        const fetchDate = async (v, student) => {
            try {
                const result = await Fetch.get(`/savvyactivity/${id}/date/${v}`);
                const arr = result.data
                for (let i = 0; i < arr.length; i++) {
                    const date = arr[i].date;
                    if (date) arr[i].date = moment(date)
                }
                let studentScore = {};
                let resultStudent = {};
                try {
                    resultStudent = await Fetch.get(`/savvydailyscore/${kelasId}/student/${student}/date/${v}`);
                    for (let i = 0; i < resultStudent.data.length; i++) {
                        const d = resultStudent.data[i];
                        studentScore[d.savvy_activity_id] = { score: d.score, note: d.note }
                    }
                } catch (e) { }
                setState({
                    ...state, loading: false, data: result.data, download: Object.keys(studentScore).length === arr.length,
                    note: resultStudent.summary ? resultStudent.summary.note : ''
                })
                setScore(studentScore)
            } catch (e) {
                setState({ ...state, loading: false, data: [], download: false, note: '' })
                setScore({})
            }
        }
        if (state.date && state.student)
            fetchDate(state.date.format('DD-MM-YYYY'), state.student)
    }, [state.date, state.student])

    const onClick = async () => {
        if (!state.date || !state.student) return;
        if (Object.keys(score).length !== state.data.length) {
            message.error("semua kegiatan belum terisi")
            return
        }
        setData({ ...data, saving: true })
        try {
            const arr = []
            for (let i = 0; i < state.data.length; i++) {
                const d = score[state.data[i].id]
                arr.push({ savvy_activity_id: state.data[i].id, score: d.score, note: d.note })
            }
            await Fetch.post(`/savvydailyscore/${kelasId}/student/${state.student}/date/${state.date.format('DD-MM-YYYY')}`, { data: arr, note: state.note });
            message.success('berhasil menyimpan data')
            setState({ ...state, download: true })
        } catch (e) {
            message.error(e)
        }
        setData({ ...data, saving: false })
    }

    const onDownloadClicked = () => {
        const url = `/savvydailyscore/${kelasId}/student/${state.student}/date/${state.date.format('DD-MM-YYYY')}/download?token=${Fetch.token}`
        window.open(Fetch.getUrl(url))
    }

    const Action = () => {
        return <div style={{ display: 'flex' }}>
            <div>
                <Button type='warning' disabled={!state.download} icon="download" style={{ marginRight: 8 }} onClick={onDownloadClicked}>Download</Button>
                <Button type='primary' loading={data.saving} onClick={onClick} disabled={!state.date}>Simpan</Button>
            </div>
        </div>
    }

    const getSpectrum = (v) => {
        return data.data.spectrums.filter(v1 => v1.id === v)[0]
    }

    const onDateSelected = (v) => {
        setState({ ...state, loading: true, date: v })
    }

    const onStudentSelected = (v) => {
        setState({ ...state, loading: true, student: v })
    }

    const onScoreChange = (v) => {
        return (idx) => {
            let newScore = score[v.id]
            if (!newScore) newScore = {}
            newScore.score = idx
            setScore({ ...score, [v.id]: newScore })
        }
    }

    const getScore = (id) => {
        if (score[id]) return score[id].score
        return undefined
    }

    const onScoreNoteChange = (v) => {
        return e => {
            let newScore = score[v.id]
            if (!newScore) newScore = {}
            newScore.note = e.target.value
            setScore({ ...score, [v.id]: newScore })
        }
    }

    const getScoreNote = (id) => {
        if (score[id] && score[id].note) return score[id].note
        return ''
    }

    const onNoteChange = (e) => {
        setState({ ...state, note: e.target.value })
    }

    return (
        <Card title={<Title title={`Penilaian harian - ${data.kelas} - ${data.year}`} actions={<Action />} />} loading={data.masterLoading}>
            {!data.masterLoading &&
                <div >
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <DatePicker locale={locale} placeholder='Pilih tanggal' onChange={onDateSelected} format="ddd, DD-MM-YY" showToday={false} />
                        </div>
                        <div style={{ marginLeft: 8 }}>
                            <MySelect url={`/student?filter[kelas_in]=${data.data.kelas.id}`} dataIndex="name" valueIndex="id" placeholder="Pilih siswa" style={{ width: 300 }}
                                onChange={onStudentSelected} />
                        </div>
                    </div>
                    <Divider />
                    {state.date && state.student &&
                        <div style={{ overflowY: 'auto', height: 500 }}>
                            <Typography >Catatan umum untuk hari ini</Typography>
                            <Input.TextArea placeholder='Masukan catatan umum jika ada' onChange={onNoteChange} value={state.note}
                                autoSize={{ minRows: 2, maxRows: 6 }} allowClear />
                            <div style={{ height: 16 }} />
                            <Card title="Penilaian kegiatan harian">
                                <List dataSource={state.data} itemLayout="horizontal" loading={state.loading} renderItem={item => {
                                    return <List.Item key={item.id}>
                                        <div style={{ width: '100%' }}>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <Typography>{getSpectrum(item.spectrum_id).value}</Typography>
                                                        </div>
                                                    </div>
                                                    <Typography>{item.value}</Typography>
                                                </Col>
                                                <Col span={12}>
                                                    <div style={{ marginBottom: 8 }}>
                                                        <Score value={getScore(item.id)} onChange={onScoreChange(item)} />
                                                    </div>
                                                    <Input.TextArea allowClear placeholder='Masukan catatan jika ada' autoSize={{ minRows: 2, maxRows: 6 }}
                                                        onChange={onScoreNoteChange(item)} value={getScoreNote(item.id)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </List.Item>
                                }}>
                                </List>
                            </Card>
                        </div>
                    }
                </div>}
        </Card >
    )
}

export default Detail;