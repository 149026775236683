import React, { Fragment } from 'react';
import { Card, Table, Tag } from 'antd';
import { push } from '../../component/link';
import Fetch from '../../Fetch';

const DetailExtra = props => {
    const { loading, student } = props;
    const [state, setState] = React.useState({ data: null, loading: true })
    const [current, setCurrent] = React.useState({ data: null, loading: true })
    if (loading) return null;

    const onRow = (record) => {
        return {
            onClick: () => {
                push(props, `/extra/${record.id}`);
            }
        }
    }

    React.useEffect(() => {
        if (student.extra_histories.length === 0) {
            setState({ ...state, data: [], loading: false });
        } else {
            Fetch.get(`/extra?filter[id_in]=${student.extra_histories.map(v => v + ",")}`)
                .then(res => setState({ ...state, data: res.data, loading: false }))
        }
        if (student.current_extra.length === 0) {
            setCurrent({ ...current, data: [], loading: false })
        } else {
            Fetch.get(`/extra?filter[id_in]=${student.current_extra.map(v => v.id + ",")}`)
                .then(res => setCurrent({ ...current, data: res.data, loading: false }))
        }
    }, [student]);

    return <Fragment>
        <Card title="Extrakulikuler saat ini" bordered={false}>
            {student.current_extra != null &&
                <Table dataSource={current.data} onRow={onRow} rowKey="id" loading={current.loading}>
                    <Table.Column key="name" dataIndex="name" title="Nama" />
                    <Table.Column key="description" dataIndex="description" title="Deskripsi" />
                    <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran" />
                    <Table.Column key="semester" dataIndex="semester" title="Semester" render={(t) => t === 1 ? 'Ganjil' : 'Genap'} />
                    <Table.Column key="type" dataIndex="type" title="Tipe" render={r => <Tag color={r === 1 ? 'green' : 'blue'}>{r === 1 ? 'Regular' : 'Non regular'}</Tag>} />
                </Table>
            }
        </Card>
        <Card title="Sejarah extrakulikuler" bordered={false}>
            <Table dataSource={state.data} onRow={onRow} rowKey="id" loading={state.loading}>
                <Table.Column key="name" dataIndex="name" title="Nama" />
                <Table.Column key="description" dataIndex="description" title="Deskripsi" />
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran" />
                <Table.Column key="semester" dataIndex="semester" title="Semester" render={(t) => t === 1 ? 'Ganjil' : 'Genap'} />
                <Table.Column key="type" dataIndex="type" title="Tipe" render={r => <Tag color={r === 1 ? 'green' : 'blue'}>{r === 1 ? 'Regular' : 'Non regular'}</Tag>} />
            </Table>
        </Card>
    </Fragment>

}

export default DetailExtra;