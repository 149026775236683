import React, { useEffect } from 'react';
import { Drawer, Form, Input, Button, message } from 'antd';
import Fetch from '../../Fetch';

const AddItem = props => {
    const { getFieldDecorator, resetFields, setFieldsValue } = props.form;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (props.item === null) {
                        await Fetch.post('/bookitem', { ...values, book_id: props.book.id });
                        message.info('Buku berhasil ditambahkan');
                    } else {
                        await Fetch.put(`/bookitem/${props.item.id}`, { ...values });
                        message.info('Buku berhasil diubah');
                    }
                    resetFields();
                    props.onCancel();
                    props.refresh();
                } catch (err) {
                    message.error(err)
                }
            }
        });
    }

    useEffect(() => {
        if (props.item === null) {
            resetFields();
        } else {
            setFieldsValue({ 'code': props.item.code, 'note': props.item.note })
        }
    }, [props.item])

    return <Drawer visible={props.visible} onClose={props.onCancel} width={500} title="Tambah item buku" >
        <Form layout="horizontal" name="itemadd" onSubmit={formSummited} >
            <Form.Item label="Kode buku">
                {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Harus diisi' }]
                })(
                    <Input placeholder="Masukkan kode buku" />
                )}
            </Form.Item>
            <Form.Item label="Catatan">
                {getFieldDecorator('note', {})(
                    <Input.TextArea placeholder="Masukkan catatan" autosize />
                )}
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Simpan
                    </Button>
            </Form.Item>
        </Form>
    </Drawer>
}

export default Form.create({ name: 'itemadd' })(AddItem);