import React, { Fragment } from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Select, Checkbox } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import { userType, USER_OWNER, USER_PARENT, USER_SCHOOL_ADMIN, USER_DRIVER, USER_TEACHER, USER_SECURITY, USER_PERPUS } from '../../constant';

const UserAdd = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [loading, setLoading] = React.useState(edit);

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { type, ...rest } = values
                    let t = USER_PARENT;
                    if (type.indexOf(USER_OWNER) >= 0) t = USER_OWNER;
                    else if (type.indexOf(USER_SCHOOL_ADMIN) >= 0) t = USER_SCHOOL_ADMIN;
                    else if (type.indexOf(USER_DRIVER) >= 0) t = USER_DRIVER;
                    else if (type.indexOf(USER_TEACHER) >= 0) t = USER_TEACHER;
                    else if (type.indexOf(USER_SECURITY) >= 0) t = USER_SECURITY;
                    else if (type.indexOf(USER_PERPUS) >= 0) t = USER_PERPUS;
                    const vals = {
                        ...rest, type: t, is_parent: type.indexOf(USER_PARENT) >= 0 ? 1 : 0,
                        is_teacher: type.indexOf(USER_TEACHER) >= 0 ? 1 : 0, is_driver: type.indexOf(USER_DRIVER) >= 0 ? 1 : 0, user_type: type,
                    }
                    if (edit) {
                        await Fetch.put(`/user/${props.match.params.id}`, vals);
                        message.info('User berhasil diedit');
                    } else {
                        await Fetch.post('/user', { ...vals, sendemail: vals.sendemail ? 1 : 0 });
                        message.info('User berhasil ditambahkan');
                    }
                    pop(props, '/user');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const { data } = await Fetch.get(`/user/${props.match.params.id}`);
        setLoading(false);
        const { setFieldsValue } = props.form;
        let type = [data.type]
        if (data.is_parent === 1 && type.indexOf(USER_PARENT) < 0) type.push(USER_PARENT);
        if (data.is_driver === 1 && type.indexOf(USER_DRIVER) < 0) type.push(USER_DRIVER);
        if (data.is_teacher === 1 && type.indexOf(USER_TEACHER) < 0) type.push(USER_TEACHER);
        setFieldsValue({
            name: data.name,
            username: data.username,
            type: type,
            email: data.email,
            phone: data.phone,
            address: data.address,
        });
    }

    const randomPassword = () => {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < 6; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    }

    return (
        <Card title={<Title title={edit ? "Edit user" : "Tambah user"} {...props} />} loading={loading}>
            <Form layout="horizontal" name="create_user" onSubmit={formSummited}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan nama" />
                            )}
                        </Form.Item>
                        <Form.Item label="Username">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan username" readOnly={edit} />
                            )}
                        </Form.Item>
                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Harus diisi' }, { type: 'email', message: 'Harus valid email' }]
                            })(
                                <Input type="email" placeholder="Masukkan email" />
                            )}
                        </Form.Item>
                        <Form.Item label="Telepon">
                            {getFieldDecorator('phone', {})(
                                <Input type="phone" placeholder="Masukkan telepon" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }],
                            })(
                                <Select placeholder="Pilih tipe user" mode="multiple">
                                    {userType.map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Alamat">
                            {getFieldDecorator('address', {})(
                                <Input.TextArea placeholder="Masukkan alamat" autosize />
                            )}
                        </Form.Item>
                        {!edit &&
                            <Fragment>
                                <Form.Item label="Password">
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Harus diisi' }]
                                    })(
                                        <Input type="password" placeholder="Masukkan password" addonAfter={<span className="span-click" onClick={() => setFieldsValue({ password: randomPassword() })}>random</span>} />
                                    )}
                                </Form.Item>
                                <Form.Item label="Kirim email">
                                    {getFieldDecorator('sendemail')(
                                        <Checkbox >Kirim email</Checkbox>
                                    )}
                                </Form.Item>
                            </Fragment>
                        }
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_user' })(UserAdd);