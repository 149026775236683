import React from 'react'
import logo from '../../logo.png'

const PageWelcome = (props) => {
    return (
        <div>
            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: 50, width: '50%' }} src={logo} alt="Logo" />
        </div>
    );
}

export default PageWelcome;