import React from 'react';
import { Card, Row, Col, Tag, Typography, Divider, Table } from 'antd';
import Fetch from '../../Fetch';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import Action from './action';
import { userTypeToString, USER_PARENT } from '../../constant';
import useTable from '../../component/table';
import { push } from '../../component/link';

const Student = props => {
    const [, tb] = useTable('/student', { filter: { parent_id: props.record.id } })

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/student/${record.id}`);
            }
        }
    }

    return (
        <Table {...tb.tableProps} onRow={onRow}>
            <Table.Column title="No Induk" dataIndex="number" />
            <Table.Column title="Nama Lengkap" dataIndex="fullname" />
            <Table.Column title="Kelas" dataIndex="current_kelas.class.name" />
        </Table>
    )
}

const Detail = props => {
    const [{ record, loading }] = useDetail(`/user/${props.match.params.id}`, {});

    return (
        <Card loading={loading} title={<Title title={"Detail User"} actions={<Action dataId={record.id || '1'} />} />} >
            {!loading &&
                <Row gutter={16}>
                    <Col span={6}>
                        <img alt="sample" width={'100%'} src={Fetch.getUrl(`/public/uploads/userphotos/${record.photo || 'default.jpg'}`)}
                            style={{ borderRadius: 10 }} />
                    </Col>
                    <Col span={18}>
                        <div>
                            <TextField label="Nama">{record.name}</TextField>
                            <TextField label="Username">{record.username}</TextField>
                            <TextField label="Email">{record.email}</TextField>
                            <TextField label="Phone">{record.phone}</TextField>
                            <TextField label="Address">{record.address}</TextField>
                        </div>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Tag color="green">{userTypeToString(record.type)}</Tag>
                        </div>
                        <Divider />
                        {record && record.type === USER_PARENT && <div>
                            <div style={{ marginBottom: 8 }}>
                                <Typography.Text strong>Daftar anak</Typography.Text>
                            </div>
                            <Student record={record} {...props} />
                        </div>
                        }
                    </Col>
                </Row>
            }
        </Card >
    );
}

export default Detail;