import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch';
import { pop, push } from '../../component/link';

const Actions = props => {
    const [loading, setLoading] = React.useState(false);

    const onConfirm = async () => {
        setLoading(true);
        try {
            await Fetch.del(`/extra/${props.dataId}`);
            message.info('Ekskul berhasil dihapus');
            if (props.reload !== undefined) {
                props.reload();
            } else {
                pop(props, '/extra');
            }
        } catch (err) {
            message.error('Error: ' + err);
        }
    }

    const onShutdown = async () => {
        const ms = message.loading("Loading...", 0);
        const v = { active: props.active ? 0 : 1, extra_id: props.dataId }
        await Fetch.post(`/extra/enable`, v);
        ms();
        if (props.reload !== undefined) {
            props.reload();
        } else {
            pop(props, '/extra');
        }
    }

    return (
        <Button.Group>
            <Button icon="edit" href={`/extra/${props.dataId}/edit`} onClick={(e) => {
                e.preventDefault();
                push(props, `/extra/${props.dataId}/edit`);
            }}>Edit</Button>
            <Popconfirm placement="bottomLeft" title={`Yakin ${props.active ? 'non aktifkan' : 'aktifkan'} ekstrakulikuler?`} onConfirm={onShutdown} okText="Iya" cancelText="Tidak">
                <Button icon="warning">{props.active ? 'Non aktifkan' : 'Aktifkan'}</Button>
            </Popconfirm>
            <Popconfirm placement="bottomLeft" title={"Yakin menghapus ekstrakulikuler?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                <Button icon="delete" type="danger" loading={loading}>Hapus</Button>
            </Popconfirm>
        </Button.Group >
    );
}

Actions.propTypes = {
    dataId: PropTypes.string.isRequired,
    reload: PropTypes.func,
}

export default withRouter(Actions);