

const USER_OWNER = 1;
const USER_SCHOOL_ADMIN = 2;
const USER_TEACHER = 3;
const USER_PARENT = 4;
const USER_DRIVER = 5;
const USER_SECURITY = 6;
const USER_PERPUS = 7;

const CLASS_NORMAL = 0;
const CLASS_REGISTER = 1;

const userType = [
    { text: 'Owner', value: 1 }, { text: 'Admin sekolah', value: 2 }, { text: 'Guru', value: 3 },
    { text: 'Wali murid', value: 4 }, { text: 'Driver', value: 5 }, { text: 'Security', value: 6 },
    { text: 'Perpus', value: 7 }
]

const studentStatus = [
    { text: 'Aktif', value: 'active', color: 'green' }, { text: 'Nonaktif', value: 'nonactive', color: 'red' },
    { text: 'Lulus', value: 'graduated', color: 'blue' },
]

const registerStatus = [
    { text: '-', value: -1, color: 'red' }, { text: 'Belum bayar', value: 0, color: 'blue' },
    { text: 'Lunas', value: 1, color: 'green' }, { text: 'Antrian', value: 2, color: 'purple' },
    { text: 'Cancel', value: 3, color: 'red' }
]

const paymentType = [
    { text: 'DP', value: 1, color: 'green' }, { text: 'Tahunan', value: 2, color: 'cyan' }, { text: 'SPP', value: 3, color: 'blue' },
    { text: 'Ekstrakulikuler', value: 4, color: 'salmon' }, { text: 'Antar jemput', value: 5, color: 'coral' },
    { text: 'Pendaftaran', value: 6, color: 'violet' }
]

const paymentStatus = [
    { text: 'Belum lunas', value: 1, color: 'red' }, { text: 'Proses', value: 2, color: 'yellow' },
    { text: 'Lunas', value: 3, color: 'green' }
]

const paymentOrderType = [
    { text: 'Cash', value: 1 }, { text: 'Midtrans', value: 2 }, { text: 'Flip', value: 3 }
]

const paymentOrderFor = [
    { text: 'Normal', value: 0 }, { text: 'Pendaftaran', value: 1 }
]

const paymentOrderStatus = [
    { text: 'Belum terbayar', value: 1, color: 'red' }, { text: 'Proses/Pending', value: 2, color: 'coral' },
    { text: 'Terbayar', value: 3, color: 'green' }, { text: 'Expired/Cancel', value: 4, color: 'blue' },
    { text: 'Cancel', value: 5, color: 'blue' }
]

const monthIntString = [
    { text: 'Jan', value: 1 }, { text: 'Feb', value: 2 }, { text: 'Mar', value: 3 },
    { text: 'Apr', value: 4 }, { text: 'Mei', value: 5 }, { text: 'Jun', value: 6 },
    { text: 'Jul', value: 7 }, { text: 'Aug', value: 8 }, { text: 'Sep', value: 9 },
    { text: 'Okt', value: 10 }, { text: 'Nov', value: 11 }, { text: 'Des', value: 12 }
]

const bookType = [
    { text: 'Cerita Anak', value: 'CA' }, { text: 'Referensi Guru', value: 'RG' },
    { text: 'Agama', value: 'AG' }
]

const bookLanguage = [
    { text: 'Indonesia', value: 'INA' }, { text: 'Inggris', value: 'EN' },
    { text: 'Bilingual', value: 'BIL' }
]

const bankVa = [
    { text: 'BNI VA', value: 'bni' }, { text: 'BRI VA', value: 'bri' },
    { text: 'Mandiri VA', value: 'mandiri' }, { text: 'CIMB VA', value: 'cimb' },
    { text: 'Permata VA', value: 'permata' }, { text: 'Danamon VA', value: 'danamon' },
    { text: 'BSI VA', value: 'bsm' }
]

const userTypeToString = (t) => {
    const f = userType.find(v => v.value === t) || { text: 'Undefined' }
    return f.text;
}

const registerStatusToString = (t) => {
    const f = registerStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const registerStatusToColor = (t) => {
    const f = registerStatus.find(v => v.value === t) || { color: 'indigo' }
    return f.color;
}

const paymentTypeToString = (t) => {
    const f = paymentType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentTypeToColor = (t) => {
    const f = paymentType.find(v => v.value === t) || { color: 'indigo' }
    return f.color;
}

const paymentStatusToString = (t) => {
    const f = paymentStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentStatusToColor = (t) => {
    const f = paymentStatus.find(v => v.value === t) || { color: 'indigo' }
    return f.color;
}

const paymentOrderTypeToString = t => {
    const f = paymentOrderType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentOrderForToString = t => {
    const f = paymentOrderFor.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentOrderStatusToString = t => {
    const f = paymentOrderStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const paymentOrderStatusToColor = t => {
    const f = paymentOrderStatus.find(v => v.value === t) || { color: 'indigo' }
    return f.color;
}

const monthIntToString = t => {
    const f = monthIntString.find(v => v.value === t) || { text: '-' }
    return f.text;
}

const studentStatusToString = (t) => {
    const f = studentStatus.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const studentStatusToColor = (t) => {
    const f = studentStatus.find(v => v.value === t) || { color: 'indigo' }
    return f.color;
}

const bookTypeToString = t => {
    const f = bookType.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const bookLanguageToString = t => {
    const f = bookLanguage.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

const bankVaToString = t => {
    const f = bankVa.find(v => v.value === t) || { text: 'Unknown' }
    return f.text;
}

export {
    userTypeToString, registerStatusToString, registerStatusToColor,
    paymentTypeToString, paymentStatusToString, paymentStatusToColor,
    userType, registerStatus, paymentStatus, paymentType,
    USER_OWNER, USER_DRIVER, USER_PARENT, USER_SCHOOL_ADMIN, USER_TEACHER, USER_SECURITY, USER_PERPUS,
    CLASS_NORMAL, CLASS_REGISTER,
    paymentOrderType, paymentOrderStatus, paymentOrderFor, paymentOrderTypeToString,
    paymentOrderForToString, paymentOrderStatusToString, paymentOrderStatusToColor,
    paymentTypeToColor, monthIntString, monthIntToString,
    studentStatus, studentStatusToString, studentStatusToColor,
    bookType, bookTypeToString,
    bookLanguage, bookLanguageToString,
    bankVa, bankVaToString
}