import React from 'react';
import { Modal, Form, message, Input, Divider, Button } from 'antd';
import Fetch from '../../Fetch';

const ResetPassword = props => {
    const [loading, setLoading] = React.useState(false);
    const { getFieldDecorator } = props.form;
    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setLoading(true);
                    const v = { 'user_id': props.record.id, ...values }
                    await Fetch.post(`/user/password`, v);
                    message.info("Password berhasil diganti");
                    props.onCancel();
                    setLoading(false);
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }
    return (
        <Modal
            title={props.record !== undefined ? props.record.name : ""}
            footer={null}
            {...props}
        >
            <Form onSubmit={formSummited} layout="horizontal">
                <Form.Item label="Password baru">
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Harus diisi' }]
                    })(
                        <Input type="password" placeholder="Masukkan password" />
                    )}
                </Form.Item>
                <Divider />
                <Button loading={loading}
                    type="primary"
                    htmlType="submit"
                >
                    Simpan
                </Button>
            </Form>
        </Modal>
    )
}

export default Form.create()(ResetPassword);