import React, { Fragment } from 'react';
import { Row, Col, Empty, Table, Modal } from 'antd';
import Fetch from '../../Fetch';
import { formatDate } from '../../component/util';

const DetailPickup = props => {
    const { loading, student } = props;
    const [state, setState] = React.useState({ data: [], loading: true, modalVisible: false, image: '' })
    if (loading) return null;

    React.useEffect(() => {
        Fetch.get(`/pickupcard?filter[user_id]=${student.parent.id}`)
            .then(({ data }) => {
                setState({ ...state, data: data, loading: false });
                console.log(data)
            })
    }, [student]);

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                setState({ ...state, modalVisible: true, image: record.image })
            }
        }
    }

    return !state.loading && <Fragment>
        {state.data == null && <Empty description="Tidak ada data absensi" />}
        {state.data != null && <Row gutter={16}>
            <Col span={24}>
                <Table dataSource={state.data} rowKey="id" loading={state.loading} onRow={onRow}>
                    <Table.Column key="expired" dataIndex="expired" title="Kadaluarsa" render={t => formatDate(t, 'dd-MM-yyyy')} />
                    <Table.Column key="name" dataIndex="name" title="Nama" />
                </Table>
            </Col>
        </Row>}
        <Modal visible={state.modalVisible} onCancel={() => setState({ ...state, modalVisible: false })} >
            <img style={{ width: '100%', height: '100%' }} src={Fetch.getUrl(`/public/uploads/userphotos/${state.image}`)} alt="detail"></img>
        </Modal>
    </Fragment>

}

export default DetailPickup;