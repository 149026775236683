import React from 'react';
import { Card, Row, Col, Table, Typography, Button } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import MyForm from './form';
import { monthIntToString } from '../../constant'
import moment from 'moment';

const Year = props => {
    const [, tb] = useTable('/year', {})
    const [currentYear, setCurrentYear] = React.useState(null);
    const [add, setAdd] = React.useState(false);

    const onRow = (record) => {
        return {
            onClick: () => {
                setCurrentYear(record);
                setAdd(false);
            }
        }
    }

    const addClicked = () => {
        setCurrentYear(null);
        setAdd(true);
    }

    const formatDate = (v) => {
        return moment(v).format('ddd, DD-MMM-YYYY')
    }

    return (
        <Card title={<Title title={"Master tahun ajaran"} actions={<Button type="primary" onClick={addClicked}>Tambah</Button>} />}>
            <Row style={{ height: '100%' }} gutter={32}>
                <Col span={16}>
                    <Card title={"Daftar tahun ajaran"}>
                        <Table {...tb.tableProps} onRow={onRow}>
                            <Table.Column dataIndex="value" title="Tahun" />
                            <Table.Column dataIndex="name" title="Nama" />
                            <Table.Column dataIndex="angkatan" title="Angkatan" />
                            <Table.Column dataIndex="start_month" title="Awal" render={monthIntToString} />
                            <Table.Column dataIndex="end_month" title="Akhir" render={monthIntToString} />
                            <Table.Column dataIndex="gasal_start" title="Awal Semester 1" render={formatDate} />
                            <Table.Column dataIndex="gasal_end" title="Akhir Semester 1" render={formatDate} />
                            <Table.Column dataIndex="genap_start" title="Awal Semester 2" render={formatDate} />
                            <Table.Column dataIndex="genap_end" title="Akhir Semester 2" render={formatDate} />
                        </Table>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title={add ? "Tambah tahun ajaran" : "Edit tahun ajaran"}>
                        {currentYear === null && !add && <Typography.Text disabled>Silakan pilih tahun ajaran pada tabel untuk edit</Typography.Text>}
                        {(currentYear !== null || add) && <MyForm record={currentYear} edit={!add} onSuccess={() => {
                            setAdd(false);
                            setCurrentYear(null);
                            tb.fetchData();
                        }} />}
                    </Card>
                </Col>
            </Row>
        </Card >
    )
}

export default Year;