import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import {
    paymentTypeToString, paymentStatusToString, paymentStatusToColor, paymentTypeToColor,
    monthIntToString, paymentType, paymentStatus, paymentOrderTypeToString
} from '../../constant'
import { formatDate } from '../../component/util'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="thunderbolt" type="primary" href={'/payment/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/payment/add`);
                }}>Generate</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const Payment = props => {
    const [, tb] = useTable('/payment', { order: { key: '_id', order: 'desc' } })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/payment/${id}`);
                message.info("Berhasil menghapus payment");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus murid: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/payment/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/payment/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            {record.status !== 3 && <Menu.Item><MyLink to={`/payment/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>}
            {record.status !== 3 && record.type === 2 && <Menu.Item><MyLink to={`/payment/${record.id}/split`}><Icon type="flag" style={{ marginRight: 8 }} />Split</MyLink></Menu.Item>}
            {record.status !== 3 && <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.student.name}: ${record.detail}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>}
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar pembayaran"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="student.name" dataIndex="student.name" title="Nama murid" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="detail" dataIndex="detail" title="Detail" />
                <Table.Column key="type" dataIndex="type" title="tipe" width={200} render={t => <Tag color={paymentTypeToColor(t)}>{paymentTypeToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'type', type: 'radio', options: paymentType, })} />
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun" />
                <Table.Column key="month" dataIndex="month" title="Bulan" render={t => monthIntToString(t)} />
                <Table.Column key="status" dataIndex="status" title="Status"
                    render={(t, r) => <>
                        <Tag color={paymentStatusToColor(t)}>{paymentStatusToString(t)}</Tag>
                        {t == 3 ? <Tag color='blue'>{paymentOrderTypeToString(r.payment_order_type)}</Tag> : <></>}
                    </>
                    }

                    {...tb.columnProps({ key: 'status', type: 'radio', options: paymentStatus, })} />
                <Table.Column key="pay_date" dataIndex="pay_date" title="Tanggal bayar" render={r => formatDate(r, false)} />
                <Table.Column key="total" dataIndex="total" title="Total" align="right" render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default Payment;