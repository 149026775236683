import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { paymentTypeToString, paymentType } from '../../constant'

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <MyLink to={'/paymentconfig/add'}><Button type="primary">Tambah</Button></MyLink>
        </div>
    );
}

const PaymentConfig = props => {
    const [, tb] = useTable('/paymentconfig', {})

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/paymentconfig/${id}`);
                message.info("Berhasil menghapus paymentconfig");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus murid: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/paymentconfig/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/paymentconfig/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar pembayaran"} actions={<Actions />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="type" dataIndex="type" title="Tipe" render={paymentTypeToString}
                    {...tb.columnProps({ key: 'type', type: 'radio', options: paymentType, })} />
                <Table.Column key="class.name" dataIndex="class.name" title="Kelas" render={(t, r) => r.type === 4 ? '-' : t}
                    {...tb.columnProps({ key: 'class_name', hint: 'Cari nama' })} />
                <Table.Column key="year.name" dataIndex="year.name" title="TA"
                    {...tb.columnProps({ key: 'year_id', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'id' })} />
                <Table.Column key="count" dataIndex="count" title="Banyak" render={(t, r) => r.type !== 4 ? '-' : t} />
                <Table.Column key="price" dataIndex="price" title="Biaya" align="right" render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default PaymentConfig;