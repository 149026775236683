import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Collapse, Select, Statistic, Row, Col } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import Fetch from '../../Fetch';
import { formatMoney } from '../../component/util'
import {
    paymentOrderTypeToString, paymentOrderForToString, paymentOrderStatusToString,
    paymentOrderStatusToColor, paymentOrderStatus, paymentOrderFor, paymentOrderType
} from '../../constant'
import { MyLink, push } from '../../component/link';
import moment from 'moment';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/paymentorder/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/paymentorder/add`);
                }}>Tambah</Button>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const PaymentOrder = props => {
    const [{ filter, order }, tb] = useTable('/paymentorder', { order: { key: 'date', order: 'descend' }, filter: { hide: 0 } })
    const [state, setState] = React.useState({ curIndex: 0, loading: true, total: 0, record: [] })
    let dates = [];
    let startDate = moment()

    for (let i = 0; i < 12; i++) {
        dates.push({ month: startDate.month(), year: startDate.year(), index: i, text: startDate.format('MMM YYYY') })
        startDate = startDate.add(-1, 'M')
    }

    React.useEffect(() => {
        fetchData()
    }, [state.curIndex])

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const d = dates[state.curIndex]
        const { data } = await Fetch.get(`/paymentorder/total/${d.year}/${d.month + 1}`);
        let total = 0;
        for (let i = 0; i < data.length; i++) {
            total += data[i].total
        }
        setState({ ...state, loading: false, record: data, total: total })
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/paymentorder/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/paymentorder/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
        </Menu>
    )

    const onExportClick = () => {
        const url = tb.genFilterUrl(`/paymentorder/export?count=0&start=0&token=${Fetch.token}`, filter, order)
        window.open(Fetch.getUrl(url))
    }

    const onSelect = (v) => {
        setState({ ...state, curIndex: v })
    }

    const getTotal = (t) => {
        for (let i = 0; i < state.record.length; i++) {
            const r = state.record[i]
            if (r.ID.type == t) return r.total
        }
        return 0
    }

    return (
        <Card title={<Title title={"Daftar pembayaran"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} exportClick={onExportClick} />} />}>
            <Collapse style={{ marginBottom: 8 }}>
                <Collapse.Panel header="Ringkasan">
                    <Select value={state.curIndex} style={{ marginBottom: 10 }} onSelect={onSelect}>
                        {
                            dates.map(v => {
                                return <Select.Option value={v.index} key={v.index}>{v.text}</Select.Option>
                            })
                        }
                    </Select>
                    <Row gutter={8}>
                        <Col span={6}>
                            <Statistic title="Total" value={formatMoney(state.total)} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Cash" value={formatMoney(getTotal(1))} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Midtrans" value={formatMoney(getTotal(2))} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Flip" value={formatMoney(getTotal(3))} />
                        </Col>
                    </Row>

                </Collapse.Panel>
            </Collapse>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="date" dataIndex="date" title="Tanggal" render={t => (new Date(t)).toLocaleString('in-ID')}
                    {...tb.columnProps({ type: 'startend', key: 'date', })} />
                <Table.Column key="number" dataIndex="number" title="Nomor" />
                <Table.Column key="user.email" dataIndex="user.email" title="Email" render={(t, r) => r.user === null ? r.register_item.email : t}
                    {...tb.columnProps({ key: 'email', hint: 'Cari email' })} />
                <Table.Column key="user.name" dataIndex="user.name" title="Nama" render={(t, r) => r.user === null ? r.register_item.name : t}
                    {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="student.name" dataIndex="student.name" title="Nama anak" render={(t, r) => {
                    var n = [];
                    r.payments.forEach(v => {
                        if (n.indexOf(v.student.fullname) < 0) n.push(v.student.fullname);
                    })
                    return n.join(',');
                }} />
                <Table.Column key="type" dataIndex="type" title="Tipe" render={paymentOrderTypeToString}
                    {...tb.columnProps({ key: 'type', type: 'radio', options: paymentOrderType, })} />
                <Table.Column key="for" dataIndex="for" title="Tujuan" render={paymentOrderForToString}
                    {...tb.columnProps({ key: 'for', type: 'radio', options: paymentOrderFor, })} />
                <Table.Column key="status" dataIndex="status" title="Status" render={t => <Tag color={paymentOrderStatusToColor(t)}>{paymentOrderStatusToString(t)}</Tag>}
                    {...tb.columnProps({ key: 'status', type: 'radio', options: paymentOrderStatus, })} />
                <Table.Column key="payment_date" dataIndex="payment_date" title="Tanggal bayar" render={t => t === null ? '-' : (new Date(t)).toLocaleString('in-ID')}
                    {...tb.columnProps({ type: 'startend', key: 'payment_date', })} />
                <Table.Column key="total" dataIndex="total" title="Total" align="right" render={(t) => t.toLocaleString('in-ID')} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default PaymentOrder;