import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Select } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ cardLoading: edit, saveLoading: false, showUserDialog: false, file: null, grade_value: null });
    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => i)
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const result = await Fetch.post('/savvyactivitymaster', values);
                    message.info('Berhasil menyimpan')
                    props.history.replace(`/dailyact/${result.id}`)
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, cardLoading: true });
        const { data } = await Fetch.get(`/savvyactivitymaster/${props.match.params.id}`);

        setState({ ...state, cardLoading: false, data });
        setFieldsValue({});
    }

    const onChangeAdv = (v) => {
        setState({ ...state, grade_value: v.grade.value, year_value: v.year.value })
    }

    return (
        <Card title={<Title title="Tambah Master Kegiatan Harian" {...props} />} loading={state.cardLoading}>
            <Form layout="horizontal" name="add_dailyact" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Master Indikator">
                            {getFieldDecorator('savvy_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/savvy" dataIndex="name" valueIndex="id" placeholder="Pilih tahun ajaran" onChangeAdv={onChangeAdv} render={(v) => {
                                    return `${v.year.name} - ${v.grade.name}`
                                }} />
                            )}
                        </Form.Item>
                        {state.grade_value && <Form.Item label="Kelas">
                            {getFieldDecorator('kelas_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url={`/kelas?filter[grade_value]=${state.grade_value}&filter[year_value]=${state.year_value}`} dataIndex="class.name" valueIndex="id" placeholder="Pilih kelas" />
                            )}
                        </Form.Item>}
                        <Form.Item label="Awal Minggu Semeter 2">
                            {getFieldDecorator('week_start', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih minggu" style={{ width: 200 }} >
                                    {range(0, 34).map(v => {
                                        return <Select.Option key={v} value={v}>{`Minggu ke-${v + 1}`}</Select.Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'add_dailyact' })(Add);