import React from 'react'
import { Typography, Divider } from 'antd';

const LabelHeader = props => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Text style={{ fontSize: 16, color: '#505050' }}>{props.children}</Typography.Text>
                {props.actions}
            </div>
            <Divider />
        </div>
    )
}

export { LabelHeader }