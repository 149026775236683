import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/kelas/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/kelas/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const Kelas = props => {
    const [, tb] = useTable('/kelas', { order: { key: 'class.grade.value', order: 'ascend' }, filter: { active: 1 } })

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/kelas/${id}`);
                message.info("Berhasil menghapus kelas");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus kelas: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/kelas/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/kelas/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/kelas/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/kelas/${record.id}/clone`}><Icon type="copy" style={{ marginRight: 8 }} />Clone</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Kelas"} actions={<Actions  {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran"
                    {...tb.columnProps({ key: 'year_value', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'value' })} />
                <Table.Column key="class.name" dataIndex="class.name" title="Kelas"
                    {...tb.columnProps({ key: 'class_name', hint: 'Cari nama kelas' })} />
                <Table.Column key="guru" dataIndex="teachers" title="Guru kelas" render={t => t.map(v => v.name + ', ')} />
                <Table.Column key="active" dataIndex="active" title="Aktif" render={t => <Tag color={t ? 'green' : 'red'}>{t ? 'Aktif' : 'Non aktif'}</Tag>}
                    {...tb.columnProps({ key: 'active', type: 'radio', options: [{ text: 'Aktif', value: 1 }, { text: 'Non aktif', value: 0 }] })} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default Kelas;