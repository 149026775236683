import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Divider, Tag, Button, message } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import { push } from '../../component/link';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';
import { pop } from '../../component/link';

const Detail = props => {
    const [{ record, loading }] = useDetail(`/kelas/${props.match.params.id}`);
    const [, tb] = useTable('/student', { loading: false }, {}, true);
    const [state, setState] = React.useState({ kelas_id: undefined, students: [], saving: false })
    const stArr = () => record.students ? record.students : [record.id]
    const lulus = (!loading && record.class.grade.graduate === 1);

    React.useEffect(() => {
        if (record) tb.setFilter({ id_in: stArr() });
    }, [record])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/student/${record.id}`)
            }
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setState({ ...state, students: selectedRowKeys })
        },
        getCheckboxProps: record => ({
            name: record.name,
        }),
    };

    const onKelasOnChanged = (v) => {
        setState({ ...state, kelas_id: v })
    }

    const saveClicked = async () => {
        if (state.kelas_id === undefined && !lulus) {
            message.error("Kelas tujuan belum di pilih!")
            return
        } else if (state.students.length === 0) {
            message.error("Murid belum ada yang dipilih!")
            return
        }
        setState({ ...state, saving: true })
        try {
            await Fetch.post(`/kelas/${props.match.params.id}/naik`, { kelas_id: lulus ? '5ba971e48ef8a663a8350b51' : state.kelas_id, students: state.students })
            pop(props, '/kelas')
        } catch (err) {
            message.error(err)
            setState({ ...state, saving: false })
        }
    }


    return (
        <Card loading={loading} title={<Title title={(!loading && record.class.grade.graduate === 1) ? 'Lulus Sekolah' : "Naik Kelas"} actions={null} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <LabelHeader>Kelas Saat ini</LabelHeader>
                        <TextField label="Tahun Ajaran">{record.year.name}</TextField>
                        <TextField label="Kelas">{record.class.name}</TextField>
                        <TextField label="Status"><Tag color={record.active ? 'green' : 'red'}>{record.active ? 'Aktif' : 'Non aktif'}</Tag></TextField>
                    </Col>
                    {!lulus && <Col span={12}>
                        <LabelHeader>Naik Ke Kelas</LabelHeader>
                        <MySelect style={{ width: '100%' }} url={`/kelas?filter[year_value]=${record.year.value + 1}&filter[grade_value]=${record.class.grade.value + 1}&filter[active]=0`}
                            dataIndex="class.name" valueIndex="id" placeholder="Pilih kelas" onChange={onKelasOnChanged} />
                        <p style={{ paddingLeft: 5, paddingTop: 5, paddingRight: 5, paddingBottom: 5, backgroundColor: 'green', color: 'white', borderRadius: 5, marginTop: 10, }}>
                            Pastikan kelas tujuan sudah dibuat dan dalam keadaan non aktif
                        </p>
                    </Col>}
                </Row>
                <Divider />
                <div style={{ marginTop: 20 }}>
                    <LabelHeader >Siswa</LabelHeader>
                </div>
                <p style={{ paddingLeft: 5, paddingTop: 5, paddingRight: 5, paddingBottom: 5, backgroundColor: '#ff5555', color: 'white', borderRadius: 5 }}>
                    Untuk memilih semua siswa, pastikan semua siswa telah terpilih pada tiap halaman!
                </p>
                <Table {...tb.tableProps} onRow={onRow} rowSelection={rowSelection} >
                    <Table.Column key="number" dataIndex="number" title="NIK" render={(t, r) => <span className="span-click" onClick={() => push(props, `/student/${r.id}`)}>{t}</span>}
                        {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })}  {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                    <Table.Column key="name" dataIndex="name" title="Nama"  {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                    <Table.Column key="fullname" dataIndex="fullname" title="Nama lengkap"  {...tb.columnProps({ key: 'fullname', hint: 'Cari nama lengkap' })} />
                    <Table.Column key="gender" dataIndex="gender" title="L/P" render={v => v === 'L' ? 'Laki-laki' : 'Perempuan'}
                        {...tb.columnProps({ key: 'gender', type: 'radio', options: [{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }] })} />
                </Table>
            </Fragment>}
            <Divider />
            <Button type="primary" onClick={saveClicked} loading={state.saving}>Simpan</Button>
        </Card >
    );
}

export default Detail;