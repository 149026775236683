import React from 'react';
import {
    Card, Collapse, Button, Input, Popconfirm, Divider,
    Select, message, Alert, Typography, Drawer, List, Tag,
} from 'antd';
import Title from '../../component/title';
import moment from 'moment';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';
import Chart from "react-apexcharts";
import Score from '../../component/score';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Select style={{ width: 120, marginRight: 8 }} placeholder="Semester" value={props.semester} onChange={props.onSemesterChanged}>
                <Select.Option value={0}>Semester 1</Select.Option>
                <Select.Option value={1}>Semester 2</Select.Option>
            </Select>
            <MySelect url={`/student?filter[kelas_in]=${props.kelas_id}`} dataIndex="name" valueIndex="id" placeholder="Pilih siswa" style={{ width: 150 }}
                onChange={props.onStudentSelected} />
            <Button type='warning' disabled={props.downloadDisabled} icon="download" style={{ marginLeft: 8 }} onClick={props.onDownloadClicked}>Download</Button>
        </div>
    );
}

const SemesterDetail = props => {
    const [data, setData] = React.useState({ masterLoading: true, reload: false })
    const [state, setState] = React.useState({ loading: true, student: undefined, data: undefined, saving: false, semester: undefined })
    const [semScore, setSemScore] = React.useState({})
    const [parentData, setParentData] = React.useState({})
    const [daily, setDaily] = React.useState({ visible: false })
    const id = props.match.params.id
    const kelasId = props.match.params.kelasid;

    React.useEffect(() => {
        async function fetchData() {
            const spectrums = await Fetch.get(`/savvyscore/spectrum/${kelasId}`);
            const result = await Fetch.get(`/savvyactivitymaster/${id}`);
            setData({ ...data, data: result, masterLoading: false, spectrums: spectrums.data })
        }
        fetchData();
    }, [id, kelasId])

    React.useEffect(() => {
        const fetchData = async (kelasid, student, semester) => {
            setSemScore({})
            setParentData({})
            try {
                const semesterScore = await Fetch.get(`/savvysemester/${kelasid}/student/${student}/semester/${semester}`)
                let newScore = {}
                semesterScore.spectrums.forEach(element => {
                    newScore[element.spectrum_id] = element.note
                });
                setSemScore(newScore)
                setParentData({ note: semesterScore.parent_note, date: semesterScore.date, released: semesterScore.released_at })
            } catch (e) { }
        }
        if (data.data && state.semester !== undefined && state.student)
            fetchData(data.data.kelas.id, state.student, state.semester)
    }, [state.student, state.semester, data.data])

    React.useEffect(() => {
        const fetchDate = async (kelasid, student) => {
            setState({ ...state, data: undefined })
            try {
                const result = await Fetch.get(`/savvyscore/${kelasid}/student/${student}`);
                let studentData = [];
                for (let i = 0; i < data.spectrums.length; i++) {
                    const found = result.data.find(v => v.savvy_spectrum.id === data.spectrums[i].id)
                    if (found) {
                        studentData.push({
                            gasal: found.gasal_count > 0 ? found.gasal_score / found.gasal_count : 0,
                            genap: found.genap_count > 0 ? found.genap_score / found.genap_count : 0,
                        })
                    } else {
                        studentData.push({
                            gasal: 0,
                            genap: 0,
                        })
                    }
                }
                const indicatorScores = await Fetch.get(`/savvyscore/${kelasid}/student/${student}/indicator`)
                setState({ ...state, data: studentData, indicators: indicatorScores['data'] })
            } catch (e) {
                message.error(e)
            }
        }
        if (data.data && state.student && state.semester !== undefined)
            fetchDate(data.data.kelas.id, state.student)
    }, [state.student, state.semester, data.data])

    const onStudentSelected = (v) => {
        setState({ ...state, loading: true, student: v })
    }

    const saveClicked = async () => {
        setState({ ...state, saving: true })
        try {
            let _spectrums = []
            for (let key in semScore) {
                _spectrums.push({ spectrum_id: key, note: semScore[key] })
            }
            const data = { semester: 0, spectrums: _spectrums }
            await Fetch.post(`/savvysemester/${kelasId}/student/${state.student}`, data)
            setState({ ...state, saving: false })
            message.success('Berhasil menyimpan')
        } catch (e) {
            message.error(e)
            setState({ ...state, saving: false })
        }
    }

    const onSemesterChanged = (e) => {
        setState({ ...state, semester: e })
    }

    const onScoreNoteChange = (v) => {
        return e => {
            setSemScore({ ...semScore, [v.id]: e.target.value })
        }
    }

    const getScoreNote = (id) => {
        if (semScore[id]) return semScore[id]
        return ''
    }

    const getScoreInt = (item) => {
        if (state.semester === 0)
            return item.gasal_count === 0 ? 0 : Math.round(item.gasal_score / item.gasal_count)
        return item.genap_count === 0 ? 0 : Math.round(item.genap_score / item.genap_count)
    }

    const onDownloadClicked = () => {
        const url = `/savvysemester/${kelasId}/student/${state.student}/semester/${state.semester}/download?token=${Fetch.token}`
        window.open(Fetch.getUrl(url))
    }

    const loadDaily = async (indicatorId) => {
        setDaily({ ...daily, visible: true, title: 'loading...', data: [] })
        const data = await Fetch.get(`/savvyscore/${kelasId}/student/${state.student}/indicator/${indicatorId}/${state.semester}`)
        setDaily({ ...daily, visible: true, title: 'Detail kegiatan', data: data.data })
    }

    const confirmPublish = async () => {
        try {
            await Fetch.post(`/savvysemester/${kelasId}/student/${state.student}/publish`,
                { semester: state.semester, publish: parentData.released ? false : true })
            setParentData({ ...parentData, released: parentData.released ? undefined : true })
        } catch (e) { }
    }

    const series = [{
        name: 'Series 1',
        data: state.data ? state.data.map(v => state.semester === 0 ? v.gasal : v.genap) : [],
    }];

    const options = {
        dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
        },
        title: {
            text: 'Ringkasan'
        },
        stroke: {
            width: 2
        },
        fill: {
            opacity: 0.1
        },
        markers: {
            size: 0
        },
        xaxis: {
            categories: data.spectrums ? data.spectrums.map(v => v.value) : [],
        }
    };

    return (
        <React.Fragment>
            <Card title={<Title title={data.data ? `Rapor Semester: ${data.data.kelas.class.name} - ${data.data.kelas.year.name}` : ''}
                actions={data.data ? <Actions kelas_id={data.data.kelas.id} onStudentSelected={onStudentSelected}
                    semester={state.semester} onSemesterChanged={onSemesterChanged}
                    downloadDisabled={!(data.spectrums && state.student && state.semester !== undefined)} onDownloadClicked={onDownloadClicked} /> : <div />} />} loading={data.masterLoading} >
                {state.data && <Alert type="success" message={<div>
                    <Typography.Text type="secondary">Status</Typography.Text>
                    <Divider style={{ margin: '4px 0' }} />
                    <div>
                        <Popconfirm placement="topLeft" title={`Yakin untuk ${parentData.released ? 'batal terbit' : 'menerbitkan'}`} onConfirm={confirmPublish} okText="Iya" cancelText="Tidak">
                            <Tag color={parentData.released ? 'green' : 'red'}>
                                {parentData.released ? 'Sudah Terbit (klik untuk batal terbit)' : 'Belum Terbit (klik untuk menerbitkan)'}</Tag>
                        </Popconfirm>
                    </div>
                </div>} style={{ marginBottom: 10 }} />}
                {parentData.date && <Alert message={<div>
                    <Typography.Text type="secondary">Catatan orang tua</Typography.Text>
                    <Divider style={{ margin: '4px 0' }} />
                    <Typography.Text>{parentData.note}</Typography.Text>
                </div>} style={{ marginBottom: 10 }} />}
                {state.data && <Collapse style={{ marginBottom: 10 }}><Collapse.Panel header="Radar Chart" key="radarchart"><Chart
                    options={options}
                    series={series}
                    type="radar"
                /></Collapse.Panel></Collapse>}
                <React.Fragment>
                    {data.spectrums && state.student && state.semester !== undefined && data.spectrums.map((v) => {
                        return <Card title={v.value} style={{ marginBottom: '10px' }} key={v.id}>
                            <Collapse style={{ marginBottom: 10 }}>
                                <Collapse.Panel header="Nilai per indicator" key="1">
                                    {state.indicators && state.indicators.filter(e => {
                                        return e.savvy_indicator.spectrum_id === v.id
                                    }).map(e => {
                                        return <div key={e.id} >
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ flex: 1, verticalAlign: 'middle' }}>
                                                    <div>
                                                        <p>{e.savvy_indicator.value}</p>
                                                        <a onClick={() => { loadDaily(e.savvy_indicator.id) }}>detail</a>
                                                    </div>
                                                </div>
                                                <div style={{ padding: 10 }}><Score value={getScoreInt(e)} /></div>
                                            </div>
                                            <Divider />
                                        </div>
                                    })}
                                </Collapse.Panel>
                            </Collapse>
                            <div>
                                <Input.TextArea allowClear placeholder={`Masukan catatan untuk ${v.value}`}
                                    autoSize={{ minRows: 4, maxRows: 6 }} onChange={onScoreNoteChange(v)} value={getScoreNote(v.id)} />
                                <div style={{ marginTop: 10 }}><Button type='primary' onClick={saveClicked} loading={state.saving}>Simpan</Button></div>
                            </div>
                        </Card>
                    })}
                </React.Fragment>
            </Card >
            <Drawer title={daily.title} visible={daily.visible} placement="right" width={460} onClose={() => { setDaily({ ...daily, visible: false }) }}>
                <List dataSource={daily.data}
                    renderItem={item => (
                        <List.Item key={item.id} >
                            <div>
                                <Typography.Text>{moment(item.date).format('DD-MMM-YYYY')}</Typography.Text><br />
                                <Typography.Text disabled>Kegiatan</Typography.Text><br />
                                <Typography.Text>{item.title}</Typography.Text><br />
                                <Typography.Text disabled>Score</Typography.Text><br />
                                <Score value={item.score} /><br />
                                <Typography.Text disabled>Catatan</Typography.Text><br />
                                <Typography.Text>{item.note}</Typography.Text>
                            </div>
                        </List.Item>
                    )} />
            </Drawer>
        </React.Fragment >
    )
}

export default SemesterDetail;