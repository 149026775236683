import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Popconfirm, Table, Input, Select, InputNumber, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { LabelHeader } from '../../component/header';
import UserSelectorDialog from '../share/userselectdialog';
import { USER_TEACHER, CLASS_NORMAL } from '../../constant';
import moment from 'moment';

const Add = props => {
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const clone = props.match.path === '/extra/:id/clone'
    const [state, setState] = React.useState({ cardLoading: edit, saveLoading: false, showUserDialog: false });
    const [teacher, setTeacher] = React.useState([])
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const t = teacher.map(v => v.id);
                    if (edit && !clone) {
                        await Fetch.put(`/extra/${props.match.params.id}`, { ...values, teacher_id: t });
                        message.info('Ekstrakulikuler berhasil diedit');
                    } else {
                        await Fetch.post('/extra', { ...values, teacher_id: t, active: 1 });
                        message.info('Ekstrakulikuler berhasil ditambahkan');
                    }
                    pop(props, '/extra');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, cardLoading: true });
        const { data } = await Fetch.get(`/extra/${props.match.params.id}`);
        setState({ ...state, cardLoading: false });
        setFieldsValue({
            name: data.name, description: data.description,
            year_id: clone ? undefined : data.year.value,
            semester: data.semester,
            classes: data.class.map(v => v.id),
            capacity: data.capacity,
            open_until: clone ? undefined : moment.utc(data.open_until),
            type: data.type,
            price: data.price
        });
        setTeacher(data.teachers.map(v => ({ id: v.id, name: v.name })))
    }

    const onTeacherDelete = (id) => {
        return () => {
            setTeacher(teacher.filter(v => v.id !== id))
        }
    }

    return (
        <Card title={<Title title={edit ? "Edit ekstrakulikuler" : "Tambah ekstrakulikuler"} {...props} />} loading={state.cardLoading}>
            <Form layout="horizontal" name="create_extra" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama ekskul">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan nama" />
                            )}
                        </Form.Item>
                        <Form.Item label="Deskripsi">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input.TextArea placeholder="Masukkan deskripsi" rows={5} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tahun Ajaran">
                            {getFieldDecorator('year_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/year" dataIndex="name" valueIndex="value" placeholder="Pilih tahun ajaran" />
                            )}
                        </Form.Item>
                        <Form.Item label="Semester">
                            {getFieldDecorator('semester', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih semester">
                                    <Select.Option key="1" value={1}>Semester 1</Select.Option>
                                    <Select.Option key="2" value={2}>Semester 2</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Kelas">
                            {getFieldDecorator('classes', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url={`/class?filter[type]=${CLASS_NORMAL}`} dataIndex="name" valueIndex="id" placeholder="Pilih kelas" mode="multiple" />
                            )}
                        </Form.Item>
                        <Form.Item label="Kapasitas">
                            {getFieldDecorator('capacity', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan kapasitas" style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal akhir">
                            {getFieldDecorator('open_until', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Pilih tanggal" format="DD-MM-YYYY" style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih tipe" >
                                    <Select.Option key="1" value={1}>Regular</Select.Option>
                                    <Select.Option key="2" value={2}>Non Regular</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        {getFieldsValue(['type']).type === 2 && <Form.Item label="Biaya">
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan biaya" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>}
                    </Col>
                    <Col span={12}>
                        <LabelHeader actions={<Button onClick={() => setState({ ...state, showUserDialog: true })}>Tambah guru</Button>}>Guru</LabelHeader>
                        <Table dataSource={teacher} rowKey="id">
                            <Table.Column key="name" dataIndex="name" title="Nama" />
                            <Table.Column key="id" dataIndex="id" title="Action" render={(t) => {
                                return <Popconfirm placement="bottomRight" title={"Yakin menghapus guru?"} onConfirm={onTeacherDelete(t)} okText="Iya" cancelText="Tidak">
                                    <span className="span-click">Delete</span>
                                </Popconfirm>
                            }} />
                        </Table>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
            <UserSelectorDialog title="Pilih guru" type={USER_TEACHER} visible={state.showUserDialog} onUserSelected={v => {
                setState({ ...state, showUserDialog: false })
                const find = teacher.find(v1 => v1.id === v.id);
                if (find === undefined) setTeacher([...teacher, v]);
                else message.error('Guru sudah ada')
            }} onCancel={() => setState({ ...state, showUserDialog: false })}
                onClose={() => setState({ ...state, showUserDialog: false })} />
        </Card>
    );
}

export default Form.create({ name: 'create_extra' })(Add);
