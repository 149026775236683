import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, message, Modal, Col, Row, Select } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/dailyact/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/dailyact/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const SavvyAct = props => {
    const [, tb] = useTable('/savvyactivitymaster', { order: { key: 'grade.value', order: 'ascend' }, filter: { active: 1 } })
    const [state, setState] = React.useState({ modalVisible: false, saving: false });

    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => i)
    }

    /*const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/savvyactivitymaster/${id}`);
                message.info("Berhasil menghapus master kegiatan");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus master kegiatan: " + err);
            }
        }
    }*/

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/dailyact/${record.id}`);
            }
        }
    }

    const saveClicked = async () => {
        setState({ ...state, saving: true })
        await Fetch.put(`/savvyactivitymaster/${state.record.id}`, { 'week_start': state.week })
        setState({ ...state, saving: false, modalVisible: false })
        tb.fetchData()
        message.info('Berhasil mengubah master')
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/dailyact/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item onClick={() => {
                setState({ ...state, modalVisible: true, record: record, week: record.week_start })
            }}><Icon type="edit" style={{ marginRight: 8 }} />Edit</Menu.Item>
        </Menu>
    )

    return (
        <React.Fragment>
            <Card title={<Title title={"Daftar Master Kegiatan Harian"} actions={<Actions  {...props} onRefresh={() => tb.fetchData()} />} />}>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="year.name" dataIndex="kelas.year.name" title="Tahun ajaran"
                        {...tb.columnProps({ key: 'year_value', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'value' })} />
                    <Table.Column key="kelas.class.name" dataIndex="kelas.class.name" title="Kelas"
                        {...tb.columnProps({ key: 'grade_name', hint: 'Cari nama kelas' })} />
                    <Table.Column key="week_start" dataIndex="week_start" title="Awal minggu semester 2"
                        render={(r) => { return r + 1 }} />
                    <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                    }} />
                </Table>
            </Card >
            <Modal title={state.record ? `Edit ${state.record.kelas.class.name} - ${state.record.kelas.year.name}` : ''}
                visible={state.modalVisible} onCancel={() => { setState({ ...state, modalVisible: false }) }} okText="Simpan" cancelText="Batal"
                confirmLoading={state.saving} onOk={saveClicked}>
                <Row>
                    <Col span={12}>
                        Awal minggu semester 2
                    </Col>
                    <Col>
                        <Select placeholder="Pilih minggu" style={{ width: 200 }} value={state.week}
                            onChange={(v) => { setState({ ...state, week: v }); }}>
                            {range(0, 34).map(v => {
                                return <Select.Option key={v} value={v}>{`Minggu ke-${v + 1}`}</Select.Option>
                            })}
                        </Select>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default SavvyAct;