import React from 'react';
import { message } from 'antd';
import Fetch from '../Fetch';
import qs from 'query-string';
import { replaceQuery } from './link';
import { getColumnSearchProps, getColumnFilterProps, getColumnDateStartEnd, getColumnSelectUrlProps } from './columnsearch';
import { __RouterContext } from 'react-router-dom'

const getOrder = (query) => {
    if (query !== undefined && query !== '') {
        const s = query.split('-')
        return { key: s[0], order: s[1] }
    }
    return null;
}

const useTable = (url, initialstate, options = { key: 't_' }, dontload = false) => {
    const router = React.useContext(__RouterContext);
    const qKey = options.key || 't_';
    const q = qs.parse(router.location.search)
    const page = parseInt(q[`${qKey}page`]) || 1;
    const pageSize = parseInt(q[`${qKey}pagesize`]) || 10;
    const ord = getOrder(q[`${qKey}order`])

    //filtering is here
    const sKey = `${qKey}search_`;
    let f = {}
    for (let k in q) {
        if (k.includes(sKey)) {
            const key = k.substring(sKey.length);
            f[key] = q[k]
        }
    }

    const initial = {
        loading: true,
        data: [],
        loaded: false
    }
    const [state, setState] = React.useState({ ...initial, ...initialstate });
    const [filter, setFilter] = React.useState({ ...initialstate.filter, ...f })
    const [pagination, setPagination] = React.useState({
        total: 0, pageSize: pageSize, current: page, pageSizeOptions: ['5', '10', '25', '50', '100', '200'],
        showSizeChanger: true, showLessItems: true, hideOnSinglePage: true, showQuickJumper: true,
    });
    const [order, setOrder] = React.useState(ord === null ? initialstate.order || {} : ord)
    const [anyOrder, setAnyOrder] = React.useState(false)

    const genFilterUrl = (url, filter, order1 = {}) => {
        let ret = '';
        for (let k in filter) {
            if (filter[k] !== undefined)
                ret += `&filter[${k}]=${filter[k]}`
        }
        const o = order1.key ? `sort[${order1.key}]=${order1.order === 'ascend' ? 'asc' : 'desc'}` : ''
        return `${url}&${o}&${encodeURI(ret)}`;
    }

    const fetchData = async (page = pagination.current - 1, pageSize = pagination.pageSize, fl = filter, o = order, reset = false) => {
        setState({ ...state, loading: true, loaded: state.loaded && reset })
        try {
            const { data, total_count } = await Fetch.get(genFilterUrl(`${url}${url.indexOf('?') < 0 ? '?' : '&'}start=${page * pageSize}&count=${pageSize}`, fl, o));
            setState({ ...state, data: data, loading: false, loaded: true });
            if (reset) setPagination({ ...pagination, total: total_count })
        } catch (err) {
            message.error('Tidak dapat load table: ' + err);
        }
    }

    const pageChange = (page, pageSize) => {
        if (page === pagination.current) return;
        setPagination({ ...pagination, current: page, pageSize: pageSize })
        fetchData(page - 1, pageSize, filter, order);
        replaceQuery(router, [`${qKey}page=${page}`, `${qKey}pagesize=${pageSize}`])
    }

    const pageSizeChange = (current, size) => {
        fetchData(current - 1, size, filter, order);
        setPagination({ ...pagination, current: current, pageSize: size })
        replaceQuery(router, [`${qKey}page=${current}`, `${qKey}pagesize=${size}`])
    }

    React.useEffect(() => {
        if (!dontload)
            fetchData(pagination.current - 1, pagination.pageSize, filter, order, true);
    }, [])

    const setFilterEx = (f) => {
        setFilter({ ...f })
        fetchData(0, pagination.pageSize, f, order, true);
    }

    const handleSearch = (dataIndex, selectedKey, confirm) => {
        confirm();
        setFilterEx({ ...filter, [dataIndex]: selectedKey[0] })
        replaceQuery(router, [`${qKey}search_${dataIndex}=${selectedKey[0]}`, `${qKey}page=1`])
    }

    const handleReset = (dataIndex, clearFilter) => {
        clearFilter();
        const { [dataIndex]: DataIndex, ...rest } = filter;
        setFilterEx(rest);
        replaceQuery(router, [{ replace: `${qKey}search_${dataIndex}=`, clear: true }, `${qKey}page=1`])
    }

    const filteredValue = (key) => {
        if (state.filter[key] !== undefined) return [filter[key]];
        return []
    }

    const columnProps = ({ key, hint, options, type, number = true, dataIndex, valueIndex, url }) => {
        if (type === undefined || type === 'text')
            return {
                ...getColumnSearchProps(key, hint, handleSearch, handleReset),
                filteredValue: filter[key] !== undefined ? [filter[key]] : []
            }
        else if (type === 'radio')
            return {
                ...getColumnFilterProps(key, options, handleSearch, handleReset),
                filteredValue: filter[key] !== undefined ? [number ? parseInt(filter[key]) : filter[key]] : []
            }
        else if (type === 'startend')
            return {
                ...getColumnDateStartEnd(key, handleSearch, handleReset),
                filteredValue: filter[key] !== undefined ? [filter[key]] : []
            }
        else if (type === 'selecturl')
            return {
                ...getColumnSelectUrlProps(key, hint, handleSearch, handleReset, url, dataIndex, valueIndex)
            }
    }

    const onTableChange = (pag, fil, sort) => {
        if (!anyOrder) return
        if (sort.field === undefined) {
            replaceQuery(router, { replace: `${qKey}order=${sort.field}-${sort.order}`, clear: true })
            setOrder({})
            fetchData(pagination.current - 1, pagination.pageSize, filter, {}, true);
        } else if (sort.field === order.key && sort.order !== order.order) {
            setOrder({ key: sort.field, order: sort.order })
            replaceQuery(router, { replace: `${qKey}order=${sort.field}-${sort.order}` })
            fetchData(pagination.current - 1, pagination.pageSize, filter, { key: sort.field, order: sort.order }, true);
        } else if (sort.field !== order.key) {
            setOrder({ key: sort.field, order: sort.order })
            replaceQuery(router, { replace: `${qKey}order=${sort.field}-${sort.order}` })
            fetchData(pagination.current - 1, pagination.pageSize, filter, { key: sort.field, order: sort.order }, true);
        }
    }

    const sortProps = (key) => {
        if (!anyOrder)
            setAnyOrder(true)
        return {
            sorter: true,
            sortOrder: order.key === key ? order.order ? order.order : false : false,
        }
    }

    return [{ state, filter, pagination, order }, {
        fetchData: fetchData,
        tableProps: {
            loading: state.loading,
            pagination: { ...pagination, onChange: pageChange, onShowSizeChange: pageSizeChange },
            dataSource: state.data,
            rowKey: 'id',
            scroll: { x: true },
            size: 'small',
            bordered: true,
            onChange: onTableChange,
        },
        setFilter: setFilterEx, handleSearch, handleReset, filteredValue, columnProps, genFilterUrl, sortProps
    }];
}

export default useTable;