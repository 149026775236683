import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import Fetch from '../../Fetch';
import { studentStatus, studentStatusToString, studentStatusToColor } from '../../constant';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/student/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/student/add`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const Student = props => {
    const [, tb] = useTable('/student', { filter: { status: 'active' } })
    const [gradeOptions, setGradeOptions] = React.useState([]);

    React.useEffect(() => {
        async function fetch() {
            try {
                const { data } = await Fetch.get('/grade');
                setGradeOptions(data.map(v => ({ text: v.name, value: v.value })));
            } catch (err) {
                message.error('Error get grade: ' + err);
            }
        }
        fetch();
    }, []);

    const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/student/${id}`);
                message.info("Berhasil menghapus murid");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus murid: " + err);
            }
        }
    }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/student/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/student/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/student/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${record.name}?`} onConfirm={onConfirm(record.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Murid"} actions={<Actions {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="number" dataIndex="number" title="No Induk" />
                <Table.Column key="name" dataIndex="name" title="Nama" {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                <Table.Column key="fullname" dataIndex="fullname" title="Nama Lengkap"
                    {...tb.columnProps({ key: 'fullname', hint: 'Cari nama lengkap' })} />
                <Table.Column key="parent.name" dataIndex="parent" title="Nama orang tua"
                    render={t => t === null ? '' : <MyLink to={`/user/${t.id}`}>{t.name}</MyLink>}
                    {...tb.columnProps({ key: 'parent_name', hint: 'Cari orang tua' })} />
                <Table.Column key="current_kelas" dataIndex="current_kelas.class.name" title="Kelas"
                    {...tb.columnProps({ key: 'grade', type: 'radio', options: gradeOptions })} />
                <Table.Column key="gender" dataIndex="gender" title="L/P"
                    {...tb.columnProps({
                        key: 'gender', type: 'radio', options: [
                            { text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }
                        ]
                    })} />
                <Table.Column key="status" dataIndex="status" title="Status"
                    {...tb.columnProps({
                        key: 'status', type: 'radio', options: studentStatus
                    })} render={v => <Tag color={studentStatusToColor(v)}>{studentStatusToString(v)}</Tag>} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default Student;