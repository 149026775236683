import React from 'react';
import { Card, Form, Row, Col, Divider, Button, Input, message, Select, Typography, Radio, DatePicker } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import SingleImageUpload from '../../component/singleimageupload';
import UserSelector from './parentselector';
import moment from 'moment';
import { calculateDiscount } from '../../component/util';

const StudentAdd = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [loading, setLoading] = React.useState(edit);
    const [fileUploaded, setFileUploaded] = React.useState('');
    const [studentSelector, setUserSelector] = React.useState({ visible: false, parent: null });
    const [state, setState] = React.useState({ total: 'loading...', after: '...loading', discount: '0' });
    let yearIn = [];
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (edit) {
                        await Fetch.put(`/student/${props.match.params.id}`, {
                            ...values,
                            photo: fileUploaded === '' ? 'default.jpg' : fileUploaded, parent_id: studentSelector.parent.id
                        });
                        message.info('Murid berhasil diedit');
                    } else {
                        await Fetch.post('/student', {
                            ...values, active: 1,
                            photo: fileUploaded === '' ? 'default.jpg' : fileUploaded, parent_id: studentSelector.parent.id,
                            status: 'active'
                        });
                        message.info('Murid berhasil ditambahkan');
                    }
                    pop(props, '/student');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);


    const fetchData = async () => {
        setLoading(true);
        const { data } = await Fetch.get(`/student/${props.match.params.id}`);
        setLoading(false);
        const { setFieldsValue } = props.form;
        setFieldsValue({
            birth_date: moment(data.birth_date),
            address: data.address,
            birth_place: data.birth_place,
            discount: data.discount,
            fullname: data.fullname,
            gender: data.gender,
            grade: data.grade,
            name: data.name,
            number: data.number,
            year_in: data.year_in,
            religion_id: data.religion.id,
            parent_name: `${data.parent.name} (${data.parent.username})`,
            father: data.father,
            mother: data.mother,
        });
        setFileUploaded(data.photo);
        setUserSelector({ ...studentSelector, parent: data.parent })
        const d = await Fetch.get(`/paymentconfig?filter[type]=4&sort[count]=desc`)
        calculate(data.discount.extra, d.data, data);
    }

    const discountChange = v => {
        calculate(v.target.value, state.config, state.data);
    }

    const calculate = (formula, config, data) => {
        let total = 0;
        let regular = 0;
        if (!edit) return;
        for (let i = 0; i < data.current_extra.length; i++) {
            const e = data.current_extra[i];
            if (e.type === 2) {
                total += e.price;
            } else {
                regular++;
            }
        }

        for (let i = 0; i < config.length; i++) {
            const p = config[i]
            if (regular === p) {
                total += p.price;
                break;
            }
        }
        const disc = calculateDiscount(formula, total);
        setState({ ...state, total: total, after: total - disc, config: config, data: data })
    }

    const n = (new Date()).getFullYear() + 1;
    for (let i = 0; i < 10; i++) {
        yearIn.push(n - i);
    }

    return (
        <Card title={<Title title={edit ? "Edit murid" : "Tambah murid"} {...props} />} loading={loading}>
            <Form colon={false} name="create_student" onSubmit={formSummited} {...formItemLayout}>
                <Card title={<Typography.Text strong>Info Siswa</Typography.Text>} bordered={false}>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Row>
                                <Col span={18} offset={6}>
                                    <SingleImageUpload action={Fetch.getUrl('/student/photo/1')} name={"upload"} onDone={setFileUploaded} path={fileUploaded} />
                                </Col>
                            </Row>
                            <Form.Item label="No Induk">
                                {getFieldDecorator('number', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Input placeholder="Masukkan no induk" />
                                )}
                            </Form.Item>
                            <Form.Item label="Nama lengkap">
                                {getFieldDecorator('fullname', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Input placeholder="Masukkan nama lengkap" />
                                )}
                            </Form.Item>
                            <Form.Item label="Nama panggilan">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Input placeholder="Masukkan nama" />
                                )}
                            </Form.Item>
                            <Form.Item label="Jenis kelamin">
                                {getFieldDecorator('gender', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Radio.Group>
                                        <Radio value="L">Laki-laki</Radio>
                                        <Radio value="P">Perempuan</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="Orang tua">
                                {getFieldDecorator('parent_name', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Input readOnly placeholder="Pilih student ortu" addonAfter={<span className="span-click" onClick={(e) => {
                                        e.preventDefault();
                                        setUserSelector({ ...studentSelector, visible: true });
                                    }}>Pilih</span>} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Tempat lahir">
                                {getFieldDecorator('birth_place', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Input placeholder="Masukkan tempat lahir" />
                                )}
                            </Form.Item>
                            <Form.Item label="Tanggal lahir">
                                {getFieldDecorator('birth_date', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <DatePicker locale={'in-ID'} placeholder="Pilih tanggal lahir" format="DD-MM-YYYY" />
                                )}
                            </Form.Item>
                            <Form.Item label="Agama">
                                {getFieldDecorator('religion_id', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <MySelect url="/religion" dataIndex="name" />
                                )}
                            </Form.Item>
                            <Form.Item label="Grade">
                                {getFieldDecorator('grade', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <MySelect url="/grade" dataIndex="name" valueIndex="value" />
                                )}
                            </Form.Item>
                            <Form.Item label="Tahun masuk">
                                {getFieldDecorator('year_in', {
                                    rules: [{ required: true, message: 'Harus diisi' }]
                                })(
                                    <Select placeholder="Pilih tahun masuk">
                                        {yearIn.map(v => <Select.Option key={v} value={v}>{v}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Alamat">
                                {getFieldDecorator('address', {})(
                                    <Input.TextArea placeholder="Masukkan alamat" autosize />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Row gutter={32}>
                    <Col span={12}>
                        <Card title={<Typography.Text strong>Info Ayah</Typography.Text>} bordered={false}>
                            <Form.Item label="Nama">
                                {getFieldDecorator('father.name', {})(
                                    <Input placeholder="Masukkan nama" />
                                )}
                            </Form.Item>
                            <Form.Item label="Telepon">
                                {getFieldDecorator('father.phone', {})(
                                    <Input placeholder="Masukkan telepon" />
                                )}
                            </Form.Item>
                            <Form.Item label="Pekerjaan">
                                {getFieldDecorator('father.job', {})(
                                    <Input placeholder="Masukkan pekerjaan" />
                                )}
                            </Form.Item>
                            <Form.Item label="Alamat">
                                {getFieldDecorator('father.address', {})(
                                    <Input.TextArea placeholder="Masukkan alamat" autosize />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title={<Typography.Text strong>Info Ibu</Typography.Text>} bordered={false}>
                            <Form.Item label="Nama">
                                {getFieldDecorator('mother.name', {})(
                                    <Input placeholder="Masukkan nama" />
                                )}
                            </Form.Item>
                            <Form.Item label="Telepon">
                                {getFieldDecorator('mother.phone', {})(
                                    <Input placeholder="Masukkan telepon" />
                                )}
                            </Form.Item>
                            <Form.Item label="Pekerjaan">
                                {getFieldDecorator('mother.job', {})(
                                    <Input placeholder="Masukkan pekerjaan" />
                                )}
                            </Form.Item>
                            <Form.Item label="Alamat">
                                {getFieldDecorator('mother.address', {})(
                                    <Input.TextArea placeholder="Masukkan alamat" autosize />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
                <Card title={<Typography.Text strong>Info Diskon</Typography.Text>} bordered={false}>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Diskon Tahunan" help={<Typography.Text disabled>Contoh: 10%+1000</Typography.Text>}>
                                {getFieldDecorator('discount.yearly', {})(
                                    <Input placeholder="Masukkan diskon tahunan" />
                                )}
                            </Form.Item>
                            <Form.Item label="Diskon SPP" help={<Typography.Text disabled>Contoh: 10%+1000</Typography.Text>}>
                                {getFieldDecorator('discount.spp', {})(
                                    <Input placeholder="Masukkan diskon SPP" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Diskon Extra" help={<Typography.Text disabled>{`Total: ${parseInt(state.total).toLocaleString('in-ID')} | after: ${parseInt(state.after).toLocaleString('in-ID')}`}</Typography.Text>}>
                                {getFieldDecorator('discount.extra', {})(
                                    <Input placeholder="Contoh: 10%+1000" onChange={discountChange} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
            <UserSelector visible={studentSelector.visible} onCancel={() => setUserSelector({ ...studentSelector, visible: false })} onUserSelected={(parent) => {
                setUserSelector({ ...studentSelector, visible: false, parent: parent });
                setFieldsValue({ parent_name: `${parent.name} (${parent.studentname})` })
            }} />
        </Card>
    );
}

export default Form.create({ name: 'create_student' })(StudentAdd);