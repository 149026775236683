import React from 'react';
import {
    Card, Form, Row, Col, Divider, Button, message, Select,
    Input, InputNumber, DatePicker, Checkbox
} from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { paymentType } from '../../constant'
import moment from 'moment'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [state, setState] = React.useState({ loading: false, saveLoading: false, type: -1, selected: [], selectAll: false, grade: undefined, kelas: undefined });
    const edit = props.match.params.id !== undefined;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/voucher/${props.match.params.id}`, { ...values, active: values.active ? 1 : 0, year_value: values.year });
                        message.info('Voucher berhasil diedit');
                    } else {
                        await Fetch.post('/voucher', { ...values, active: values.active ? 1 : 0, year_value: values.year });
                        message.info('Voucher berhasil disimpan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/voucher');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const data = await Fetch.get(`/voucher/${props.match.params.id}`);
        console.log(data)
        setState({ ...state, loading: false });
        setFieldsValue({
            title: data.title,
            description: data.description,
            payment_type: data.payment_type,
            year: data.year.value,
            amount: data.amount,
            active: data.active === 1,
            valid_from: moment(data.valid_from),
            valid_until: data.valid_until ? moment(data.valid_until) : null,
            students: data.students || [],
        });
    }

    return (
        <Card title={<Title title="Tambah voucher" {...props} />} >
            <Form layout="vertical" name="generate_payment" onSubmit={formSummited}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Nama voucher">
                            {getFieldDecorator('title', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Input placeholder="Masukkan nama voucher" />
                            )}
                        </Form.Item>
                        <Form.Item label="Deskipsi/Keterangan">
                            {getFieldDecorator('description', {})(
                                <Input.TextArea placeholder="Masukkan deskipsi" autosize />
                            )}
                        </Form.Item>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('payment_type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih tipe">{paymentType.filter(v => v.value < 5).map(v =>
                                    <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                                )}</Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Tahun ajaran">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih tahun ajaran" dataIndex="name" valueIndex="value" url={`/year`} />
                            )}
                        </Form.Item>
                        <Form.Item label="Nilai potongan">
                            {getFieldDecorator('amount', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan nilai potongan" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Aktif?">
                            {getFieldDecorator('active', {
                                rules: [{ required: true, message: 'Harus diisi' }],
                                initialValue: props.record ? (props.record.graduate === 0 ? false : true) : false,
                                valuePropName: 'checked',
                            })(
                                <Checkbox>Aktif</Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal awal">
                            {getFieldDecorator('valid_from', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker placeholder="Pilih tanggal" format="DD-MM-YYYY" style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tanggal akhir">
                            {getFieldDecorator('valid_until')(
                                <DatePicker placeholder="Pilih tanggal" format="DD-MM-YYYY" style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Siswa">
                            {getFieldDecorator('students', {
                                rules: []
                            })(
                                <MySelect placeholder="Pilih siswa" dataIndex="fullname" valueIndex="id" url={`/student?filter[active]=1&filter[status]=active&sort[fullname]=ASC&count=0`} mode="multiple" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'generate_payment' })(Add);