import React, { Fragment } from 'react';
import { Card, Row, Col, Tag, Divider, Table, Menu, Icon, Dropdown, message } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import { TextField } from '../../component/field';
import Action from './action';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';
import { registerStatusToString, registerStatusToColor } from '../../constant';
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }] = useDetail(`/register/${props.match.params.id}`, {});
    const [, tb] = useTable('/registeritem', { filter: { register_id: props.match.params.id } })
    const options = [
        { text: 'Belum lunas', value: 0 }, { text: 'Lunas', value: 1 }, { text: 'Antrian', value: 2 }, { text: 'Canceled', value: 3 }
    ]

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/registeritem/${record.id}`);
            }
        }
    }

    const resendEmail = (record) => {
        return async () => {
            await Fetch.get(`/registeritem/resend/${record.id}`)
            message.info('Email berhasil dikirim ulang')
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/registeritem/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            {record.status === 1 && <Menu.Item><MyLink to={`/student/${record.id}/register`}><Icon type="file" style={{ marginRight: 8 }} />Siswa</MyLink></Menu.Item>}
            {record.status === 1 && <Menu.Item onClick={resendEmail(record)}><span className="span-click2"><Icon type="file" style={{ marginRight: 8 }} />Resend Email</span></Menu.Item>}
        </Menu>
    )

    return (
        <Card loading={loading} title={<Title title={"Detail pendaftaran"} actions={<Action dataId={record.id || '1'} />} />} >
            {!loading &&
                <Fragment>
                    <Row gutter={16}>
                        <Col span={12}>
                            <TextField label="Kelas" >{record.class.name}</TextField>
                            <TextField label="Tahun ajaran" >{record.year.name}</TextField>
                            <TextField label="Tanggal buka" >{(new Date(record.open_date)).toLocaleString('in-ID')}</TextField>
                            <TextField label="Harga" >{record.price.toLocaleString('in-ID')}</TextField>
                        </Col>
                        <Col span={12}>
                            <div>
                                <TextField label="Kapasitas" >{record.capacity.toLocaleString('in-ID')}</TextField>
                                <TextField label="Isi" >{record.count.toLocaleString('in-ID')}</TextField>
                                <TextField label="Pendaftar" >{record.registerer.toLocaleString('in-ID')}</TextField>
                            </div>
                        </Col>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Tag color={record.is_active ? 'green' : 'red'}>{record.is_active ? 'Aktif' : 'Non aktif'}</Tag>
                        </div>
                    </Row>
                    <Divider />
                    <Card title="Pendaftar" bordered={false}>
                        <Table {...tb.tableProps} onRow={onRow}>
                            <Table.Column key="date" dataIndex="date" title="Tanggal" render={(t) => (new Date(t)).toLocaleString('in-ID')} />
                            <Table.Column key="student.name" dataIndex="student.name" title="Nama anak"
                                {...tb.columnProps({ key: 'student_name', hint: 'Cari nama' })} />
                            <Table.Column key="name" dataIndex="name" title="Nama"
                                {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                            <Table.Column key="email" dataIndex="email" title="Email"
                                {...tb.columnProps({ key: 'email', hint: 'Cari email' })} />
                            <Table.Column key="phone" dataIndex="phone" title="Telepon"
                                {...tb.columnProps({ key: 'phone', hint: 'Cari telepon' })} />
                            <Table.Column key="status" dataIndex="status" title="Status" render={(t) => <Tag color={registerStatusToColor(t)}>{registerStatusToString(t)}</Tag>}
                                {...tb.columnProps({ key: 'status', type: 'radio', options: options })} />
                            <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                                return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                            }} />
                        </Table>
                    </Card>
                </Fragment>
            }
        </Card >
    );
}

export default Detail;