import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Divider, Tag, Menu, Icon, Popconfirm, Dropdown, Button } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { LabelHeader } from '../../component/header'
import { MyLink, push } from '../../component/link'
import Fetch from '../../Fetch';

const Detail = props => {
    const [{ record, loading }, dt] = useDetail(`/pickup/${props.match.params.id}`);

    const onConfirm = (id) => {
        return async () => {
            await Fetch.del(`/pickup/item/${record.id}/${id}`);
            dt.fetchData();
        }
    }

    const actionMenus = (r) => (
        <Menu>
            <Menu.Item><MyLink to={`/shuttle/item/${record.id}/${r.student.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomLeft" title={`Yakin menghapus ${r.student.fullname}?`} onConfirm={onConfirm(r.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )

    return (
        <Card loading={loading} title={<Title title={"Detail shuttle"} actions={<Action dataId={record.id || '1'} />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField label="Nama">{record.name}</TextField>
                        <TextField label="Driver">{record.driver.name}</TextField>
                        <TextField label="Aktif"><Tag color={record.active === 1 ? 'green' : 'red'}>{record.active === 1 ? 'Aktif' : 'Tidak aktif'}</Tag></TextField>
                    </Col>
                    <Col span={12}>
                        <TextField label="Peserta">{record.pickup_items.length}</TextField>
                        <TextField label="Total biaya">{record.total.toLocaleString('in-ID')}</TextField>
                    </Col>
                </Row>
                <Divider />
                <LabelHeader actions={<Button onClick={() => push(props, `/shuttle/item/${record.id}/add`)}>Tambah item</Button>}>Peserta</LabelHeader>
                <Table dataSource={record.pickup_items} pagination={{ pageSize: 100, hideOnSinglePage: true }} scroll={{ x: true }}>
                    <Table.Column key="NIS" dataIndex="student.number" title="NIS" render={(t, r) => <a href={`/student/${r.student.id}`} onClick={(e) => {
                        e.preventDefault();
                        push(props, `/student/${r.student.id}`);
                    }}>{t}</a>} />
                    <Table.Column key="name" dataIndex="student.fullname" title="Nama siswa" />
                    <Table.Column key="address" dataIndex="address" title="Alamat" />
                    <Table.Column key="price" dataIndex="price" title="Biaya" render={t => t.toLocaleString('in-ID')} align="right" fixed="right" />
                    <Table.Column key="action" title="Aksi" align="center" fixed="right" render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                    }} />
                </Table>
            </Fragment>
            }
        </Card >
    );
}

export default Detail;