import React from 'react';
import { Card, Button, List, Typography, Tag, Input } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { MyLink } from '../../component/link';
import ActionsItem from './editdeleteaction';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <MyLink to={'/bulletin/add'}><Button type="primary">Tambah</Button></MyLink>
        </div>
    );
}

const BulletinItem = props => {
    const { record } = props;
    const date = new Date(record.date);

    return (
        <List.Item key={record.id}>
            <div style={{ float: 'right' }}>
                <ActionsItem dataId={record.id} reload={props.reload} />
            </div>
            <div style={{ display: 'flex' }}>
                <div>
                    <img alt="sample" src={Fetch.getUrl(`/public/uploads/bulletin/${record.image}`)} style={{ width: 200, borderRadius: 10 }} />
                </div>
                <div style={{ flexGrow: 1, paddingLeft: 24 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <MyLink to={`/bulletin/${record.id}`}><Typography.Text strong style={{ fontSize: 16 }}>{record.title}</Typography.Text></MyLink>
                        <div><Typography.Text disabled style={{ fontSize: 12 }}>{date.toLocaleString('in-ID')}</Typography.Text></div>
                        <div style={{ marginTop: 10, marginBottom: 10, display: 'block' }}>{record.channel.map(v => {
                            return <Tag color="green" key={v.id}>{v.name}</Tag>
                        })}</div>
                        <div><Typography.Text>{record.body}</Typography.Text></div>
                        <div><Typography.Text disabled>{record.comment_num} komentar</Typography.Text></div>
                    </div>
                </div>
            </div>
        </List.Item>
    )
}

const PageBulletinList = props => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [pagination, setPagination] = React.useState({ total: 0, pageSize: 5, current: 1 });
    const [search, setSearch] = React.useState('');
    let timerId = -1;

    React.useEffect(() => {
        fetchData(0, pagination.pageSize);
    }, []);

    const fetchData = async (page, size, search = '') => {
        setLoading(true);
        const url = search === '' ?
            `/bulletin?start=${page * size}&count=${size}` :
            `/bulletin?start=${page * size}&count=${size}&filter[title]=${search}`;
        const res = await Fetch.get(url);
        setLoading(false);
        setData(res.data);
        if (pagination.total === 0)
            setPagination({ ...pagination, total: res.total_count })
    }

    const pageChange = (page) => {
        if (page === pagination.current) return;
        setPagination({ ...pagination, current: page })
        fetchData(page - 1, pagination.pageSize);
    }

    const searchChange = (val) => {
        const searchVal = val.target.value;
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            fetchData(0, pagination.pageSize, searchVal);
            setSearch(searchVal);
        }, 500);
    }

    return (
        <Card title={<Title title={"Pengumuman"} actions={<Actions {...props} />} />}>
            <div style={{ float: 'right' }}>
                <Input.Search placeholder="Cari judul" style={{ width: 200, marginBottom: 20 }} onChange={searchChange} />
            </div>
            <div style={{ marginTop: 40 }}>
                <List loading={loading} itemLayout="vertical" size="large" pagination={{ ...pagination, onChange: pageChange }}
                    renderItem={item => <BulletinItem record={item} history={props.history} reload={() => { fetchData(pagination.current, pagination.pageSize, search); }} />} dataSource={data}
                />
            </div>
        </Card>
    );
}

export default PageBulletinList;