const globalReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDE_BAR': {
            return { ...state, collapse: !state.collapse }
        }
        case 'CHANGE_TEST': {
            return { ...state, test: action.payload }
        }
        default: {
            return state;
        }
    }
}

export default globalReducer;