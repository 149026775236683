import React from 'react'
import { Card } from 'antd';

const PageDashboard = (props) => {
    return (
        <Card title="Dashboard">

        </Card>
    );
}

export default PageDashboard;