import React, { Fragment } from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Select, Table } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop, MyLink } from '../../component/link';
import MySelect from '../../component/select';
import { paymentType } from '../../constant'
import useTable from '../../component/table';
import { LabelHeader } from '../../component/header';
import { monthIntString } from '../../constant'

const Add = props => {
    const { getFieldDecorator } = props.form;
    const [state, setState] = React.useState({ loading: false, saveLoading: false, type: -1, selected: [], selectAll: false, grade: undefined, kelas: undefined });
    const [, tb] = useTable('/student', {}, { key: "st_" }, true);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (!state.selectAll && state.selected.length === 0) {
                        message.error("Siswa tidak boleh kosong")
                        return;
                    }
                    setState({ ...state, saveLoading: true })
                    await Fetch.post('/payment', { ...values, student_id: state.selected });
                    message.info('Pembayaran berhasil digenerate');
                    setState({ ...state, saveLoading: false })
                    pop(props, '/payment');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    const typeChange = e => setState({ ...state, type: e });
    const filterTypeChange = e => {
        if (e === 'all') {
            tb.setFilter({});
        }
        setState({ ...state, ftype: e, grade: undefined, kelas: undefined, extra: undefined, selectAll: false })
    }
    const gradeChange = (v) => {
        setState({ ...state, grade: v })
        tb.setFilter({ grade: v })
    }
    const kelasChange = v => {
        setState({ ...state, kelas: v })
        tb.setFilter({ kelas: v })
    }
    const extraChange = v => {
        setState({ ...state, extra: v })
        tb.setFilter({ extra: v })
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setState({ ...state, selected: selectedRowKeys })
        },
        getCheckboxProps: record => ({
            name: record.name,
        }),
    };

    return (
        <Card title={<Title title="Generate item pembayaran" {...props} />} >
            <Form layout="horizontal" name="generate_payment" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih tipe" onChange={typeChange}>{paymentType.filter(v => v.value < 5).map(v =>
                                    <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>
                                )}</Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Tahun ajaran">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih tahun ajaran" dataIndex="name" valueIndex="value" url={`/year`} />
                            )}
                        </Form.Item>
                        {(state.type === 3 || state.type === 4) && <Form.Item label="Bulan awal">
                            {getFieldDecorator('month', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih bulan" valueIndex="value" dataIndex="text" >
                                    {monthIntString.map(v => <Select.Option value={v.value} key={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                        {(state.type === 3 || state.type === 4) && <Form.Item label="Bulan akhir">
                            {getFieldDecorator('until', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih bulan" valueIndex="value" dataIndex="text" >
                                    {monthIntString.map(v => <Select.Option value={v.value} key={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>}
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Filter siswa">
                            {getFieldDecorator('ftype')(
                                <Select placeholder="Pilih tipe" onChange={filterTypeChange}>
                                    <Select.Option key='all' value='all'>Semua</Select.Option>
                                    <Select.Option key='grade' value='grade'>Grade</Select.Option>
                                    <Select.Option key='kelas' value='kelas'>Kelas</Select.Option>
                                    <Select.Option key='extra' value='extra'>Ekstrakulikuler</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        {state.ftype === 'grade' && <Form.Item label="Grade">
                            {getFieldDecorator('fgrade')(
                                <MySelect url="/grade" valueIndex="value" dataIndex="name" onChange={gradeChange} />
                            )}
                        </Form.Item>}
                        {state.ftype === 'kelas' && <Form.Item label="Kelas">
                            {getFieldDecorator('fkelas')(
                                <MySelect url="/kelas?filter[active]=1" valueIndex="id" dataIndex="class.name" onChange={kelasChange} />
                            )}
                        </Form.Item>}
                        {state.ftype === 'extra' && <Form.Item label="Ekskul">
                            {getFieldDecorator('fkelas')(
                                <MySelect url="/extra?filter[active]=1" valueIndex="id" dataIndex="name" onChange={extraChange} />
                            )}
                        </Form.Item>}
                    </Col>
                </Row>
                {state.ftype && (state.ftype === 'all' || (state.ftype === 'grade' && state.grade) || (state.ftype === 'kelas' && state.kelas) || (state.ftype === 'extra' && state.extra)) && < Fragment >
                    <Divider />
                    <LabelHeader>Pilih siswa</LabelHeader>
                    <Table {...tb.tableProps} rowSelection={rowSelection}>
                        <Table.Column key="number" dataIndex="number" title="NIK" render={(t, r) => <MyLink to={`/student/${r.id}`}>{t}</MyLink>}
                            {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })}  {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                        <Table.Column key="name" dataIndex="name" title="Nama"  {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                        <Table.Column key="fullname" dataIndex="fullname" title="Nama lengkap"  {...tb.columnProps({ key: 'fullname', hint: 'Cari nama lengkap' })} />
                        <Table.Column key="gender" dataIndex="gender" title="L/P" render={v => v === 'L' ? 'Laki-laki' : 'Perempuan'}
                            {...tb.columnProps({ key: 'gender', type: 'radio', options: [{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }] })} />
                    </Table>
                </Fragment>}
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Generate pembayaran
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'generate_payment' })(Add);