import React from 'react';
import { Card, Tabs } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import DetailInfo from './detail_info';
import DetailKelas from './detail_kelas';
import DetailExtra from './detail_extra';
import DetailShuttle from './detail_shuttle';
import DetailPayment from './detail_payment';
import { useTab } from '../../component/tab';
import DetailPickup from './detail_pickupcard';
import DetailAbsensi from './detail_absensi';
import DetailBook from './detail_book';

const Detail = props => {
    const isRegister = props.match.path === '/student/:id/register';
    const [{ record, loading }, { fetchData }] = useDetail(!isRegister ? `/student/${props.match.params.id}` : `/registeritem/${props.match.params.id}`, {});
    const student = !isRegister ? record : record.student;
    const tabProps = useTab(props, 'tab', '1');
    const photo = !loading ? (student.photo.endsWith('jpg') ? student.photo : student.photo + '.jpg') : null;

    return (
        <Card loading={loading} title={<Title title={"Detail siswa"}
            actions={isRegister ? null : <Action dataId={record.id || '1'} reload={fetchData} />} />} >
            <Tabs {...tabProps}>
                <Tabs.TabPane key="1" tab="Info siswa"><DetailInfo {...{ student, record, loading, photo }} {...props} refresh={fetchData} /></Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Kelas"><DetailKelas {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="3" tab="Extrakulikuler"><DetailExtra {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="4" tab="Penjemputan"><DetailShuttle  {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="5" tab="Pembayaran"><DetailPayment  {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="6" tab="Absensi"><DetailAbsensi  {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="7" tab="Perpus"><DetailBook  {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
                <Tabs.TabPane key="8" tab="Kartu Jemputan"><DetailPickup  {...{ student, record, loading, photo }} {...props} /></Tabs.TabPane>
            </Tabs>
        </Card >
    );
}

export default Detail;