import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Select, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import { paymentType, CLASS_NORMAL } from '../../constant'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ loading: edit, saveLoading: false, type: 0 });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setState({ ...state, saveLoading: true })
                    if (edit) {
                        await Fetch.put(`/paymentconfig/${props.match.params.id}`, values);
                        message.info('Setting pembayaran berhasil diedit');
                    } else {
                        await Fetch.post('/paymentconfig', values);
                        message.info('Setting pembayaran berhasil ditambahkan');
                    }
                    setState({ ...state, saveLoading: false })
                    pop(props, '/paymentconfig');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saveLoading: false })
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, loading: true });
        const { data } = await Fetch.get(`/paymentconfig/${props.match.params.id}`);
        setState({ ...state, loading: false, type: data.type });
        setFieldsValue({
            type: data.type,
            class_id: data.type !== 4 ? data.class.id : '',
            count: data.count,
            price: data.price,
            year_id: data.year.value
        });
    }

    const typeChange = (e) => {
        setState({ ...state, type: e })
    }

    return (
        <Card title={<Title title={edit ? "Edit setting pembayaran" : "Tambah setting pembayaran"} {...props} />} loading={state.loading}>
            <Form layout="horizontal" name="create_spayment" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tahun Ajaran">
                            {getFieldDecorator('year_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih tahun ajaran" dataIndex="name" valueIndex="value" url={`/year`} />
                            )}
                        </Form.Item>
                        <Form.Item label="Tipe">
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih tipe pembayaran" onChange={typeChange}>
                                    {paymentType.filter(v => v.value < 5).map(v => <Select.Option key={v.value} value={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                        {state.type > 0 && state.type < 4 && <Form.Item label="Kelas">
                            {getFieldDecorator('class_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih kelas" dataIndex="name" valueIndex="id" url={`/class?filter[type]=${CLASS_NORMAL}`} />
                            )}
                        </Form.Item>}
                        {state.type === 4 && <Form.Item label="Banyak">
                            {getFieldDecorator('count', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukan kelipatan paket" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>}
                        <Form.Item label="Biaya">
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber placeholder="Masukkan biaya" style={{ width: '100%' }} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_spayment' })(Add);