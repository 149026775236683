import React, { useReducer } from 'react';
import globalReducer from './globalreducer';

const GlobalStore = React.createContext();

const intialState = {
    global: {
        test: 'TEST',
        collapse: false
    }
};

const mainReducer = (state, action) => {
    return {
        global: globalReducer(state.global, action),
    }
}

const StoreProvider = ({ children }) => {
    return <GlobalStore.Provider value={useReducer(mainReducer, intialState)}>
        {children}
    </GlobalStore.Provider>
}

export { GlobalStore, StoreProvider }