import React from 'react';
import { Card, Row, Col, Form, Table, Divider, Tag, Menu, Icon, Popconfirm, Dropdown, Button, Select, message } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field'
import { MyLink, push, pop } from '../../component/link'
import Fetch from '../../Fetch';
import MySelect from '../../component/select';
import { monthIntString } from '../../constant'


const Payment = props => {
    const { getFieldDecorator } = props.form;
    const [{ record, loading }, dt] = useDetail(`/pickup/${props.match.params.id}`);
    const [state, setState] = React.useState({ students: [], saving: false })
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        colon: false, labelAlign: 'left'
    };

    const onConfirm = (id) => {
        return async () => {
            await Fetch.del(`/pickup/item/${record.id}/${id}`);
            dt.fetchData();
        }
    }

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (!state.selectAll && state.students.length === 0) {
                        message.error("Siswa tidak boleh kosong")
                        return;
                    }
                    setState({ ...state, saving: true })
                    await Fetch.post('/payment', { ...values, student_id: state.students, type: 5, pickup_id: record.id, status: 1 });
                    message.info('Pembayaran berhasil digenerate');
                    setState({ ...state, saving: false })
                    pop(props, '/shuttle');
                } catch (err) {
                    message.error('Error: ' + err);
                    setState({ ...state, saving: false })
                }
            }
        });
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setState({ ...state, students: selectedRowKeys.map(v => record.pickup_items.find(it => it.id === v).student.id) })
        },
        getCheckboxProps: record => ({
            name: record.name,
        }),
    };

    const actionMenus = (r) => (
        <Menu>
            <Menu.Item><MyLink to={`/shuttle/item/${record.id}/${r.student.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
            <Menu.Item>
                <Popconfirm placement="bottomRight" title={`Yakin menghapus ${r.name}?`} onConfirm={onConfirm(r.id)} okText="Iya" cancelText="Tidak">
                    <span className="span-click2"><Icon type="delete" style={{ marginRight: 8 }} />Hapus</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )


    return (
        <Card loading={loading} title={<Title title={"Generate pembayaran penjemputan"} actions={<Action dataId={record.id || '1'} />} />} >
            {!loading && <Form layout="horizontal" name="generate_payment" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tahun ajaran">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih tahun ajaran" dataIndex="name" valueIndex="value" url={`/year`} />
                            )}
                        </Form.Item>
                        <Form.Item label="Bulan">
                            {getFieldDecorator('month', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Select placeholder="Pilih bulan" valueIndex="value" dataIndex="text" >
                                    {monthIntString.map(v => <Select.Option value={v.value} key={v.value}>{v.text}</Select.Option>)}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <TextField label="Nama">{record.name}</TextField>
                        <TextField label="Driver">{record.driver.name}</TextField>
                        <TextField label="Aktif"><Tag color={record.active === 1 ? 'green' : 'red'}>{record.active === 1 ? 'Aktif' : 'Tidak aktif'}</Tag></TextField>
                    </Col>
                </Row>
                <Divider />
                <Table dataSource={record.pickup_items} pagination={{ pageSize: 100, hideOnSinglePage: true }} rowSelection={rowSelection} rowKey="id" scroll={{ x: true }}>
                    <Table.Column key="NIS" dataIndex="student.number" title="NIS" render={(t, r) => <a href={`/student/${r.student.id}`} onClick={(e) => {
                        e.preventDefault();
                        push(props, `/student/${r.student.id}`);
                    }}>{t}</a>} />
                    <Table.Column key="name" dataIndex="student.fullname" title="Nama siswa" />
                    <Table.Column key="address" dataIndex="address" title="Alamat" />
                    <Table.Column key="price" dataIndex="price" title="Biaya" render={t => t.toLocaleString('in-ID')} align="right" fixed="right" />
                    <Table.Column key="action" title="Aksi" align="center" fixed="right" render={(t, r) => {
                        return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                    }} />
                </Table><br />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Generate pembayaran
                    </Button>
                </Form.Item>
            </Form>
            }
        </Card >
    );
}

export default Form.create({ name: 'generate_payment' })(Payment);