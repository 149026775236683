import React from 'react';
import { Form, Input, Button, Popconfirm, message, InputNumber, Select, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import Fetch from '../../Fetch'
import { monthIntString } from '../../constant'
import moment from 'moment';

const YearForm = props => {
    const [loading, setLoading] = React.useState({ save: false, delete: false });
    const { getFieldDecorator } = props.form;

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    if (values.gasal_end.unix() <= values.gasal_start.unix()) {
                        message.error("Tanggal awal semester gasal harus lebih kecil dari akhir semester gasal")
                        return
                    }
                    if (values.genap_end.unix() <= values.genap_start.unix()) {
                        message.error("Tanggal awal semester genap harus lebih kecil dari akhir semester genap")
                        return
                    }
                    if (values.genap_start.unix() <= values.gasal_end.unix()) {
                        message.error("Tanggal awal semester genap harus lebih besar dari akhir semester gasal")
                        return
                    }
                    setLoading({ ...loading, save: true })
                    if (props.edit) {
                        await Fetch.put(`/year/${props.record.id}`, values);
                        message.info('Tahun ajaran berhasil diedit');
                    }
                    else {
                        await Fetch.post('/year', values);
                        message.info('Tahun ajaran berhasil ditambahkan');
                    }
                    props.onSuccess()
                    setLoading({ ...loading, save: false })
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    const onConfirm = async () => {
        setLoading({ ...loading, delete: true });
        await Fetch.del(`/year/${props.record.id}`);
        message.info('Tahun ajaran berhasil dihapus');
        props.onSuccess();
        setLoading({ ...loading, delete: false });
    }

    const getValue = (v) => {
        const date = moment(v)
        if (date.year() < 2000) return moment()
        return date
    }

    return (
        <div>
            <Form layout="horizontal" onSubmit={formSummited}>
                <Form.Item label="Nama tahun ajaran">
                    {getFieldDecorator('value', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.value : ''
                    })(
                        <InputNumber placeholder="Masukkan tahun ajaran" />
                    )}
                </Form.Item>
                <Form.Item label="Nama tahun ajaran">
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.name : ''
                    })(
                        <Input placeholder="Masukkan nama tahun ajaran" />
                    )}
                </Form.Item>
                <Form.Item label="Angkatan">
                    {getFieldDecorator('angkatan', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.angkatan : ''
                    })(
                        <InputNumber placeholder="Masukkan angkatan" />
                    )}
                </Form.Item>
                <Form.Item label="Bulan awal">
                    {getFieldDecorator('start_month', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.start_month === 0 ? undefined : props.record.start_month : undefined
                    })(
                        <Select placeholder="Pilih bulan" valueIndex="value" dataIndex="text" >
                            {monthIntString.map(v => <Select.Option value={v.value} key={v.value}>{v.text}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Bulan akhir">
                    {getFieldDecorator('end_month', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? props.record.end_month === 0 ? undefined : props.record.start_month : undefined
                    })(
                        <Select placeholder="Pilih bulan" valueIndex="value" dataIndex="text" >
                            {monthIntString.map(v => <Select.Option value={v.value} key={v.value}>{v.text}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label="Awal Semester 1">
                    {getFieldDecorator('gasal_start', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? getValue(props.record.gasal_start) : undefined
                    })(
                        <DatePicker placeholder='Pilih tanggal' />
                    )}
                </Form.Item>
                <Form.Item label="Akhir Semester 1">
                    {getFieldDecorator('gasal_end', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? getValue(props.record.gasal_end) : undefined
                    })(
                        <DatePicker placeholder='Pilih tanggal' />
                    )}
                </Form.Item>
                <Form.Item label="Awal Semester 2">
                    {getFieldDecorator('genap_start', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? getValue(props.record.genap_start) : undefined
                    })(
                        <DatePicker placeholder='Pilih tanggal' />
                    )}
                </Form.Item>
                <Form.Item label="Akhir Semester 2">
                    {getFieldDecorator('genap_end', {
                        rules: [{ required: true, message: 'Harus diisi' }],
                        initialValue: props.record ? getValue(props.record.genap_end) : undefined
                    })(
                        <DatePicker placeholder='Pilih tanggal' />
                    )}
                </Form.Item>
                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            loading={loading.save}
                            type="primary"
                            htmlType="submit"
                        >
                            Simpan
                        </Button>
                        {props.edit &&
                            <Popconfirm placement="bottomLeft" title={"Yakin menghapus tahun ajaran?"} onConfirm={onConfirm} okText="Iya" cancelText="Tidak">
                                <Button loading={loading.delete} type="danger">Hapus</Button>
                            </Popconfirm>
                        }
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

YearForm.propTypes = {
    edit: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func,
}

export default Form.create()(YearForm);