import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, DatePicker, Switch, InputNumber } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { pop } from '../../component/link';
import MySelect from '../../component/select';
import moment from 'moment'

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [loading, setLoading] = React.useState(edit);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const val = { ...values }//, open_date: values.open_date.format('YYYY-MM-DD HH:mm') }
                    if (edit) {
                        await Fetch.put(`/register/${props.match.params.id}`, val);
                        message.info('Pendaftaran berhasil diedit');
                    } else {
                        await Fetch.post('/register', val);
                        message.info('Pendaftaran berhasil ditambahkan');
                    }
                    pop(props, '/register');
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        const { data } = await Fetch.get(`/register/${props.match.params.id}`);
        setLoading(false);
        setFieldsValue({
            year_id: data.year.value,
            class_id: data.class.id,
            price: data.price,
            is_active: data.is_active,
            open_date: moment(data.open_date),
            capacity: data.capacity,
        });
    }

    return (
        <Card title={<Title title={edit ? "Edit pendaftaran" : "Tambah pendaftaran"} {...props} />} loading={loading}>
            <Form layout="horizontal" name="create_register" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Form.Item label="Tahun ajaran">
                            {getFieldDecorator('year_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="Pilih tahun ajaran" url="/year" dataIndex="name" valueIndex="value" />
                            )}
                        </Form.Item>
                        <Form.Item label="Pilih kelas">
                            {getFieldDecorator('class_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect placeholder="PIlih kelas" url="/class" dataIndex="name" valueIndex="id" />
                            )}
                        </Form.Item>
                        <Form.Item label="Biaya">
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber style={{ textAlign: 'right', width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Aktif">
                            {getFieldDecorator('is_active', {
                                valuePropName: 'checked',
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <Switch />
                            )}
                        </Form.Item>
                        <Form.Item label="Tannggal buka">
                            {getFieldDecorator('open_date', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <DatePicker showTime allowClear showToday={false} placeholder="Pilih tanggal dan jam" style={{ width: '100%' }}
                                    format="DD-MM-YYYY HH:mm:ss" />
                            )}
                        </Form.Item>
                        <Form.Item label="Kapasitas">
                            {getFieldDecorator('capacity', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <InputNumber style={{ textAlign: 'right', width: '100%' }}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                    parser={value => value.replace(/\$\s?|(\.*)/g, '')}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Simpan
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_register' })(Add);