import React from 'react';
import { Card, Form, Row, Col, Divider, Button, message, Upload } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import MySelect from '../../component/select';

const Add = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const edit = props.match.params.id !== undefined;
    const [state, setState] = React.useState({ cardLoading: edit, saveLoading: false, showUserDialog: false, file: null });
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const formSummited = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const formData = new FormData();
                    formData.append("year", values.year)
                    formData.append("grade_id", values.grade_id)
                    formData.append("file", state.file)
                    const result = await Fetch.postForm('/savvy/upload', formData);
                    props.history.replace(`/savvy/import/${result.filename}`)
                } catch (err) {
                    message.error('Error: ' + err);
                }
            }
        });
    }

    React.useEffect(() => {
        if (edit) fetchData();
    }, []);

    const fetchData = async () => {
        setState({ ...state, cardLoading: true });
        const { data } = await Fetch.get(`/savvy/${props.match.params.id}`);
        setState({ ...state, cardLoading: false });
        setFieldsValue({
            class_id: data.class.id,
            year_id: data.year.value,
        });
    }

    const handleUpload = (list) => {
        setState({ ...state, file: list.file })
    }

    return (
        <Card title={<Title title="Import Indikator" {...props} />} loading={state.cardLoading}>
            <Form layout="horizontal" name="create_kelas" onSubmit={formSummited} {...formItemLayout}>
                <Row gutter={32}>
                    <Col span={12}>
                        <Row>
                            <Col span={6}>
                                File XLSX
                            </Col>
                            <Col span={18}>
                                <Upload accept='.xlsx' beforeUpload={() => false} onChange={handleUpload}>
                                    <Button>
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Form.Item label="Tahun Ajaran">
                            {getFieldDecorator('year', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url="/year" dataIndex="name" valueIndex="value" placeholder="Pilih tahun ajaran" />
                            )}
                        </Form.Item>
                        <Form.Item label="Grade">
                            {getFieldDecorator('grade_id', {
                                rules: [{ required: true, message: 'Harus diisi' }]
                            })(
                                <MySelect url={`/grade`} dataIndex="name" valueIndex="id" placeholder="Pilih grade" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                </Row>
                <Divider />
                <Form.Item>
                    <Button type="primary" htmlType="submit" >Import</Button>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default Form.create({ name: 'create_savvy' })(Add);