import React from 'react';
import { Card, Table, Button, } from 'antd';
import Title from '../../component/title';
import Fetch from '../../Fetch';
import { push } from '../../component/link';

const Detail = props => {
    const colNum = 35
    const columns = [{
        title: 'Perkembangan dasar',
        dataIndex: 'name',
        width: 300,
        render: (value, row, index) => {
            const obj = {
                children: row.value,
            };
            if (row.type === 'spectrum') {
                return {
                    children: row.spectrum,
                    props: {
                        colSpan: colNum + 2,
                        style: { background: 'aliceblue' }
                    },
                }
            } else if (row.type === 'basic') {
                return {
                    children: row.basic,
                    props: {
                        rowSpan: row.count,
                    }
                }
            } else if (row.type === 'indicator') {
                return {
                    props: {
                        rowSpan: 0,
                    }
                }
            }
            return obj;
        },
    }, {
        title: 'Indikator perkembangan',
        dataIndex: 'age',
        width: 400,
        height: 100,
        textWrap: 'word-break',
        render: (value, row, index) => {
            if (row.type === 'spectrum') {
                return {
                    props: { colSpan: 0 },
                };
            } else if (row.type === 'basic' || row.type === 'indicator') {
                return {
                    children: row.indicator
                }
            }
        },
    }, { title: '', dataIndex: 'number', render: (value, row, index) => { return { children: row.number } } }];
    const [data, setData] = React.useState({ data: [], column: [], year: 'loading...', grade: 'loading...' })
    const id = props.match.params.id

    React.useEffect(() => {
        async function fetchData() {
            const result = await Fetch.get(`/savvy/${id}`);
            let data = [];
            const fillPlots = (plots, iid, obj) => {
                for (let i = 0; i < plots.length; i++) {
                    if (plots[i].indicator_id === iid) {
                        obj[`plot${plots[i].week}`] = 'X'
                    }
                }
                return obj
            }
            for (let i = 0; i < result.spectrums.length; i++) {
                const spec = result.spectrums[i];
                data.push({ key: spec.id, spectrum: spec.value, type: 'spectrum' })
                const basics = result.basics.filter((v) => v.spectrum_id === spec.id)
                let number = 1;
                for (let j = 0; j < basics.length; j++) {
                    const basic = basics[j];
                    const indicators = result.indicators.filter(v => v.basic_id === basic.id)
                    data.push(fillPlots(result.plots, indicators[0].id, { key: indicators[0].id, basic: basic.value, type: 'basic', count: indicators.length, indicator: indicators[0].value, number: number++, indicator_id: indicators[0].id }))
                    for (let k = 1; k < indicators.length; k++) {
                        const indi = indicators[k];
                        data.push(fillPlots(result.plots, indi.id, { key: indi.id, indicator: indi.value, type: 'indicator', number: number++, indicator_id: indi.id }))
                    }
                }
            }
            const column = [columns[0], columns[2], columns[1]];
            for (let i = 0; i < colNum; i++) {
                column.push({
                    title: `${i + 1}`, dataIndex: `${i}`, width: 40, render: (value, row, index) => {
                        if (row.type === 'spectrum') {
                            return {
                                children: row.value,
                                props: { colSpan: 0 },
                            };
                        }
                        return { children: row[`plot${i}`] }
                    }
                })
            }
            setData({ column: column, data: data, year: result.savvy.year.name, grade: result.savvy.grade.name })
        }
        fetchData();
    }, [id])

    const refresh = () => { }

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/savvy/indicator/${record.indicator_id}/edit`);
            }
        }
    }

    const Action = () => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="reload" onClick={refresh}></Button>
            </Button.Group>
        </div>
    }

    return (
        <Card title={<Title title={`Daftar Indikator dan Spektrum - ${data.grade} - ${data.year}`} actions={<Action />} />}>
            <Table onRow={onRow} tableLayout='fixed' columns={data.column} dataSource={data.data} scroll={{ x: true, y: 450 }} size='small' bordered pagination={false} />
        </Card >
    )
}

export default Detail;