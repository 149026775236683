import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink, push } from '../../component/link';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button type="primary" href={'/kelas/add'} onClick={(e) => {
                    e.preventDefault();
                    push(props, `/savvy/import`);
                }}>Tambah</Button>
                <Button icon="reload" onClick={props.onRefresh}></Button>
            </Button.Group>
        </div>
    );
}

const Savvy = props => {
    const [, tb] = useTable('/savvy', { order: { key: 'grade.value', order: 'ascend' }, filter: { active: 1 } })

    /*const onConfirm = (id) => {
        return async () => {
            try {
                await Fetch.del(`/savvy/${id}`);
                message.info("Berhasil menghapus aspect");
                tb.fetchData();
            } catch (err) {
                message.error("Error menghapus aspect: " + err);
            }
        }
    }*/

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/savvy/${record.id}`);
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/savvy/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/savvy/${record.id}/clone`}><Icon type="copy" style={{ marginRight: 8 }} />Clone</MyLink></Menu.Item>
        </Menu>
    )

    return (
        <Card title={<Title title={"Daftar Indikator dan Spektrum"} actions={<Actions  {...props} onRefresh={() => tb.fetchData()} />} />}>
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="year.name" dataIndex="year.name" title="Tahun ajaran"
                    {...tb.columnProps({ key: 'year_value', hint: '', type: 'selecturl', url: '/year', dataIndex: 'name', valueIndex: 'value' })} />
                <Table.Column key="grade.name" dataIndex="grade.name" title="Grade"
                    {...tb.columnProps({ key: 'grade_name', hint: 'Cari nama kelas' })} />
                <Table.Column key="action" title="Aksi" align="center" render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><span className="span-click" >aksi</span></Dropdown>
                }} />
            </Table>
        </Card >
    )
}

export default Savvy;