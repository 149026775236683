import React from 'react'
import { Card, Form, Input, Icon, Checkbox, Button, message } from 'antd';
import Fetch from '../Fetch';

const PageLogin = (props) => {
    const { getFieldDecorator } = props.form;
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);
                const result = await fetch(Fetch.getUrl('/auth'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: values.username,
                        password: values.password,
                    })
                });
                const json = await result.json();
                if (result.status === 200) {
                    if (json.type > 2) {
                        message.error('User tidak diperkenankan untuk login web');
                        setLoading(false);
                    } else {
                        Fetch.setLoggedIn(json)
                        setTimeout(() => props.history.replace('/'), 500)
                    }
                } else {
                    message.error('Login failed: ' + json.error);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: 300, padding: '70px 0' }}>
            <Card title="Login">
                <Form onSubmit={handleSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: 'Please input your username!' }],
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'Please input your Password!' }],
                        })(
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('remember', {
                            valuePropName: 'checked',
                            initialValue: true,
                        })(
                            <Checkbox>Remember me</Checkbox>
                        )}
                        <span className="span-click" style={{ float: 'right' }} href="">Forgot password</span>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default Form.create({ name: 'login-form' })(PageLogin);