import React, { Fragment } from 'react';
import { Card, Row, Col, Table, Divider, Popconfirm, message, Tag } from 'antd';
import Title from '../../component/title';
import { useDetail } from '../../component/detail';
import Action from './action';
import { TextField } from '../../component/field';
import { LabelHeader } from '../../component/header';
import useTable from '../../component/table';
import { push } from '../../component/link';
import { Button } from 'antd/lib/radio';
import StudentSelectDialog from '../share/studentselectdialog';
import Fetch from '../../Fetch';
import { formatDate, formatMoney } from '../../component/util'

const Detail = props => {
    const [{ record, loading }, { fetchData }] = useDetail(`/extra/${props.match.params.id}`);
    const [, tb] = useTable('/student', { loading: false }, {}, true);
    const [state, setState] = React.useState({ modalVisible: false, selectStudentVisible: false, addStudentLoading: false })
    const stArr = () => record.students ? record.students.length === 0 ? ["5ba971e48ef8a663a8350b50"] : record.students : ["5ba971e48ef8a663a8350b50"]
    const tFieldSpan = { labelSpan: 6, valueSpan: 18 }

    React.useEffect(() => {
        tb.setFilter({ id_in: stArr() });
    }, [record])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                push(props, `/student/${record.id}`)
            }
        }
    }

    const onStudentRemoved = (t) => {
        return async () => {
            try {
                await Fetch.del(`/extra/student/${record.id}/${t}`)
                message.info('Berhasil menghapus siswa')
                tb.setFilter({ id_in: stArr().filter(v => v !== `${t},`) });
                fetchData();
            } catch (err) {
                message.error('Error menghapus siswa: ' + err)
            }
        }
    }

    const onStudentSelected = async (o) => {
        setState({ ...state, selectStudentVisible: false })
        try {
            await Fetch.put(`/extra/student/${record.id}`, { student_id: o.id });
            message.info('Berhasil menambah siswa pada ekskul');
            tb.setFilter({ id_in: [...stArr(), `${o.id},`] });
            fetchData();
        } catch (err) {
            message.error('Error menambah siswa: ' + err)
        }
    }

    return (
        <Card loading={loading} title={<Title title={"Detail ekstrakulikuler"} actions={<Action active={record.active} dataId={record.id || '1'} reload={fetchData} />} />} >
            {!loading && <Fragment>
                <Row gutter={16}>
                    <Col span={12}>
                        <TextField {...tFieldSpan} label="Nama">{record.name}</TextField>
                        <TextField {...tFieldSpan} label="Deskripsi"><span>{record.description.split('\n').map((item, i) => <p key={i}>{item}</p>)}</span></TextField>
                        <TextField {...tFieldSpan} label="Aktif"><Tag color={record.active ? 'green' : 'red'}>{record.active ? 'Aktif' : 'Non aktif'}</Tag></TextField>
                        <TextField {...tFieldSpan} label="Tipe"><Tag color={record.type === 1 ? 'green' : 'blue'}>{record.type === 1 ? 'Regular' : 'Non regular'}</Tag></TextField>
                        {record.type === 2 && <TextField {...tFieldSpan} label="Harga">{formatMoney(record.price)}</TextField>}
                        <TextField {...tFieldSpan} label="Tahun Ajaran">{record.year.name}</TextField>
                        <TextField {...tFieldSpan} label="Semester">{record.semester === 0 ? 'Semester 1' : 'Semester 2'}</TextField>
                        <TextField {...tFieldSpan} label="Kelas">{record.class.map(v => v.name + ',')}</TextField>
                        <TextField {...tFieldSpan} label="Buka sampai">{formatDate(record.open_until)}</TextField>
                        <TextField {...tFieldSpan} label="Kapasitas">{record.capacity}</TextField>
                        <TextField {...tFieldSpan} label="Peserta">{record.students.length}</TextField>
                    </Col>
                    <Col span={12}>
                        <LabelHeader>Guru</LabelHeader>
                        <Table dataSource={record.teachers} rowKey="id">
                            <Table.Column key="name" dataIndex="name" title="Nama" render={(t, r) => <span className="span-click" onClick={() => push(props, `/user/${r.id}`)}>{t}</span>} />
                        </Table>
                    </Col>
                </Row>
                <Divider />
                <div style={{ marginTop: 20 }}>
                    <LabelHeader actions={<div><Button loading={true}
                        onClick={() => setState({ ...state, selectStudentVisible: true, addStudentLoading: true })}>Tambah siswa</Button></div>}>Siswa</LabelHeader>
                </div>
                <Table {...tb.tableProps} onRow={onRow}>
                    <Table.Column key="number" dataIndex="number" title="NIK" render={(t, r) => <a href={`/student/${r.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            push(props, `/student/${r.id}`);
                        }
                        }>{t}</a>}
                        {...tb.columnProps({ key: 'number', hint: 'Cari NIK' })} />
                    <Table.Column key="name" dataIndex="name" title="Nama"  {...tb.columnProps({ key: 'name', hint: 'Cari nama' })} />
                    <Table.Column key="fullname" dataIndex="fullname" title="Nama lengkap"  {...tb.columnProps({ key: 'fullname', hint: 'Cari nama lengkap' })} />
                    <Table.Column key="gender" dataIndex="gender" title="L/P" render={v => v === 'L' ? 'Laki-laki' : 'Perempuan'}
                        {...tb.columnProps({ key: 'gender', type: 'radio', options: [{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }] })} />
                    <Table.Column dataIndex="id" title="Action" render={(t, record) => {
                        return <Popconfirm placement="bottomRight" title={"Yakin menghapus siswa?"} onConfirm={onStudentRemoved(t, record)} okText="Iya" cancelText="Tidak">
                            <span className="span-click">Delete</span>
                        </Popconfirm>
                    }} />
                </Table>
            </Fragment>}
            {!loading && <StudentSelectDialog visible={state.selectStudentVisible} onCancel={() => setState({ ...state, selectStudentVisible: false })}
                filter={{ grade_in: record.class.map(v => v.grade.value), id_nin: stArr() }} onStudentSelected={onStudentSelected} title="Pilih siswa" />}
        </Card >
    );
}

export default Detail;